import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../../../../../../modules/FollowUpQuestion/FollowUpQuestion";

export type QuestionDetailCollapseProps = {
  title: string;
  InheritedSection: React.ReactNode;
  followUpQuestions: FollowUpQuestionProps[];
};

const QuestionDetailCollapse: React.FC<QuestionDetailCollapseProps> = props => {
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography color="textSecondary" fontWeight={700}>
        {props.title}
      </Typography>
      {props.InheritedSection && <Box height="250px">{props.InheritedSection}</Box>}
      <Box>
        {props.followUpQuestions.map((question, index) => (
          <FollowUpQuestion key={`question-${index}`} {...question} />
        ))}
      </Box>
    </Stack>
  );
};

QuestionDetailCollapse.displayName = "QuestionDetailCollapse";

export default QuestionDetailCollapse;
