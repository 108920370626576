import { useTranslation } from "@hireroo/i18n";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import PairMatchCodeEditor, { PairMatchCodeEditorProps } from "./parts/PairMatchCodeEditor/PairMatchCodeEditor";
import PairMatchCodeEditorFooter, { PairMatchCodeEditorFooterProps } from "./parts/PairMatchCodeEditorFooter/PairMatchCodeEditorFooter";

const ColumnHeader = styled(Box)(() => ({
  flex: 1,
  height: "32px",
  textAlign: "center",
}));

const ColumnHeading = styled(Typography)(() => ({
  minWidth: 50,
  alignContent: "center",
  height: "100%",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  flexShrink: 0,
  borderRadius: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: 32,
  width: 32,
  svg: {
    height: 20,
    width: 20,
  },
}));

type Fragment = PairMatchCodeEditorProps["fragments"][0];

interface Pair {
  leftValue: string;
  rightValue: string;
  fragments: Fragment[];
}

export type PairEditorProps = {
  pair: Pair;
  rightFooter: PairMatchCodeEditorFooterProps;
  runtime: string;
  decrementButton: Pick<IconButtonProps, "disabled" | "onClick">;
  incrementButton: Pick<IconButtonProps, "disabled" | "onClick">;
};

const PairEditor: React.FC<PairEditorProps> = props => {
  const { pair, runtime, rightFooter } = props;
  const { t } = useTranslation();
  const [selectedMatch, setSelectedMatch] = React.useState<Fragment[] | null>(null);
  const pairMatchCodeEditorFooterRef = React.useRef<HTMLElement>(null);

  const pairMatchCodeEditorProps: Omit<PairMatchCodeEditorProps, "side" | "value"> = {
    runtime: runtime,
    fragments: pair.fragments,
    selectedMatch: selectedMatch,
    onChangeSelectedMatch: setSelectedMatch,
  };

  const resizeDetector = useResizeDetector({
    handleWidth: true,
    handleHeight: false,
  });

  const editorWidth = resizeDetector.width ? Math.floor(resizeDetector.width / 2) : undefined;
  const wrapperHeight = 400;
  const rightCodeEditorHeight =
    pairMatchCodeEditorFooterRef.current === null ? undefined : wrapperHeight - pairMatchCodeEditorFooterRef.current.clientHeight;
  const leftProps: PairMatchCodeEditorProps = { side: "left", value: pair.leftValue, width: editorWidth, ...pairMatchCodeEditorProps };
  const rightProps: PairMatchCodeEditorProps = {
    side: "right",
    value: pair.rightValue,
    width: editorWidth,
    height: rightCodeEditorHeight,
    ...pairMatchCodeEditorProps,
  };

  return (
    <Box display="flex" ref={resizeDetector.ref}>
      <Box sx={{ flex: 1, mr: "1px", height: "100%" }}>
        <ColumnHeader>
          <ColumnHeading variant="body2">{t("提出された解答")}</ColumnHeading>
        </ColumnHeader>
        <Box sx={{ height: wrapperHeight }}>
          <PairMatchCodeEditor {...leftProps} />
        </Box>
      </Box>
      <Box sx={{ flex: 1, height: "100%", display: "flex", flexDirection: "column" }}>
        <ColumnHeader display="flex" justifyContent="space-between">
          <StyledIconButton {...props.decrementButton}>
            <ArrowBackOutlined color="inherit" />
          </StyledIconButton>

          <Box sx={{ display: "flex" }}>
            <ColumnHeading paddingRight={2} variant="body2">
              {t("類似コード")}
            </ColumnHeading>
          </Box>

          <StyledIconButton {...props.incrementButton}>
            <ArrowForwardOutlined color="inherit" />
          </StyledIconButton>
        </ColumnHeader>
        <Box sx={{ height: wrapperHeight, display: "flex", flexDirection: "column" }}>
          <Box sx={{ height: rightCodeEditorHeight }}>
            <PairMatchCodeEditor {...rightProps} />
          </Box>
          <PairMatchCodeEditorFooter {...rightFooter} ref={pairMatchCodeEditorFooterRef} />
        </Box>
      </Box>
    </Box>
  );
};

PairEditor.displayName = "PairEditor";

export default PairEditor;
