import { useTranslation } from "@hireroo/i18n";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link from "../../../../../../../../primitive/Link/Link";

type PairEditorFooterAccessible = {
  kind: "ACCESSIBLE";
  url: string;
};

type PairEditorFooterOtherCompany = {
  kind: "OTHER_COMPANY";
};

type PairEditorFooterNotFound = {
  kind: "NOT_FOUND";
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "166%",
  letterSpacing: "0.4px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.action.hover,
  svg: {
    width: 12,
    height: 12,
    verticalAlign: "middle",
  },
}));

export type PairMatchCodeEditorFooterProps = PairEditorFooterAccessible | PairEditorFooterOtherCompany | PairEditorFooterNotFound;

const PairMatchCodeEditorFooter = React.forwardRef((props: PairMatchCodeEditorFooterProps, ref: React.ForwardedRef<HTMLElement>) => {
  const { t } = useTranslation();

  const Content = React.useMemo((): React.ReactNode => {
    switch (props.kind) {
      case "ACCESSIBLE":
        return (
          <StyledLink href={props.url} target="_blank">
            {props.url} <OpenInNew />
          </StyledLink>
        );
      case "OTHER_COMPANY":
        return t("他社企業の候補者が提出したコードです。");
      case "NOT_FOUND":
        return t("提出コードのリンクはみつかりませんでした");
      default:
        throw new Error(`invalid kind ${props satisfies never}`);
    }
  }, [t, props]);

  return <StyledTypography ref={ref}>{Content}</StyledTypography>;
});

PairMatchCodeEditorFooter.displayName = "PairMatchCodeEditorFooter";

export default PairMatchCodeEditorFooter;
