import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minWidth: 415,
}));

const HeaderWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const TextWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  height: 36,
}));

const TrialLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontWeight: 400,
}));

type Trial = {
  kind: "TRIAL";
};

type PayAsYouGo = {
  kind: "PAY_AS_YOU_GO";
};

type BULK = {
  kind: "BULK";
  count: number;
};

type Plan = PayAsYouGo | BULK | Trial;

export type PackProps = {
  plan: Plan;
};

const Pack: React.FC<PackProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return (
    <Wrapper elevation={4}>
      <HeaderWrapper mb={1.5}>
        <TextWrapper>
          <Typography variant="subtitle2" color="text.secondary">
            {t("選考数")}
          </Typography>
        </TextWrapper>
      </HeaderWrapper>
      <Box>
        {props.plan.kind === "BULK" && (
          <Typography variant="subtitle2">{t2("numberOfRemainingSelections", { count: props.plan.count })}</Typography>
        )}
        {props.plan.kind === "PAY_AS_YOU_GO" && <Typography variant="subtitle2">{t("従量課金")}</Typography>}
        {props.plan.kind === "TRIAL" && <TrialLabel>{t("無料トライアル中のため制限なし")}</TrialLabel>}
      </Box>
    </Wrapper>
  );
};

Pack.displayName = "Pack";

export default Pack;
