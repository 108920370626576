import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Fields } from "@hireroo/validator";

type EntityTrack = Fields.EntityTrack.EntityTrack;
type Questions = Graphql.LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragment[];
type Sessions = Exclude<Exclude<Graphql.RemoteForRemoteInterviewResourceEditorFragment["entity"], null>["liveCoding"], null>["sessions"];

export const convertEntityTracksValidatorFromGraphql = (questions: Questions): EntityTrack[] => {
  return questions.reduce<EntityTrack[]>((all, question) => {
    const type = question.__typename;
    switch (type) {
      case "AlgorithmQuestion": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "REMOTE_ALGORITHM",
            uniqueKey: question.key,
            questionId: question.questionId,
            questionVersion: question.version,
            isSelectable: true,
          },
        };
        return all.concat(entityTrack);
      }
      case "SystemDesignQuestion": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "REMOTE_SYSTEM_DESIGN",
            uniqueKey: question.key,
            questionId: question.questionId,
            isSelectable: true,
          },
        };
        return all.concat(entityTrack);
      }
      default:
        throw new Error(`Invalid type: ${type satisfies never}`);
    }
    return all;
  }, []);
};

export const convertEntityTracksValidatorFromSessionGraphql = (sessions: Sessions): EntityTrack[] => {
  return sessions.reduce<EntityTrack[]>((all, session) => {
    if (!session) {
      return all;
    }
    if (session.algorithmQuestion) {
      const entityTrack: EntityTrack = {
        type: "FIXED",
        questionScoreWeight: 1,
        entitySource: {
          type: "REMOTE_ALGORITHM",
          uniqueKey: session.algorithmQuestion.key,
          questionId: session.algorithmQuestion.questionId,
          questionVersion: session.algorithmQuestion.version,
          isSelectable: true,
        },
      };
      return all.concat(entityTrack);
    } else if (session.systemDesignQuestion) {
      const entityTrack: EntityTrack = {
        type: "FIXED",
        questionScoreWeight: 1,
        entitySource: {
          type: "REMOTE_SYSTEM_DESIGN",
          uniqueKey: session.systemDesignQuestion.key,
          questionId: session.systemDesignQuestion.questionId,
          isSelectable: true,
        },
      };
      return all.concat(entityTrack);
    }
    return all;
  }, []);
};

export const convertSessionsToEntitySourceQuestions = (sessions: Sessions): QuestionSelectFieldForResourceEditor.EntitySourceQuestion[] => {
  return sessions.reduce<QuestionSelectFieldForResourceEditor.EntitySourceQuestion[]>((all, session) => {
    if (!session) {
      return all;
    }
    if (session.algorithmQuestion) {
      return all.concat(session.algorithmQuestion);
    } else if (session.systemDesignQuestion) {
      return all.concat(session.systemDesignQuestion);
    }
    return all;
  }, []);
};
