import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../FollowUpQuestion/FollowUpQuestion";

export type EntityFollowUpQuestionsSectionProps = {
  questions: FollowUpQuestionProps[];
};

const EntityFollowUpQuestionsSection: React.FC<EntityFollowUpQuestionsSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("インタビューで深堀る観点")}
      </Typography>

      <Stack mt={2}>
        {props.questions.length === 0 && (
          <Typography fontSize={14} color="textSecondary">
            {t("深堀る観点が登録されていません。")}
          </Typography>
        )}
        {props.questions.map((question, index) => (
          <FollowUpQuestion key={`question-${index}`} {...question} />
        ))}
      </Stack>
    </Box>
  );
};

EntityFollowUpQuestionsSection.displayName = "EntityFollowUpQuestionsSection";

export default EntityFollowUpQuestionsSection;
