import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { LiveCoding, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { initialLanguageMap } from "@hireroo/challenge/definition";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";
import { useGenerateCompleteRemoteInterviewProps } from "./useGenerateCompleteRemoteInterviewProps";
import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewForCandidateContainerProps = {
  remoteId: string;
};

const RemoteInterviewForCandidateContainer: React.FC<RemoteInterviewForCandidateContainerProps> = props => {
  const uid = Auth.useCurrentUid();
  const session = RemotesId.useActiveSession();
  const liveCodingId = RemotesId.useLiveCodingId();
  const candidateUser = RemotesId.useCandidateUser(uid ?? "");
  const fetchRemote = PrivateHelper.useFetchRemote({
    remoteId: props.remoteId,
  });

  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useTwilioVideoRoomContext();
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();

  const cleanUpRef = React.useRef<() => void>();
  React.useEffect(() => {
    cleanUpRef.current = () => {
      room?.disconnect();
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    };
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);

  // Clean up on unmount
  React.useEffect(() => {
    return () => {
      if (cleanUpRef.current) {
        cleanUpRef.current();
        cleanUpRef.current = undefined;
      }
    };
  }, []);

  // Reload page when page is shown with back-forward cache
  // For use video call in Safari
  React.useEffect(() => {
    const handlePageShowWithBfCache = (event: PageTransitionEvent) => {
      if (!event.persisted) {
        return;
      }
      window.location.reload();
    };

    window.addEventListener("pageshow", handlePageShowWithBfCache);
    return () => {
      window.removeEventListener("pageshow", handlePageShowWithBfCache);
    };
  }, []);

  const handleChangeSyncState = React.useCallback<LiveCoding.SyncStateCallback>(
    syncState => {
      switch (syncState.s) {
        case "dels": {
          RemotesId.removeSession(syncState.v);
          break;
        }
        case "adds": {
          fetchRemote.start();
          break;
        }
      }
    },
    [fetchRemote],
  );
  const initialLanguage = React.useMemo((): string => {
    const defaultLanguage = initialLanguageMap["ALGORITHM"];
    if (
      enabledSinSClassPhase1 &&
      session?.questionExtras?.__typename === "LiveCodingAlgorithmQuestionExtras" &&
      session.questionExtras.initialRuntime
    ) {
      return session.questionExtras.initialRuntime;
    }
    if (session?.algorithmQuestion) {
      switch (session.algorithmQuestion.variant) {
        case "ALGORITHM":
          return initialLanguageMap["ALGORITHM"];
        case "CLASS":
          return initialLanguageMap["CLASS"];
        case "DATABASE":
          return initialLanguageMap["DATABASE"];
        case "UNKNOWN":
          return defaultLanguage;
        default:
          throw new Error(`Unknown variant: ${session.algorithmQuestion.variant satisfies never}`);
      }
    }
    return defaultLanguage;
  }, [enabledSinSClassPhase1, session?.algorithmQuestion, session?.questionExtras]);

  const initialComponentType = React.useMemo(() => {
    if (
      enabledSinSClassPhase1 &&
      session?.questionExtras?.__typename === "LiveCodingSystemDesignQuestionExtras" &&
      session.questionExtras.initialComponentType
    ) {
      return session.questionExtras.initialComponentType;
    }
    return "default";
  }, [enabledSinSClassPhase1, session?.questionExtras]);

  const liveCodingRealtimeDatabaseArgs = React.useMemo((): LiveCoding.LiveCodingRealtimeDatabaseArgs => {
    return {
      enableBrowserEventDetector: true,
      onChangeSyncState: handleChangeSyncState,
      liveCodingId: liveCodingId,
      sessionId: session?.liveCodingSessionId,
      isInterviewing: true,
      uid: candidateUser.userId,
      /**
       * User Name or participant Name
       */
      participantName: candidateUser.userName,
      isCandidate: true,
      session: session
        ? {
            id: session.liveCodingSessionId,
            liveCodingId: liveCodingId,
            liveCodingQuestionType: session.questionType,
            algorithmQuestion: session.algorithmQuestion
              ? {
                  id: session.algorithmQuestion.questionId,
                  version: session.algorithmQuestion.version,
                  questionVariant: session.algorithmQuestion.variant,
                  initialLanguage: initialLanguage,
                }
              : null,
            systemDesignQuestion: session.systemDesignQuestion
              ? {
                  id: session.systemDesignQuestion.questionId,
                  initialFlowChartSnapshot: session.systemDesignQuestion.initialSnapshot,
                  initialComponentType: initialComponentType,
                }
              : null,
          }
        : null,
    };
  }, [candidateUser.userId, candidateUser.userName, handleChangeSyncState, initialComponentType, initialLanguage, liveCodingId, session]);

  const { collaborativeState, collaborativeAction } = LiveCoding.useLiveCodingRealtimeDatabase(liveCodingRealtimeDatabaseArgs);
  const remoteInterviewForCandidateProps = useGenerateProps({
    remoteId: props.remoteId,
    collaborativeAction: collaborativeAction,
    collaborativeState: collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemoteInterviewParticipantsStore({
    collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemotesIdStore({
    collaborativeState,
  });
  const completeRemoteInterviewProps = useGenerateCompleteRemoteInterviewProps({});

  if (collaborativeState.isLiveCodingFinished) {
    cleanUpRef.current?.();
  }

  if (collaborativeState.isLiveCodingFinished) {
    return (
      <ErrorBoundary>
        <Pages.CompleteRemoteInterview {...completeRemoteInterviewProps} />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewForCandidate {...remoteInterviewForCandidateProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewForCandidateContainer.displayName = "RemoteInterviewForCandidateContainer";

export default withErrorBoundary(RemoteInterviewForCandidateContainer, {});
