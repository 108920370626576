import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingRight: theme.spacing(1),
  fontSize: "28px",
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  paddingRight: theme.spacing(1),
  fontSize: "28px",
}));

type SuspiciousDegree = "CLEAN" | "SUSPICIOUS";

export type SuspiciousDegreeMessageForQuestionProps = {
  suspiciousDegree: SuspiciousDegree;
  feedbackButton: Pick<ButtonProps, "onClick" | "disabled">;
  reason?: string;
};

const SuspiciousDegreeMessageForQuestion: React.FC<SuspiciousDegreeMessageForQuestionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { suspiciousDegree, feedbackButton, reason } = props;
  const messageMap: Record<SuspiciousDegree, string> = {
    CLEAN: t("この問題の解答時に不審な行動はみつかりませんでした。"),
    SUSPICIOUS: t("この問題の解答時に不審な行動がみつかりました。"),
  };

  const SuspiciousDegreeIcon = React.useMemo(() => {
    switch (suspiciousDegree) {
      case "CLEAN":
        return (
          <Tooltip title={t("不審な行動はみつかりませんでした。")}>
            <StyledCheckCircleIcon />
          </Tooltip>
        );
      case "SUSPICIOUS":
        return (
          <Tooltip title={t("不審な行動がみつかりました。プレイバックなどを確認し、候補者の行動に問題がないか確認してください。")}>
            <StyledWarningIcon />
          </Tooltip>
        );
      default:
        throw Error(`unexpected suspicious degree ${suspiciousDegree satisfies never}`);
    }
  }, [suspiciousDegree, t]);

  const button = React.useMemo(
    () => (
      <Button
        {...feedbackButton}
        variant="outlined"
        color="inherit"
        startIcon={<FlagOutlinedIcon />}
        sx={{
          borderColor: theme.palette.divider,
        }}
      >
        {t("フィードバック")}
      </Button>
    ),
    [t, feedbackButton, theme.palette.divider],
  );

  const feedbackSuspiciousDegreeButton = React.useMemo(() => {
    if (!feedbackButton.disabled) {
      return button;
    }

    return (
      <Tooltip title={t("この問題に対する不審度のフィードバックは既に送信されています。")} placement="top">
        <span>{button}</span>
      </Tooltip>
    );
  }, [t, button, feedbackButton.disabled]);

  const wrapperProps: BoxProps = {
    p: 2,
    display: "flex",
    alignItems: reason ? undefined : "center",
    border: suspiciousDegree === "SUSPICIOUS" ? `1px solid ${theme.palette.warning.main}` : undefined,
    borderRadius: "4px",
  };

  const iconWrapperProps: BoxProps = React.useMemo(() => {
    const baseProps: BoxProps = {
      mr: 0.5,
    };

    if (reason) {
      return {
        ...baseProps,
      };
    }

    return {
      ...baseProps,
      display: "flex",
      alignItems: "center",
    };
  }, [reason]);

  return (
    <Paper elevation={8} sx={{ color: theme.palette.text.secondary, boxShadow: "none" }}>
      <Box {...wrapperProps}>
        <Box {...iconWrapperProps}>{SuspiciousDegreeIcon}</Box>
        <Box mr={2} display="flex" flexDirection="column">
          <Typography fontSize={16}>{messageMap[suspiciousDegree]}</Typography>
          {reason && <Typography fontSize={14}>{reason}</Typography>}
        </Box>
        <Box ml="auto" sx={{ whiteSpace: "nowrap" }} color={theme.palette.text.primary}>
          {feedbackSuspiciousDegreeButton}
        </Box>
      </Box>
    </Paper>
  );
};

SuspiciousDegreeMessageForQuestion.displayName = "SuspiciousDegreeMessageForQuestion";

export default SuspiciousDegreeMessageForQuestion;
