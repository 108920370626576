import * as React from "react";

import SuspiciousDegreeMessageForQuestion, {
  SuspiciousDegreeMessageForQuestionProps,
} from "../../ms-components/Suspicious/SuspiciousDegreeMessageForQuestion/SuspiciousDegreeMessageForQuestion";

export type ChallengeTestReportSuspiciousDegreeMessageForQuestionProps = {
  suspiciousDegreeMessageForQuestion: SuspiciousDegreeMessageForQuestionProps;
};

const ChallengeTestReportSuspiciousDegreeMessageForQuestion: React.FC<ChallengeTestReportSuspiciousDegreeMessageForQuestionProps> = props => {
  return <SuspiciousDegreeMessageForQuestion {...props.suspiciousDegreeMessageForQuestion} />;
};

ChallengeTestReportSuspiciousDegreeMessageForQuestion.displayName = "ChallengeTestReportSuspiciousDegreeMessageForQuestion";

export default ChallengeTestReportSuspiciousDegreeMessageForQuestion;
