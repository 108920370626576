export const extensionMap: Record<string, string> = {
  ".c": "c",
  ".cs": "csharp",
  ".py": "python",
  ".go": "go",
  ".ts": "typescript",
  ".js": "javascript",
  ".php": "php",
  ".pl": "perl",
  ".rb": "ruby",
  ".kt": "kotlin",
  ".java": "java",
  ".sql": "sql",
  ".mysql": "mysql",
  ".pgsql": "pgsql",
  ".html": "html",
  ".htm": "html",
  ".css": "css",
  ".scss": "scss",
  ".md": "markdown",
  ".json": "json",
  ".yaml": "yaml",
  ".yml": "yaml",
  ".xml": "xml",
  Dockerfile: "dockerfile",
  ".jsx": "javascriptreact",
  ".tsx": "typescriptreact",
  ".vue": "html",
  ".rs": "rust",
};

export default extensionMap;
