import extensionMap from "./extensionMap";

/**
 * key: language
 * value: extension
 *
 * e.g.
 * {
 *  "typescript": ".ts"
 * }
 */
export const languageExtensionMap: Record<string, string> = {
  ...Object.fromEntries(Object.entries(extensionMap).map(([key, value]) => [value, key])),
  python3: ".py",
  cpp: ".cpp",
};

export default languageExtensionMap;
