import type { Widget } from "@hireroo/presentation";

export type GenerateRemoteInterviewStaticFlowChartPropsArgs = {
  elements: Widget.RemoteInterviewStaticFlowChartProps["staticFlowChart"]["elements"];
  componentType: Widget.RemoteInterviewStaticFlowChartProps["staticFlowChart"]["componentType"];
};

export const useGenerateProps = (args: GenerateRemoteInterviewStaticFlowChartPropsArgs): Widget.RemoteInterviewStaticFlowChartProps => {
  return {
    staticFlowChart: {
      elements: args.elements,
      componentType: args.componentType,
    },
    lastElements: args.elements,
  };
};
