import * as z from "zod";

/**
 * The Value should not be easily changed as it will be the data sent to realtime database.
 */
export const AliasEventNameMap = {
  access: z.literal("access"),
  visible: z.literal("visible"),
  hidden: z.literal("hidden"),
  blur: z.literal("blur"),
  paste: z.literal("paste"),
  cut: z.literal("cut"),
  copy: z.literal("copy"),
  focus: z.literal("focus"),
};

export type EventName = z.infer<(typeof AliasEventNameMap)[keyof typeof AliasEventNameMap]>;

export const TextSelection = z.object({
  /** String value of copied text */
  text: z.string(),
  /** start position */
  position: z.number(),
  /** end position */
  selectionEnd: z.number(),
});
export type TextSelection = z.infer<typeof TextSelection>;

export const TextSelections = TextSelection.array();
export type TextSelections = z.infer<typeof TextSelections>;

export const Operation = z.union([z.string(), z.number()]);
export type Operation = z.infer<typeof Operation>;

export const Operations = Operation.array();
export type Operations = z.infer<typeof Operations>;

const FirebaseTimestamp = z.number();

export const QuestionParams = z.object({
  uid: z.string(),
  appType: z.literal("quiz"),
  quizId: z.number(),
  packageId: z.number(),
  questionId: z.number(),
});
export type QuestionParams = z.infer<typeof QuestionParams>;

export const ChallengeParams = z.object({
  uid: z.string(),
  appType: z.literal("challenge"),
  challengeId: z.number(),
  questionId: z.number(),
});
export type ChallengeParams = z.infer<typeof ChallengeParams>;

export const SystemDesignParams = z.object({
  uid: z.string(),
  appType: z.literal("systemDesign"),
  systemDesignId: z.number(),
  questionId: z.number(),
});
export type SystemDesignParams = z.infer<typeof SystemDesignParams>;

export const ProjectParams = z.object({
  uid: z.string(),
  appType: z.literal("project"),
  projectId: z.number(),
  questionId: z.number(),
});
export type ProjectParams = z.infer<typeof ProjectParams>;

export const LiveCodingParams = z.object({
  uid: z.string(),
  appType: z.literal("liveCoding"),
  liveCodingId: z.number(),
  sessionId: z.number(),
});
export type LiveCodingParams = z.infer<typeof LiveCodingParams>;

export const ChallengeLanguageEventParams = z.object({
  uid: z.string(),
  language: z.string(),
  appType: z.literal("challenge-language"),
  challengeId: z.number(),
  questionId: z.number(),
});
export type ChallengeLanguageEventParams = z.infer<typeof ChallengeLanguageEventParams>;

export const Params = z.union([
  ChallengeParams,
  ChallengeLanguageEventParams,
  QuestionParams,
  SystemDesignParams,
  ProjectParams,
  LiveCodingParams,
]);
export type Params = z.infer<typeof Params>;

export const FocusEventPayload = z.object({
  s: AliasEventNameMap.focus,
  t: FirebaseTimestamp,
});
export type FocusEventPayload = z.infer<typeof FocusEventPayload>;

export const BlurEventPayload = z.object({
  s: AliasEventNameMap.blur,
  t: FirebaseTimestamp,
});
export type BlurEventPayload = z.infer<typeof BlurEventPayload>;

export const MonacoPasteEventPayload = z.object({
  s: AliasEventNameMap.paste,
  t: FirebaseTimestamp,
  v: Operations,
});
export type MonacoPasteEventPayload = z.infer<typeof MonacoPasteEventPayload>;

export const MonacoCopyEventPayload = z.object({
  s: AliasEventNameMap.copy,
  t: FirebaseTimestamp,
  v: TextSelections,
  /**
   * l = location
   * optionalな理由: 元々 { s: "copy" }に対しての値はmonaco-editorのcopyイベントのみを取り扱っていた
   * しかしながら、コピーイベントを検出する場所(=location)が増えたことにより、"l"のフィールドが無いときは"monaco-editor"として扱う必要がある
   *
   * もし、optionalを取り外したい場合は、firebaseに保存されている過去のcopyイベントをすべてマイグレーションしたうえで削除してください
   */
  l: z.literal("monaco-editor").optional(),
});

export type MonacoCopyEventPayload = z.infer<typeof MonacoCopyEventPayload>;

export const MonacoCutEventPayload = z.object({
  s: AliasEventNameMap.cut,
  t: FirebaseTimestamp,
  v: TextSelections,
});
export type MonacoCutEventPayload = z.infer<typeof MonacoCutEventPayload>;

export const BrowserTabHiddenReason = z.union([
  z.object({
    /** k = kind */
    k: z.literal("googleSearch"),
  }),
  z.object({
    /** k = kind */
    k: z.literal("clickElement"),
    /**
     * x = xPath
     *
     * Use `null` when storing values in firebase. Also, when referencing a value, `undefined` is returned.
     */
    x: z.string().nullable().optional(),
  }),
]);
export type BrowserTabHiddenReason = z.infer<typeof BrowserTabHiddenReason>;

export const VisibleEventPayload = z.object({
  s: AliasEventNameMap.visible,
  t: FirebaseTimestamp,
});
export type VisibleEventPayload = z.infer<typeof VisibleEventPayload>;

export const HiddenEventPayload = z.object({
  s: AliasEventNameMap.hidden,
  t: FirebaseTimestamp,
  /**
   * The "r" means "reason".
   *
   * Use `null` when storing values in firebase. Also, when referencing a value, `undefined` is returned.
   *
   **/
  r: BrowserTabHiddenReason.nullable().optional(),
  /**
   * @example https://example.com
   *
   * Use `null` when storing values in firebase. Also, when referencing a value, `undefined` is returned.
   */
  to: z.string().nullable().optional(),
});
export type HiddenEventPayload = z.infer<typeof HiddenEventPayload>;

export const AccessEventPayload = z.object({
  s: AliasEventNameMap.access,
  /**
   * ip address (location)
   */
  l: z.string(),
  /**
   * geolocation (City, Country)
   */
  g: z.string(),
  t: FirebaseTimestamp,
});
export type AccessEventPayload = z.infer<typeof AccessEventPayload>;

export const CopyQuestionEventPayload = z.object({
  s: AliasEventNameMap.copy,
  /**
   * User ID
   */
  a: z.string(),
  /**
   * Copied Text
   */
  v: z.string(),
  t: FirebaseTimestamp,
  /**
   * l = location
   */
  l: z.literal("question"),
});

export type CopyQuestionEventPayload = z.infer<typeof CopyQuestionEventPayload>;

export type CopyEventPayload = CopyQuestionEventPayload;

export const Payload = z.union([
  AccessEventPayload,
  FocusEventPayload,
  BlurEventPayload,
  VisibleEventPayload,
  HiddenEventPayload,
  MonacoPasteEventPayload,
  MonacoCopyEventPayload,
  MonacoCutEventPayload,
]);
export type Payload = z.infer<typeof Payload>;

export const SendPayload = z.union([
  AccessEventPayload.omit({
    t: true,
  }),
  FocusEventPayload.omit({
    t: true,
  }),
  BlurEventPayload.omit({
    t: true,
  }),
  VisibleEventPayload.omit({
    t: true,
  }),
  CopyQuestionEventPayload.omit({
    t: true,
  }),
  HiddenEventPayload.omit({
    t: true,
  }),
  MonacoPasteEventPayload.omit({
    t: true,
  }),
  MonacoCopyEventPayload.omit({
    t: true,
  }),
  MonacoCutEventPayload.omit({
    t: true,
  }),
]);
export type SendPayload = z.infer<typeof SendPayload>;

export const BrowserUniqSendPayload = z.intersection(
  z.object({
    /**
     * User ID
     */
    a: z.string(),
  }),
  SendPayload,
);
export type BrowserUniqSendPayload = z.infer<typeof BrowserUniqSendPayload>;
