import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import LiveCodingChatWindowFetchContainer from "./widget/LiveCodingChatWindow/FetchContainer";
import RemoteInterviewEvaluationFetchContainer from "./widget/RemoteInterviewEvaluation/FetchContainer";

type GenerateRightSidePanelPropsArgs = {
  remoteId: string;
};

export const useGenerateRightSidePanelProps = (
  args: GenerateRightSidePanelPropsArgs,
): Pages.RemoteInterviewForEmployeeProps["rightSidePanel"] => {
  const { hasUnreadMessages } = useTwilioConversationsContext();
  const currentSession = RemotesId.useActiveSession();
  const lang = useLanguageCode();
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();
  return {
    Evaluation: <RemoteInterviewEvaluationFetchContainer uniqueKey={args.remoteId} />,
    Chat: <LiveCodingChatWindowFetchContainer />,
    hasChatBadge: hasUnreadMessages,
    followUpQuestions:
      enabledSinSClassPhase1 && currentSession && currentSession.followUpQuestions.length > 0
        ? {
            questions: currentSession.followUpQuestions.map(
              (
                followUpQuestion,
              ): Exclude<Pages.RemoteInterviewForEmployeeProps["rightSidePanel"]["followUpQuestions"], undefined>["questions"][0] => ({
                question: resolveLanguage(followUpQuestion, lang, "question"),
                answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
                purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
              }),
            ),
          }
        : undefined,
  };
};
