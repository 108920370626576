import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeTestReportSuspiciousDegreeMessageForQuestionArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeTestReportSuspiciousDegreeMessageForQuestionProps = GenerateChallengeTestReportSuspiciousDegreeMessageForQuestionArgs;

const ChallengeTestReportSuspiciousDegreeMessageForQuestion: React.FC<ChallengeTestReportSuspiciousDegreeMessageForQuestionProps> = args => {
  const ChallengeTestReportSuspiciousDegreeMessageForQuestionProps = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.ChallengeTestReportSuspiciousDegreeMessageForQuestion {...ChallengeTestReportSuspiciousDegreeMessageForQuestionProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportSuspiciousDegreeMessageForQuestion.displayName = "ChallengeTestReportSuspiciousDegreeMessageForQuestion";

export default withErrorBoundary(ChallengeTestReportSuspiciousDegreeMessageForQuestion, {});
