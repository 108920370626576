const routes = {
  "/": "/",
  "/auth": "/auth",
  "/signin": "/signin",
  "/signup": "/signup",
  /** Employee */
  "/e/home": "/e/home",
  "/e/assessments": "/e/assessments",
  "/e/assessments/:id/detail": "/e/assessments/:id/detail",
  "/e/assessments/create": "/e/assessments/create",
  "/e/assessments/:id/update": "/e/assessments/:id/update",
  "/e/exams/:id/review": "/e/exams/:id/review",
  "/e/settings/user": "/e/settings/user",
  "/e/settings/company": "/e/settings/company",
  "/e/settings/company/profile": "/e/settings/company/profile",
  "/e/settings/company/security": "/e/settings/company/security",
  "/e/settings/company/integration/sonar": "/e/settings/company/integration/sonar",
  "/e/settings/company/notification/slack": "/e/settings/notification/slack",
  "/e/settings/company/employee": "/e/settings/company/employee",
  "/e/settings/company/talent": "/e/settings/company/talent",
  "/e/settings/company/group/employee": "/e/settings/company/group/employee",
  "/e/settings/payment": "/e/settings/payment",
  "/e/settings/billing/overview": "/e/settings/billing/overview",
  "/e/settings/billing/usages": "/e/settings/billing/usages",
  "/e/settings/payment/method/update": "/e/settings/payment/method/update",
  "/e/settings/plan/list": "/e/settings/plan/list",
  "/e/settings/billing/invoices": "/e/settings/billing/invoices",
  "/e/settings/order/history": "/e/settings/order/history",
  "/e/screenings": "/e/screenings",
  "/e/screenings/:id/detail": "/e/screenings/:id/detail",
  "/e/screenings/create": "/e/screenings/create",
  "/e/screenings/:id/update": "/e/screenings/:id/update",
  "/e/screenings/tests": "/e/screenings/tests",
  "/e/screenings/tests/:id": "/e/screenings/tests/:id",
  "/e/screenings/tests/:id/detail": "/e/screenings/tests/:id/detail",
  "/e/screenings/tests/:id/review": "/e/screenings/tests/:id/review",
  "/e/interviews": "/e/interviews",
  "/e/interview-groups/list": "/e/interview-groups/list",
  "/e/questions": "/e/questions",
  "/e/interviews/create": "/e/interviews/create",
  "/e/interviews/:id/update": "/e/interviews/:id/update",
  "/e/interview-groups/create": "/e/interview-groups/create",
  "/e/interview-groups/:id/update": "/e/interview-groups/:id/update",
  "/e/interviews/:id/review": "/e/interviews/:id/review",
  "/e/interviews/:id/detail": "/e/interviews/:id/detail",
  "/e/remotes": "/e/remotes",
  "/e/remotes/:id": "/e/remotes/:id",
  "/e/remotes/:id/evaluate": "/e/remotes/:id/evaluate",
  "/e/remotes/create": "/e/remotes/create",
  "/e/remotes/:id/update": "/e/remotes/:id/update",
  "/e/remotes/:id/detail": "/e/remotes/:id/detail",
  "/e/evaluations/metrics": "/e/evaluations/metrics",
  "/e/evaluations/groups": "/e/evaluations/groups",
  "/e/evaluations/groups/:id": "/e/evaluations/groups/:id",
  "/e/evaluations/metrics/:id": "/e/evaluations/metrics/:id",
  "/e/invitations/:id": "/e/invitations/:id",
  "/e/templates/create": "/e/templates/create",
  "/e/templates/:id/update": "/e/templates/:id/update",
  "/e/templates/remote/create": "/e/templates/remote/create",
  "/e/templates/remote/:id/update": "/e/templates/remote/:id/update",
  "/e/questions/challenge/:id": "/e/questions/challenge/:id",
  "/e/questions/project/:id": "/e/questions/project/:id",
  "/e/questions/quiz/create": "/e/questions/quiz/create",
  "/e/questions/:entityType/:id": "/e/questions/:entityType/:id",
  "/e/questions/:entityType/:id/update": "/e/questions/:entityType/:id/update",
  "/e/questions/quiz/:id/update": "/e/questions/quiz/:id/update",
  "/e/questions/quiz/:id": "/e/questions/quiz/:id",
  "/e/questions/systemdesign/:id": "/e/questions/systemdesign/:id",
  "/c/demos/start": "/c/demos/start",
  "/c/demos/:id": "/c/demos/:id",
  "/c/remotes/:id": "/c/remotes/:id",
  "/c/screenings/:id": "/c/screenings/:id",
  "/c/screenings/tests/:id": "/c/screenings/tests/:id",
  "/c/screenings/tests/:id/detail": "/c/screenings/tests/:id/detail",
  "/c/interviews/:id": "/c/interviews/:id",
  "/c/interviews/:id/detail": "/c/interviews/:id/detail",
  "/c/interview-groups/:id": "c/interview-groups/:id",
  "/t/invitations/:id": "/t/invitations/:id",
  "/t/assessments": "/t/assessments",
  "/t/assessments/:id/detail": "/t/assessments/:id/detail",
  "/t/exams/:id": "/t/exams/:id",
  "/t/exams/:id/review": "/t/exams/:id/review",
  "/t/settings/user": "/t/settings/user",
  // "/c/interviews/finish": "/c/interviews/finish",
  "/reset/password": "/reset/password",
} as const;

export type Routes = typeof routes;
export type RouteKey = keyof Routes;

export default routes;
