import { Listener as QuizEventRecordServiceListener, QuizEventRecordService } from "./QuizEventRecordService";
import { Listener as QuizPackageQuestionsListener, QuizPackageQuestionsClient } from "./QuizPackageQuestionsClient";
import { Listener as QuizPackageQuestionStateClientListener, QuizPackageQuestionStateClient } from "./QuizPackageQuestionStateClient";
import { Listener as QuizPackageStateClientListener, QuizPackageStateClient } from "./QuizPackageStateClient";
import { QuizStateClient } from "./QuizStateClient";

export type CreateClientArgs = {
  quizId: number;
  quizPackageId: number;
  questionId: number;
  debug?: boolean;
};

export const createQuizEventRecordService = async (args: CreateClientArgs): Promise<QuizEventRecordService> => {
  const service = new QuizEventRecordService({
    quizId: args.quizId,
    packageId: args.quizPackageId,
    questionId: args.questionId,
    debug: args.debug,
  });
  const quizPackageQuestionStateClient = new QuizPackageQuestionStateClient({
    debug: args.debug,
  });
  const quizPackageStateClient = new QuizPackageStateClient({
    debug: args.debug,
  });
  const quizPackageQuestionsClient = new QuizPackageQuestionsClient({
    debug: args.debug,
  });
  const quizStateClient = new QuizStateClient({
    debug: args.debug,
  });

  const handleSelectSingleOption: QuizEventRecordServiceListener["selectSingleOption"]["callback"] = event => {
    quizPackageQuestionStateClient.selectSingleOption(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
        questionId: event.questionId,
      },
      event.optionId,
    );
  };

  const handleSelectMultiOption: QuizEventRecordServiceListener["selectMultiOption"]["callback"] = event => {
    quizPackageQuestionStateClient.selectMultiOption(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
        questionId: event.questionId,
      },
      event.optionId,
    );
  };

  const handleUnselectMultiOption: QuizEventRecordServiceListener["unselectMultiOption"]["callback"] = event => {
    quizPackageQuestionStateClient.unselectOption(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
        questionId: event.questionId,
      },
      event.optionId,
    );
  };

  const handleSubmitQuestion: QuizEventRecordServiceListener["submitQuestion"]["callback"] = payload => {
    quizPackageQuestionStateClient.submitQuestion(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
        questionId: payload.questionId,
      },
      payload.questionId,
    );
  };

  const handleRestoreQuestionsState: QuizPackageQuestionsListener["restoreQuestionsState"]["callback"] = payload => {
    Object.entries(payload.data).forEach(([key, data]) => {
      const questionId = Number(key);
      if (Number.isNaN(questionId)) {
        return;
      }
      const finalActions = QuizPackageQuestionStateClient.generateNormalizeEventBySelectOption(data.state || {});
      if (finalActions.type === "SINGLE") {
        const options = Array.from(finalActions.options);
        const firstOption = options.at(0);
        if (firstOption !== undefined) {
          service.updateQuizQuestionStateMapWithoutEmit({
            questionId,
            selectedOptionIds: new Set([firstOption]),
          });
        } else {
          service.updateQuizQuestionStateMapWithoutEmit({
            questionId,
            selectedOptionIds: new Set(),
          });
        }
      } else {
        service.updateQuizQuestionStateMapWithoutEmit({
          questionId,
          selectedOptionIds: finalActions.options,
        });
      }
    });
  };

  const handleQuizPackageQuestionStateObject: QuizPackageQuestionStateClientListener["quizPackageQuestionStateObject"]["callback"] = event => {
    const finalActions = QuizPackageQuestionStateClient.generateNormalizeEventBySelectOption(event.data);
    if (finalActions.type === "SINGLE") {
      const options = Array.from(finalActions.options);
      const firstOption = options.at(0);
      if (firstOption !== undefined) {
        service.restoreSingleOption(finalActions.questionId, firstOption);
      } else {
        service.clearOptions(finalActions.questionId);
      }
    } else {
      service.restoreMultiChoiceOptions(finalActions.questionId, finalActions.options);
    }
  };

  const handleSelectOption: QuizPackageQuestionStateClientListener["selectOption"]["callback"] = event => {
    switch (event.action) {
      case "rep": {
        service.selectSingleOption(event.questionId, event.optionId);
        break;
      }
      case "set": {
        service.selectMultiOption(event.questionId, event.optionId);
        break;
      }
      case "uset": {
        service.unselectMultiOption(event.questionId, event.optionId);
        break;
      }
      default: {
        throw new Error(`Event Action is invalid value: ${event.action satisfies never}`);
      }
    }
  };

  const handleRestoreSelectQuestionId: QuizPackageStateClientListener["restoreSelectQuestionId"]["callback"] = async event => {
    service.restoreQuestionId(event.questionId);
    await quizPackageQuestionStateClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: service.selectedPackageId,
      questionId: event.questionId,
    });
  };

  const handleSelectQuestionId: QuizPackageStateClientListener["selectQuestionId"]["callback"] = async event => {
    service.selectQuestion(event.questionId);
    await quizPackageQuestionStateClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: service.selectedPackageId,
      questionId: event.questionId,
    });
  };

  const handleDispose: QuizEventRecordServiceListener["dispose"]["callback"] = async () => {
    quizPackageQuestionStateClient.dispose();
    quizPackageQuestionsClient.dispose();
    quizPackageStateClient.dispose();
    quizStateClient.dispose();
  };

  const handleSelectPackage: QuizEventRecordServiceListener["selectPackage"]["callback"] = async event => {
    await quizPackageQuestionStateClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: event.packageId,
      questionId: service.selectedQuestionId,
    });
    await quizPackageQuestionsClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: event.packageId,
    });
    await quizPackageStateClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: event.packageId,
    });
  };

  const handleComeOutQuizPackage: QuizEventRecordServiceListener["comeOutQuizPackage"]["callback"] = async event => {
    await quizPackageStateClient.comeOutQuizPackage(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
      },
      event.questionId,
    );
  };

  const handleChangeQuestionId: QuizEventRecordServiceListener["changeQuestionId"]["callback"] = async event => {
    service.emitChangeConnectEvent({ status: "CONNECTING" });
    await quizPackageStateClient.comeOutQuizPackage(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
      },
      event.previousQuestionId,
    );
    await quizPackageStateClient.selectQuestion(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
      },
      event.questionId,
    );
    await quizPackageStateClient.getIntoQuizPackage(
      {
        quizId: service.selectedQuizId,
        quizPackageId: service.selectedPackageId,
      },
      event.questionId,
    );

    await quizPackageQuestionStateClient.reconnect({
      quizId: service.selectedQuizId,
      quizPackageId: service.selectedPackageId,
      questionId: event.questionId,
    });
    service.emitChangeConnectEvent({ status: "CONNECTED" });
  };

  quizPackageQuestionsClient.once("restoreQuestionsState", handleRestoreQuestionsState);
  quizPackageQuestionStateClient.on("quizPackageQuestionStateObject", handleQuizPackageQuestionStateObject);
  quizPackageQuestionStateClient.on("selectOption", handleSelectOption);
  quizPackageStateClient.on("selectQuestionId", handleSelectQuestionId);
  quizPackageStateClient.on("restoreSelectQuestionId", handleRestoreSelectQuestionId);
  service.on("selectSingleOption", handleSelectSingleOption);
  service.on("selectMultiOption", handleSelectMultiOption);
  service.on("unselectMultiOption", handleUnselectMultiOption);
  service.on("submitQuestion", handleSubmitQuestion);
  service.on("dispose", handleDispose);
  service.on("comeOutQuizPackage", handleComeOutQuizPackage), service.on("selectPackage", handleSelectPackage);
  service.on("changeQuestionId", handleChangeQuestionId);

  /**
   * DO NOT CHANGE CONNECT ORDER
   *
   * QuizPackageStateClient must be connected before QuizPackageQuestionClient because it restores the selected questionId at initialization.
   *
   * `QuizPackageQuestionsClient` restores the state of `questions` in `packages/${id}/questions` and synchronizes the state of `service` with the state of the realtime database.
   *
   * The `QuizPackageQuestionStateClient` does the same thing, but if the state of the service is synchronized,
   * it will not emit unnecessary events.
   * Therefore, `QuizPackageQuestionStateClient` needs to be connected later than `QuizPackageQuestionsClient`.
   */
  await quizPackageStateClient.connect({
    quizId: service.selectedQuizId,
    quizPackageId: service.selectedPackageId,
  });
  await quizPackageQuestionsClient.connect({
    quizId: service.selectedQuizId,
    quizPackageId: service.selectedPackageId,
  });
  await quizPackageQuestionStateClient.connect({
    quizId: service.selectedQuizId,
    quizPackageId: service.selectedPackageId,
    questionId: service.selectedQuestionId,
  });
  await quizStateClient.connect({
    quizId: service.selectedQuizId,
  });

  quizPackageStateClient.getIntoQuizPackage(
    {
      quizId: service.selectedQuizId,
      quizPackageId: service.selectedPackageId,
    },
    service.selectedQuestionId,
  );

  return service;
};
