import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Def from "../../shared/payment/definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useCustomerStatus = (): Types.CustomerStatus => {
  const snapshot = useSnapshotState();
  if (!snapshot.customerV2) {
    return "BEFORE_FETCH";
  }
  return snapshot.customerV2.paymentStatus !== "UNKNOWN" ? "VALID" : "INVALID";
};

const usePaymentItemType = (): Graphql.PaymentItemType => {
  const snapshot = useSnapshotState();
  if (!snapshot.customerV2) {
    return Graphql.PaymentItemType.Unknown;
  }
  const paymentV2ItemType: Def.PaymentV2ItemType = `${snapshot.customerV2.subscription.plan.planType}.${snapshot.customerV2.subscription.plan.generation}`;
  return Def.paymentV2ItemTypeToPaymentItemType[paymentV2ItemType] || Graphql.PaymentItemType.Unknown;
};

/**
 * @example
 *
 * ```ts
 * const isAvailableFeature = useGetAvailableFeature();
 *
 * isAvailableFeature("test.report.create");
 * ```
 *
 * @returns boolean
 */
export const useIsAvailableFeature = () => {
  const paymentItemType = usePaymentItemType();
  const isAvailableFeature = React.useCallback(
    (feature: Def.AvailableFeatureName): boolean => {
      const target = Def.FeaturePlanMap[feature] as Def.PaymentItemType[];
      return target.includes(paymentItemType);
    },
    [paymentItemType],
  );
  return isAvailableFeature;
};
export type IsAvailableFeature = ReturnType<typeof useIsAvailableFeature>;

export const useIsAvailableQuestionToUse = (target: "test" | "exam" | "remote") => {
  const isAvailableFeature = useIsAvailableFeature();

  const questionVariantMap = React.useMemo((): Record<Graphql.QuestionVariant, boolean> => {
    if (target === "remote") {
      return {
        [Graphql.QuestionVariant.Challenge]: true,
        [Graphql.QuestionVariant.ChallengeAlgorithm]: true,
        [Graphql.QuestionVariant.ChallengeClass]: true,
        [Graphql.QuestionVariant.ChallengeDatabase]: true,
        [Graphql.QuestionVariant.Quiz]: false,
        [Graphql.QuestionVariant.Project]: false,
        [Graphql.QuestionVariant.ProjectBackend]: false,
        [Graphql.QuestionVariant.ProjectDataScience]: false,
        [Graphql.QuestionVariant.ProjectFrontend]: false,
        [Graphql.QuestionVariant.ProjectOther]: false,
        [Graphql.QuestionVariant.SystemDesign]: true,
      };
    }
    return {
      [Graphql.QuestionVariant.Challenge]: true,
      [Graphql.QuestionVariant.ChallengeAlgorithm]: true,
      [Graphql.QuestionVariant.ChallengeClass]: true,
      [Graphql.QuestionVariant.ChallengeDatabase]: true,
      [Graphql.QuestionVariant.Quiz]: true,
      [Graphql.QuestionVariant.Project]: isAvailableFeature(`${target}.project.create`),
      [Graphql.QuestionVariant.ProjectBackend]: isAvailableFeature(`${target}.project.create`),
      [Graphql.QuestionVariant.ProjectDataScience]: isAvailableFeature(`${target}.project.create`),
      [Graphql.QuestionVariant.ProjectFrontend]: isAvailableFeature(`${target}.project.create`),
      [Graphql.QuestionVariant.ProjectOther]: isAvailableFeature(`${target}.project.create`),
      [Graphql.QuestionVariant.SystemDesign]: isAvailableFeature(`${target}.systemDesign.create`),
    };
  }, [isAvailableFeature, target]);

  return React.useCallback(
    (variant: Graphql.QuestionVariant) => {
      return questionVariantMap[variant];
    },
    [questionVariantMap],
  );
};

export const useCustomerV2 = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.customerV2) {
    throw new Error("Please Initialize Customer V2");
  }
  return snapshot.customerV2;
};

export const useAvailableForPurchase = (): boolean => {
  const customer = useCustomerV2();
  if (customer.paymentType === "UNKNOWN") {
    return false;
  }
  switch (customer.paymentStatus) {
    case "SUSPENDED":
    case "UNKNOWN":
    case "UNPAID":
      return false;
    case "PAID":
      return true;
    default:
      throw new Error(`paymentStatus is unknown: ${customer.paymentStatus satisfies never}`);
  }
};

/**
 * @deprecated This API will be deprecated once Payment V2 is connected.
 */
export const useOptionalCustomerV2 = () => {
  const snapshot = useSnapshotState();
  return snapshot.customerV2;
};

export const useAccountTypeDisplayTextMap = (): Record<Graphql.PaymentV2AccountType, string> => {
  const { t } = useTranslation();
  return {
    FUTSU: t("普通口座"),
    TOZA: t("当座口座"),
  };
};
