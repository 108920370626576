import { getRef, getTimestamp } from "@hireroo/firebase";
import { PlaybackEvent } from "@hireroo/validator";
import { EventEmitter } from "events";
import type firebase from "firebase/compat/app";

import { revisionFromId, revisionToId } from "../firepad";
import type * as Types from "./ClientType";

export interface Listener {
  selectQuestionId: {
    payload: { questionId: number };
    callback: (payload: Listener["selectQuestionId"]["payload"]) => void;
  };
  restoreSelectQuestionId: {
    payload: { questionId: number };
    callback: (payload: Listener["restoreSelectQuestionId"]["payload"]) => void;
  };
  selectPackageId: {
    payload: { packageId: number };
    callback: (payload: Listener["selectPackageId"]["payload"]) => void;
  };
  skippedChildAdded: {
    payload: { revisionId: string; data: unknown };
    callback: (payload: Listener["skippedChildAdded"]["payload"]) => void;
  };
}

type EventName = keyof Listener;

export type CreateReferenceArgs = {
  quizId: number;
  quizPackageId: number;
};

export type AppendParams = {
  quizId: number;
  quizPackageId: number;
  event: PlaybackEvent.QuizPackageState;
};

export type InitializeConfig = {
  debug?: boolean;
};

/**
 * Firebase Realtime Database Path: quizzes/${quizId}/packages/${quizPackageId}/state
 */
export class QuizPackageStateClient implements Types.BaseStateClient {
  #emitter = new EventEmitter();
  #isDisposed = false;
  #isConnected = false;
  #disposeClientListener: () => void = () => undefined;

  #restoredIndex: number = NaN;
  #queueAppendTasks: Map<number, { run: () => void; event: PlaybackEvent.QuizPackageState; count: number }> = new Map();
  #queueCounter = 0;
  #previousAppendEvent: PlaybackEvent.QuizPackageState | undefined;

  #debug = false;

  constructor(private readonly config: InitializeConfig) {
    this.#debug = config.debug ?? false;
  }

  private static createReference = (config: CreateReferenceArgs) => {
    return getRef("quiz", `quizzes/${config.quizId}/packages/${config.quizPackageId}/state`);
  };

  private debugMessage = (methodName: string, message: () => string) => {
    if (this.#debug) {
      console.log(["%c[DEBUG]", `QuizPackageStateClient#${methodName}`, message()].join(" "), "color: #f9a302;");
    }
  };

  public reconnect = async (config: CreateReferenceArgs) => {
    this.#restoredIndex = NaN;
    this.#previousAppendEvent = undefined;
    this.#disposeClientListener();
    this.#isConnected = false;
    await this.connect(config);
  };

  public connect = async (config: CreateReferenceArgs): Promise<void> => {
    if (this.#isConnected) {
      return;
    }
    if (this.#isDisposed) {
      console.warn(`QuizPackageStateClient was disposed in connect sequence`);
      return;
    }
    const client = QuizPackageStateClient.createReference(config);
    this.#isConnected = true;

    await client.once("value", this.handleOnceValue);
    client.on("child_added", this.handleChildAdded);

    this.#disposeClientListener = () => {
      client.off("value", this.handleOnceValue);
      client.off("child_added", this.handleChildAdded);
    };
  };

  public dispose = () => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.removeAllListeners();
    this.#disposeClientListener();
    this.#isDisposed = true;
  };

  private handleChildAdded = (snapshot: firebase.database.DataSnapshot) => {
    if (this.#isDisposed) {
      return;
    }
    const data = snapshot.val();
    if (!data || !snapshot.key) {
      return;
    }
    const hasAlreadyBeenRestored = revisionFromId(snapshot.key) <= this.#restoredIndex;
    if (hasAlreadyBeenRestored) {
      this.emit("skippedChildAdded", {
        revisionId: snapshot.key,
        data,
      });

      const task = this.getFirstTask();
      task?.run();
      return;
    }
    const result = PlaybackEvent.QuizPackageState.safeParse(data);

    if (result.success) {
      switch (result.data.s) {
        case "selq": {
          this.emit("selectQuestionId", {
            questionId: result.data.v,
          });
          break;
        }
        case "outq":
        case "inq": {
          break;
        }
        default:
          throw new Error(`Invalid value: ${result.data satisfies never}`);
      }
    } else {
      console.warn({
        raw: data,
        error: result.error,
      });
    }
    /**
     * Get a task from the beginning of the queued task and execute it.
     */
    const task = this.getFirstTask();
    task?.run();
  };

  private static fetchLatestIndex = (client: firebase.database.Reference): Promise<number> => {
    return new Promise(resolve => {
      client.once("value", snapshot => {
        const data = snapshot.val();
        if (!data) {
          return resolve(0);
        }
        const result = PlaybackEvent.QuizPackageStateObject.safeParse(data);
        if (!result.success) {
          return resolve(0);
        }
        const lastKey = Object.keys(result.data).at(-1);
        if (lastKey) {
          return resolve(revisionFromId(lastKey));
        }
        return resolve(0);
      });
    });
  };

  private handleOnceValue = (snapshot: firebase.database.DataSnapshot) => {
    const data = snapshot.val();
    if (!data) {
      return;
    }
    const result = PlaybackEvent.QuizPackageStateObject.safeParse(data);
    if (result.success) {
      const keys = Object.keys(result.data).sort();
      const lastKey = keys.at(-1);
      if (lastKey) {
        this.#restoredIndex = revisionFromId(lastKey);
      }

      const keyForLatestSelectQuestionEvent = keys.findLast(key => {
        const value = result.data[key];
        if (!value) {
          return false;
        }
        return value.s === "selq";
      });
      const latestSelectQuestionEvent = keyForLatestSelectQuestionEvent ? result.data[keyForLatestSelectQuestionEvent] : undefined;
      if (keyForLatestSelectQuestionEvent && latestSelectQuestionEvent) {
        this.debugMessage("handleOnceValue", () => `restored key = ${latestSelectQuestionEvent.v}`);
        this.emit("restoreSelectQuestionId", {
          questionId: latestSelectQuestionEvent.v,
        });
      }
    } else {
      console.warn({
        error: result.error,
        raw: data,
      });
    }
  };

  private getFirstTask = () => {
    const firstTs = Array.from(this.#queueAppendTasks.keys())
      .sort((a, b) => a - b)
      .at(0);
    if (firstTs) {
      return this.#queueAppendTasks.get(firstTs);
    }
  };

  private append = async (params: AppendParams, count = this.#queueCounter + 1): Promise<void> => {
    const { quizId, quizPackageId, event } = params;
    if (this.#isDisposed) {
      return;
    }

    /**
     * Skip to prevent double counting.
     *
     * Although double posting by user operation is not allowed in the specification, the possibility of multiple execution of the API cannot be denied, so it is prevented here.
     */
    if (this.#previousAppendEvent?.s === event.s && this.#previousAppendEvent.v === event.v) {
      this.debugMessage("append", () => `The same Event was appended last time. ${JSON.stringify(event)}`);
      return;
    }
    this.#previousAppendEvent = event;

    if (this.#queueCounter < count) {
      this.#queueCounter = count;
    }
    const result = PlaybackEvent.QuizPackageState.safeParse(event);
    if (!result.success) {
      console.warn({
        event,
        error: result.error,
      });
      return;
    }
    /**
     * Register a timestamp of when the append function was executed and sort the order of execution.
     */
    this.#queueAppendTasks.set(count, {
      count,
      event,
      run: () => {
        this.debugMessage("append#run", () => JSON.stringify({ count, event }));
        this.append(
          {
            ...params,
            event: result.data,
          },
          count,
        );
      },
    });

    this.debugMessage(
      "append",
      () => `Queue Length: ${this.#queueAppendTasks.size}, values=${JSON.stringify([...this.#queueAppendTasks.values()])}`,
    );
    const client = QuizPackageStateClient.createReference({
      quizId,
      quizPackageId: quizPackageId,
    });

    /**
     * Ensure that the information held by the DB is verified before writing to ensure that the index is incremented.
     */
    const nextIndex = (await QuizPackageStateClient.fetchLatestIndex(client)) + 1;
    if (nextIndex === this.#restoredIndex) {
      return;
    }
    const nextTask = this.getFirstTask();
    if (!nextTask) {
      this.debugMessage("append", () => `Not found Next Task. Queue Length: ${this.#queueAppendTasks.size}`);
      return;
    }
    this.#restoredIndex = nextIndex;
    this.#queueAppendTasks.delete(nextTask.count);
    const revisionId = revisionToId(nextIndex);

    this.debugMessage("append", () => `${JSON.stringify(nextTask.event)} ${nextIndex} - ${revisionId}`);
    await client.child(revisionId).set(nextTask.event);
  };

  public on = <T extends EventName>(eventName: T, callback: Listener[T]["callback"]) => {
    this.#emitter.on(eventName, callback);
    return () => {
      this.#emitter.off(eventName, callback);
    };
  };

  private emit = <T extends EventName>(eventName: T, payload: Listener[T]["payload"]) => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.emit(eventName, payload);
  };

  // ===== Friendly interface =====

  public selectQuestion = (params: Omit<AppendParams, "event">, questionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "selq",
        v: questionId,
        t: getTimestamp(),
      },
    });
  };
  public comeOutQuizPackage = (params: Omit<AppendParams, "event">, questionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "outq",
        v: questionId,
        t: getTimestamp(),
      },
    });
  };
  public getIntoQuizPackage = (params: Omit<AppendParams, "event">, questionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "inq",
        v: questionId,
        t: getTimestamp(),
      },
    });
  };
}
