import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AppealMessageInReport, { AppealMessageInReportProps } from "../../modules/AppealMessageInReport/AppealMessageInReport";
import PlaybackModeSwitcher, { PlaybackModeSwitcherProps } from "../../modules/PlaybackModeSwitcher/PlaybackModeSwitcher";
import ChallengePlaybackSubmitCodeView, {
  ChallengePlaybackSubmitCodeViewProps,
} from "./parts/ChallengePlaybackSubmitCodeView/ChallengePlaybackSubmitCodeView";
import ReportChallengePlayback, { ReportChallengePlaybackProps } from "./parts/ReportChallengePlayback/ReportChallengePlayback";

type EditorMode = "SUBMIT_RESULT" | "PLAYBACK";

export type ChallengePlaybackProps = {
  appealMessage?: string;
  /**
   * switch for playback or submitted code
   */
  playbackSwitcher?: Pick<PlaybackModeSwitcherProps, "enableMode" | "onDialogClose" | "onChangeMode">;
  playBack?: Omit<ReportChallengePlaybackProps, "appealMessageInReport">;
  PlaybackUpsellWithVideo?: React.ReactNode;
  targetElementId?: string;

  playbackSubmitCodeView: ChallengePlaybackSubmitCodeViewProps;

  sliderValue: number;
  editorMode: EditorMode;
  updateEditorMode: (mode: EditorMode) => void;

  SuspiciousDegreeMessageForQuestion: React.ReactNode;
};

const ChallengePlayback: React.FC<ChallengePlaybackProps> = props => {
  const { t } = useTranslation();
  const { editorMode, updateEditorMode: setEditorMode } = props;
  const playbackSwitch: PlaybackModeSwitcherProps | undefined = props.playbackSwitcher && {
    ...props.playbackSwitcher,
    onChangeMode: mode => {
      if (mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
        setEditorMode(mode);
      }
    },
    forceMode: editorMode,
  };

  const appealMessageAreaProps: AppealMessageInReportProps = {
    body: props.appealMessage ? props.appealMessage : t("受験者が入力した説明文はありません。"),
    title: t("候補者による提出されたフローチャートの説明を確認することができます。"),
  };

  const reportChallengePlaybackProps = props.playBack
    ? {
        ...props.playBack,
        appealMessageInReport: appealMessageAreaProps,
      }
    : undefined;

  return (
    <Box id={props.targetElementId}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={3}>
        <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })}>
          {t("提出コード")}
        </Typography>
        {playbackSwitch && <PlaybackModeSwitcher key={editorMode} {...playbackSwitch} />}
      </Box>
      <Box mb={3}>{props.SuspiciousDegreeMessageForQuestion}</Box>
      <Box position={"relative"} overflow={"hidden"} mt={2} mb={2}>
        {editorMode === "SUBMIT_RESULT" && <ChallengePlaybackSubmitCodeView {...props.playbackSubmitCodeView} />}
        {editorMode === "PLAYBACK" && reportChallengePlaybackProps && (
          <Box height="100%" width="100%">
            {reportChallengePlaybackProps && <ReportChallengePlayback {...reportChallengePlaybackProps} />}
          </Box>
        )}
        {editorMode === "PLAYBACK" && props.PlaybackUpsellWithVideo && (
          <Box height="100%" width="100%">
            {props.PlaybackUpsellWithVideo}
          </Box>
        )}
      </Box>
      <AppealMessageInReport {...appealMessageAreaProps} />
    </Box>
  );
};

ChallengePlayback.displayName = "ChallengePlayback";

export default ChallengePlayback;
