import { usePlanNameMap } from "@hireroo/app-definition/payment";
import { Company, Payment } from "@hireroo/app-store/essential/employee";
import { BillingOverview } from "@hireroo/app-store/widget/e/BillingOverview";
import { unixToDatetimeFormat } from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GenerateBillingOverviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateBillingOverviewPropsArgs): Widget.BillingOverviewProps => {
  const navigate = useTransitionNavigate();
  const company = Company.useStrictActiveCompany();
  const customer = BillingOverview.useCustomer();
  const contractPeriodUsages = BillingOverview.useContractPeriodUsages();
  const accountTypeDisplayTextMap = Payment.useAccountTypeDisplayTextMap();
  const { t } = useTranslation();
  const { subscription } = customer;
  const packPlan = React.useMemo((): Widget.BillingOverviewProps["pack"]["plan"] => {
    if (customer.isTrial) {
      return {
        kind: "TRIAL",
      };
    }
    if (subscription.packStock > 0) {
      return {
        kind: "BULK",
        count: subscription.packStock,
      };
    }
    return {
      kind: "PAY_AS_YOU_GO",
    };
  }, [customer, subscription]);

  const planNameMap = usePlanNameMap(customer.subscription.plan.generation);

  const hasPaymentMethod = React.useMemo((): boolean => {
    if (customer.isTrial) {
      return true;
    }
    return customer.paymentType !== "UNKNOWN";
  }, [customer]);

  const contractPeriodLabel = [
    subscription.didStartAtSeconds ? unixToDatetimeFormat(subscription.didStartAtSeconds) : "",
    subscription.willEndAtSeconds ? unixToDatetimeFormat(subscription.willEndAtSeconds) : "",
  ].join(" ~ ");

  const nextPaymentDate = React.useMemo((): Widget.BillingOverviewProps["paymentInformation"]["nextPaymentDate"] => {
    if (customer.isTrial) {
      return undefined;
    }

    switch (customer.paymentType) {
      case "CARD":
        return {
          label: t("次回のお支払日"),
          description: subscription.nextPaymentAtSeconds ? unixToDatetimeFormat(subscription.nextPaymentAtSeconds) : "-",
        };
      case "INVOICE":
        return {
          label: t("次回の請求書発行日"),
          description: subscription.nextPaymentAtSeconds ? unixToDatetimeFormat(subscription.nextPaymentAtSeconds) : "-",
          tooltipText: t("お支払期日は請求書発行日の翌月末になります。"),
        };
      case "UNKNOWN":
        return undefined;
      default:
        throw new Error(`Payment Type is invalid ${customer.paymentType satisfies never}`);
    }
  }, [customer.isTrial, customer.paymentType, subscription.nextPaymentAtSeconds, t]);

  return {
    plan: {
      planLabel: customer.subscription ? planNameMap[customer.subscription.plan.planType] : "-",
      planSubLabel: customer.isTrial ? [t("トライアル期間"), contractPeriodLabel].join(":") : undefined,
      contactUsButton: customer.isTrial && customer.subscription.status === "ACTIVE" ? {} : undefined,
    },
    contract: {
      contractPeriodLabel: customer.isTrial ? { kind: "TRIAL" } : { kind: "TEXT", value: contractPeriodLabel },
      editButton: {
        disabled: customer.isTrial,
      },
      showAutoRenewalLabel: customer.isAutoRenewal,
    },
    pack: {
      plan: packPlan,
    },
    paymentInformation: {
      hasPaymentMethod: hasPaymentMethod,
      editPaymentMethod: {
        disabled: customer.isTrial,
        onClick: () => {
          navigate("/e/settings/payment/method/update");
        },
      },
      billingInformation: {
        companyName: company.name,
        mailAddress: customer.email,
        address: [customer.postalCode, customer.state, customer.city, customer.line1, customer.line2].join(" "),
        paymentMethod: ((): Widget.BillingOverviewProps["paymentInformation"]["billingInformation"]["paymentMethod"] => {
          if (customer.paymentType === "CARD") {
            return {
              kind: "CREDIT_CARD",
              field: {
                lastFour: customer.lastFour,
                warningMessage: (() => {
                  switch (customer.paymentStatus) {
                    case "SUSPENDED":
                      return [
                        t("現在、お支払いが確認できてないためサービスがご利用いただけません。"),
                        t("お支払い方法に不備がないかご確認ください。"),
                      ].join("");
                    case "UNPAID":
                      return [
                        t("現在、お支払いが確認できておりません。"),
                        t("2週間以上この状態が続くとサービスがご利用できなくなります。"),
                        t("お支払い方法に不備がないかご確認ください。"),
                      ].join("");
                    case "PAID":
                    case "UNKNOWN":
                      return undefined;
                  }
                })(),
              },
            };
          }
          if (customer.paymentType === "INVOICE") {
            return {
              kind: "BANK",
              field: {
                bankName: customer.bankName,
                branchName: `${customer.branchName} (${customer.branchCode})`,
                accountNumber: customer.accountNumber,
                accountHolderName: customer.accountHolderName,
                accountType: accountTypeDisplayTextMap[customer.accountType],
              },
            };
          }
          return undefined;
        })(),
      },
      nextPaymentDate: nextPaymentDate,
    },
    usageBarGraphSection: {
      link: {
        href: generatePath("/e/settings/billing/usages"),
        onClick: () => {
          navigate("/e/settings/billing/usages");
        },
      },
      usageBarGraph: {
        labels: [
          {
            key: "paidCount",
          },
          {
            key: "freeCount",
          },
        ],
        data: contractPeriodUsages.map(contractPeriodUsage => {
          return {
            yearMonth: contractPeriodUsage.yearMonth,
            totalCount: contractPeriodUsage.totalCount,
            paidCount: contractPeriodUsage.paidCount,
            freeCount: contractPeriodUsage.freeCount,
          };
        }),
      },
    },
  };
};
