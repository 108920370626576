import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../../../../../modules/FollowUpQuestion/FollowUpQuestion";

const StyledFollowUpQuestion = styled(FollowUpQuestion)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

export type FollowUpQuestionsProps = {
  questions: FollowUpQuestionProps[];
};

const FollowUpQuestions: React.FC<FollowUpQuestionsProps> = props => {
  return (
    <Stack spacing={1} p={1.5}>
      {props.questions.map((question, index) => (
        <StyledFollowUpQuestion key={`question-${index}`} {...question} />
      ))}
    </Stack>
  );
};

FollowUpQuestions.displayName = "FollowUpQuestions";

export default FollowUpQuestions;
