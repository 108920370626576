import { getRef } from "@hireroo/firebase";
import { PlaybackEvent } from "@hireroo/validator";
import { EventEmitter } from "events";
import type firebase from "firebase/compat/app";

import type * as Types from "./ClientType";

export interface Listener {
  restoreQuestionsState: {
    payload: { data: PlaybackEvent.QuizPackageQuestionsObject };
    callback: (payload: Listener["restoreQuestionsState"]["payload"]) => void;
  };
}

type EventName = keyof Listener;

export type CreateReferenceArgs = {
  quizId: number;
  quizPackageId: number;
};

export type InitializeConfig = {
  debug?: boolean;
};

/**
 * Firebase Realtime Database Path: quizzes/${quizId}/packages/${quizPackageId}/questions
 */
export class QuizPackageQuestionsClient implements Types.BaseStateClient {
  #emitter = new EventEmitter();
  #isDisposed = false;
  #isConnected = false;
  #disposeClientListener: () => void = () => undefined;
  #debug = false;

  constructor(config: InitializeConfig) {
    this.#debug = config.debug ?? false;
  }

  private static createReference = (config: CreateReferenceArgs) => {
    return getRef("quiz", `quizzes/${config.quizId}/packages/${config.quizPackageId}/questions`);
  };

  private debugMessage = (methodName: string, message: () => string) => {
    if (this.#debug) {
      console.log(["%c[DEBUG]", `QuizPackageQuestionsClient#${methodName}`, message()].join(" "), "color: #f03353;");
    }
  };

  public reconnect = async (config: CreateReferenceArgs) => {
    this.#disposeClientListener();
    this.#isConnected = false;
    await this.connect(config);
  };

  public connect = async (config: CreateReferenceArgs): Promise<void> => {
    if (this.#isConnected) {
      return;
    }
    if (this.#isDisposed) {
      console.warn(`QuizPackageQuestionsClient was disposed in connect sequence`);
      return;
    }
    const client = QuizPackageQuestionsClient.createReference(config);
    this.#isConnected = true;

    await client.once("value", this.handleOnceValue);
    this.#disposeClientListener = () => {
      client.off("value", this.handleOnceValue);
    };
  };

  public dispose = () => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.removeAllListeners();
    this.#disposeClientListener();
    this.#isDisposed = true;
  };

  public once = <T extends EventName>(eventName: T, callback: Listener[T]["callback"]) => {
    this.#emitter.once(eventName, callback);
    return () => {
      this.#emitter.off(eventName, callback);
    };
  };

  private emit = <T extends EventName>(eventName: T, payload: Listener[T]["payload"]) => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.emit(eventName, payload);
  };

  private handleOnceValue = (snapshot: firebase.database.DataSnapshot) => {
    const data = snapshot.toJSON();
    if (!data) {
      this.emit("restoreQuestionsState", {
        data: {},
      });
      return;
    }
    const result = PlaybackEvent.QuizPackageQuestionsObject.safeParse(data);
    if (result.success) {
      this.emit("restoreQuestionsState", {
        data: result.data,
      });
    } else {
      console.warn({
        raw: data,
        error: result.error,
      });
    }
  };
}
