import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { EventEmitter } from "events";

type QuizId = number;
type QuizPackageId = number;
type QuizQuestionId = number;
type QuizOptionId = number;

export interface Listener {
  restoreQuestionId: {
    payload: { questionId: QuizQuestionId };
    callback: (payload: Listener["restoreQuestionId"]["payload"]) => void;
  };
  changeQuestionId: {
    payload: { questionId: QuizQuestionId; previousQuestionId: QuizQuestionId };
    callback: (payload: Listener["changeQuestionId"]["payload"]) => void;
  };
  selectSingleOption: {
    payload: { questionId: number; optionId: QuizOptionId };
    callback: (payload: Listener["selectSingleOption"]["payload"]) => void;
  };
  restoreMultiOptions: {
    payload: { optionId: Set<QuizOptionId> };
    callback: (payload: Listener["restoreMultiOptions"]["payload"]) => void;
  };
  selectMultiOption: {
    payload: { questionId: number; optionId: QuizOptionId };
    callback: (payload: Listener["selectMultiOption"]["payload"]) => void;
  };
  unselectMultiOption: {
    payload: { questionId: number; optionId: QuizOptionId };
    callback: (payload: Listener["unselectMultiOption"]["payload"]) => void;
  };
  clearOptions: {
    payload: { questionId: number };
    callback: (payload: Listener["clearOptions"]["payload"]) => void;
  };
  selectPackage: {
    payload: { packageId: QuizPackageId };
    callback: (payload: Listener["selectPackage"]["payload"]) => void;
  };
  changeSingleSelectedOptions: {
    payload: { questionId: QuizQuestionId; optionIds: Set<QuizOptionId> };
    callback: (payload: Listener["changeSingleSelectedOptions"]["payload"]) => void;
  };
  changeMultiSelectedOptions: {
    payload: { questionId: QuizQuestionId; optionIds: Set<QuizOptionId> };
    callback: (payload: Listener["changeMultiSelectedOptions"]["payload"]) => void;
  };
  submitQuestion: {
    payload: { questionId: QuizQuestionId };
    callback: (payload: Listener["submitQuestion"]["payload"]) => void;
  };
  comeOutQuizPackage: {
    payload: { questionId: QuizQuestionId };
    callback: (payload: Listener["comeOutQuizPackage"]["payload"]) => void;
  };
  dispose: {
    payload: {};
    callback: (payload: Listener["dispose"]["payload"]) => void;
  };
  /**
   * firebaseに対するアクセスの切り替えイベント
   */
  changeConnect: {
    payload: { status: "CONNECTING" | "CONNECTED" };
    callback: (payload: Listener["changeConnect"]["payload"]) => void;
  };
}

type EventName = keyof Listener;

type QuizQuestionState = {
  questionId: QuizQuestionId;
  selectedOptionIds: Set<QuizOptionId>;
};

type QuizQuestionStateMap = Map<QuizQuestionId, QuizQuestionState>;

export type QuizEventRecordServiceConfig = {
  quizId: QuizId;
  packageId: QuizPackageId;
  questionId: QuizQuestionId;
  debug?: boolean;
};

export class QuizEventRecordService {
  #emitter = new EventEmitter();
  #selectedQuizId: QuizId;
  #selectedPackageId: QuizPackageId;
  #selectedQuestionId: QuizQuestionId;
  #quizQuestionStateMap: QuizQuestionStateMap = new Map();
  #isDisposed = false;
  #debug = false;

  constructor(config: QuizEventRecordServiceConfig) {
    this.#selectedQuizId = config.quizId;
    this.#selectedPackageId = config.packageId;
    this.#selectedQuestionId = config.questionId;
    this.#debug = config.debug ?? false;
    this.debugMessage("new", () => {
      return `Package:${config.packageId}, QuestionId:${config.questionId}`;
    });
  }

  private debugMessage = (methodName: string, message: () => string) => {
    if (this.#debug) {
      console.log(["%c[DEBUG]", `QuizEventRecordService#${methodName}`, message()].join(" "), "color: #fb4d35");
    }
  };

  public get quizQuestionStateMap() {
    return this.#quizQuestionStateMap;
  }

  public updateQuizQuestionStateMapWithoutEmit = (state: QuizQuestionState) => {
    this.debugMessage("updateQuizQuestionStateMapWithoutEmit", () =>
      JSON.stringify({
        questionId: state.questionId,
        selectedOptionIds: [...state.selectedOptionIds],
      }),
    );
    this.#quizQuestionStateMap.set(state.questionId, state);
  };

  public get quizQuestionState(): QuizQuestionState | undefined {
    return this.#quizQuestionStateMap.get(this.#selectedQuestionId);
  }

  public get selectedQuizId(): QuizId {
    return this.#selectedQuizId;
  }

  public get selectedPackageId(): QuizPackageId {
    return this.#selectedPackageId;
  }

  public get selectedQuestionId(): QuizQuestionId {
    return this.#selectedQuestionId;
  }

  public get selectedOptions(): Set<QuizOptionId> {
    return new Set(this.quizQuestionState?.selectedOptionIds ?? []);
  }

  public getSelectedOptionsByQuestionId(questionId: QuizQuestionId): Set<QuizOptionId> {
    const quizQuestionState = this.#quizQuestionStateMap.get(questionId);
    return new Set(quizQuestionState?.selectedOptionIds ?? []);
  }

  public restoreQuestionId = (questionId: number): void => {
    this.#selectedQuestionId = questionId;
    this.emit("restoreQuestionId", {
      questionId,
    });
  };

  public selectQuestion = (questionId: number): void => {
    if (this.#selectedQuestionId === questionId) {
      return;
    }
    const previousQuestionId = this.#selectedQuestionId;
    this.#selectedQuestionId = questionId;
    this.emit("changeQuestionId", {
      questionId,
      previousQuestionId,
    });
  };

  public submitQuestion = (): void => {
    this.emit("submitQuestion", {
      questionId: this.#selectedQuestionId,
    });
  };

  public clearOptions = (questionId: number) => {
    if (!this.quizQuestionState) {
      return;
    }
    if (this.quizQuestionState.selectedOptionIds.size === 0) {
      return;
    }
    this.quizQuestionState.selectedOptionIds.clear();
    this.emit("clearOptions", {
      questionId: questionId,
    });
  };

  public unselectMultiOption = (questionId: QuizQuestionId, optionId: QuizOptionId) => {
    if (!this.quizQuestionState?.selectedOptionIds.has(optionId)) {
      return;
    }
    this.quizQuestionState.selectedOptionIds.delete(optionId);
    this.#quizQuestionStateMap.set(questionId, this.quizQuestionState);
    this.emit("unselectMultiOption", {
      questionId: questionId,
      optionId,
    });
    this.emit("changeMultiSelectedOptions", {
      optionIds: new Set(this.quizQuestionState.selectedOptionIds),
      questionId: questionId,
    });
  };

  public selectSingleOption = (questionId: number, optionId: QuizOptionId): void => {
    if (this.quizQuestionState?.selectedOptionIds.has(optionId)) {
      this.debugMessage("selectSingleOption", () => `State has already option (${optionId}).`);
      return;
    }
    const selectedOptionIds = new Set([optionId]);
    this.#quizQuestionStateMap.set(questionId, {
      questionId: questionId,
      selectedOptionIds: selectedOptionIds,
    });
    this.emit("selectSingleOption", {
      questionId: questionId,
      optionId,
    });
    this.emit("changeSingleSelectedOptions", {
      questionId: questionId,
      optionIds: new Set([optionId]),
    });
  };

  public restoreSingleOption = (questionId: number, optionId: QuizOptionId) => {
    this.#quizQuestionStateMap.set(questionId, {
      questionId: questionId,
      selectedOptionIds: new Set([optionId]),
    });
    this.emit("changeSingleSelectedOptions", {
      optionIds: new Set([optionId]),
      questionId: questionId,
    });
  };

  public restoreMultiChoiceOptions = (questionId: QuizQuestionId, options: Set<QuizOptionId>) => {
    this.#quizQuestionStateMap.set(questionId, {
      questionId: questionId,
      selectedOptionIds: options,
    });
    this.emit("changeMultiSelectedOptions", {
      optionIds: new Set(options),
      questionId: this.selectedQuestionId,
    });
  };

  public selectMultiOption = (questionId: QuizQuestionId, optionId: QuizOptionId) => {
    if (this.quizQuestionState) {
      if (this.quizQuestionState.selectedOptionIds.has(optionId)) {
        return;
      }
      if (optionId === SKIP_OPTION_ID) {
        this.quizQuestionState.selectedOptionIds.clear();
      } else {
        this.quizQuestionState.selectedOptionIds.delete(SKIP_OPTION_ID);
      }
      this.quizQuestionState.selectedOptionIds.add(optionId);
      this.#quizQuestionStateMap.set(questionId, this.quizQuestionState);
    } else {
      this.#quizQuestionStateMap.set(questionId, {
        questionId: questionId,
        selectedOptionIds: new Set([optionId]),
      });
    }
    this.emit("selectMultiOption", {
      questionId: questionId,
      optionId,
    });
    this.emit("changeMultiSelectedOptions", {
      optionIds: new Set(this.quizQuestionState?.selectedOptionIds ?? []),
      questionId: questionId,
    });
  };

  public comeOutQuizPackage = () => {
    this.emit("comeOutQuizPackage", {
      questionId: this.#selectedQuestionId,
    });
  };

  public dispose = () => {
    this.emit("dispose", {});
    this.#emitter.removeAllListeners();
  };

  public emitChangeConnectEvent = (payload: Listener["changeConnect"]["payload"]) => {
    this.emit("changeConnect", payload);
  };

  /** ============== Subscriber ============== */
  public on = <T extends EventName>(eventName: T, callback: Listener[T]["callback"]) => {
    this.#emitter.on(eventName, callback);
    return () => {
      this.#emitter.off(eventName, callback);
    };
  };

  private emit = <T extends EventName>(eventName: T, payload: Listener[T]["payload"]) => {
    if (this.#isDisposed) {
      return;
    }
    this.debugMessage(
      "emit",
      () =>
        `EventName:${eventName}, Payload:${JSON.stringify(payload)}, PackageId:${this.#selectedPackageId}, QuestionId:${
          this.#selectedQuestionId
        }`,
    );
    this.#emitter.emit(eventName, payload);
  };
}
