import { useTranslation } from "@hireroo/i18n";
import { ScoringPrecisionFeedbackForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import RadioControlField, { RadioControlFieldProps } from "../../../primitive/InputControl/RadioControlField/RadioControlField";

const NoticeTextTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: "12px",
  marginBottom: theme.spacing(3),
}));

const StyledRadioControlField = styled(RadioControlField)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  border: "1px solid",
  borderColor: theme.palette.divider,
  justifyContent: "space-between",
  borderRadius: "4px",
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  gap: theme.spacing(1.5),
  ".MuiFormControlLabel-root": {
    width: "33.33%",
    marginRight: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ":first-of-type": {
      borderRight: "1px solid",
      borderColor: theme.palette.divider,
    },
    ":last-of-type": {
      borderLeft: "1px solid",
      borderColor: theme.palette.divider,
    },
  },
  ".MuiTypography-root": {
    paddingTop: "9px",
    paddingBottom: "9px",
  },
}));

type Option = {
  id: ScoringPrecisionFeedbackForm.FeedbackAnswer;
  label: string;
  value: ScoringPrecisionFeedbackForm.FeedbackAnswer;
};

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type ScoringFeedbackDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose"> & { closeText?: string };
  onSubmit: (fields: ScoringPrecisionFeedbackForm.ScoringPrecisionFeedbackFormSchema, controller: DialogController) => void;
};

const ScoringFeedbackDialog: React.FC<ScoringFeedbackDialogProps> = props => {
  const { t } = useTranslation();
  const answerValidateSchema = ScoringPrecisionFeedbackForm.useFeedbackAnswer();
  const validateSchema = ScoringPrecisionFeedbackForm.useScoringPrecisionFeedbackFormSchema();
  const methods = useForm<ScoringPrecisionFeedbackForm.ScoringPrecisionFeedbackFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      answer: undefined,
      comment: undefined,
    },
  });
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    props.dialog.onClose?.();
    setLoading(false);
  };

  const dialogController: DialogController = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
    close: () => {
      handleClose();
    },
  };
  const canSubmit = methods.watch("answer") !== undefined;

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("フィードバック"),
    yesButton: {
      children: t("送信する"),
      onClick: () => {
        methods.handleSubmit(fields => {
          props.onSubmit(fields, dialogController);
        })();
      },
      disabled: loading || !canSubmit,
      color: "secondary",
      autoFocus: false,
    },
    yesButtonTooltip: !canSubmit ? { title: t("回答を選択してください") } : undefined,
    noButton: {
      children: props.dialog.closeText ?? t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
      disabled: loading,
      onClick: handleClose,
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
  };
  const titleTypographyProps: TypographyProps = {
    fontSize: "20px",
    fontWeight: 700,
    mt: 2,
    mb: 3,
    children: t("レポートのスコアは参考になりましたか？"),
  };

  const options: Option[] = [
    {
      id: "YES",
      label: t("はい"),
      value: "YES",
    },
    {
      id: "NO",
      label: t("いいえ"),
      value: "NO",
    },
    {
      id: "NOT_SURE",
      label: t("わからない"),
      value: "NOT_SURE",
    },
  ];

  const radioControlFieldProps: RadioControlFieldProps = {
    name: "ans",
    fullWidth: true,
    color: "secondary",
    size: "medium",
    options: options,
    onChange: value => {
      const answer = answerValidateSchema.safeParse(value);
      if (answer.success) {
        methods.setValue("answer", answer.data);
      }
    },
  };

  const textFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    multiline: true,
    rows: 7,
    type: "text",
    placeholder: t("理由を具体的に記述してください。"),
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      <FormProvider {...methods}>
        <Typography {...titleTypographyProps} />
        <StyledRadioControlField {...radioControlFieldProps} sx={{ width: "100%" }} />
        <NoticeTextTypography>
          {t("このフィードバックはスコアの精度向上に役立てるもので、候補者の評価とは無関係となります。")}
        </NoticeTextTypography>
        <InputControlTextField name="comment" {...textFieldProps} />
      </FormProvider>
    </DialogWithCloseIcon>
  );
};

ScoringFeedbackDialog.displayName = "ScoringFeedbackDialog";

export default ScoringFeedbackDialog;
