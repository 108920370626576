import { BrowserWindowEventDetect, useFirebaseSender } from "@hireroo/app-helper/hooks";
import { useSystemDesignRealtimeDatabase } from "@hireroo/app-helper/hooks";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { getTimestamp } from "@hireroo/firebase";
import { Widget } from "@hireroo/presentation";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { ShowingOption, useGenerateProps } from "./useGenerateProps";

export type Operator = {
  kind: "CANDIDATE" | "EMPLOYEE";
  displayName: string;
};

export type SystemDesignCodingEditorContainerProps = {
  entityId: number;
  uid: string;
  operator: Operator;
  enableBrowserEventDetector: boolean;
  interviewKind: "DEMO" | "INTERVIEW";
  showingOptions: ShowingOption[];
};

const SystemDesignCodingEditorContainer: React.FC<SystemDesignCodingEditorContainerProps> = props => {
  const hooks = SystemDesignCodingEditor.useCreateSystemDesignEntityHooks(props.entityId);
  const question = hooks.useQuestion();
  const status = hooks.useStatus();
  const ipAddress = hooks.useIpAddress();
  const geolocation = hooks.useGeolocation();

  const Store = useSystemDesignContext();
  const [collaborativeState, collaborativeAction] = useSystemDesignRealtimeDatabase({
    systemDesignId: props.entityId,
    isCandidate: props.operator.kind === "CANDIDATE",
    isInterviewing: props.interviewKind === "INTERVIEW",
    initialSnapshot: question?.initialSnapshot ?? "",
    userName: props.operator.displayName,
    selectedQuestionId: question?.questionId ?? 0,
    uid: props.uid,
  });
  const { pushEventHistory, pushAccessEvent, pushCopyEvent } = useFirebaseSender({
    uid: props.uid,
    appType: "systemDesign",
    systemDesignId: props.entityId,
    questionId: question?.questionId ?? 0,
  });

  const systemDesignCodingEditorProps = useGenerateProps({
    showingOptions: props.showingOptions,
    collaborativeState,
    collaborativeAction,
    entityId: props.entityId,
    onCopyQuestionContent: text => {
      pushCopyEvent({
        l: "question",
        v: text,
        t: getTimestamp(),
      });
    },
  });

  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
    }),
  );
  React.useEffect(() => {
    if (!props.enableBrowserEventDetector) {
      return;
    }
    if (status !== "STARTED") {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [status, props.enableBrowserEventDetector, ipAddress, geolocation, pushAccessEvent]);

  React.useEffect(() => {
    const shouldDetectEvents = props.enableBrowserEventDetector && status === "STARTED";

    if (!shouldDetectEvents) {
      return;
    }

    if (ipAddress !== null && geolocation !== null) {
      pushAccessEvent({
        l: ipAddress,
        g: geolocation,
        t: getTimestamp(),
      });
    }
  }, [ipAddress, geolocation, pushAccessEvent, props.enableBrowserEventDetector, status]);

  React.useEffect(() => {
    Store.action.updateComponentType(collaborativeState.selectedComponentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!collaborativeState.ready) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }
  return (
    <ErrorBoundary>
      <Widget.SystemDesignCodingEditor {...systemDesignCodingEditorProps} />
    </ErrorBoundary>
  );
};

SystemDesignCodingEditorContainer.displayName = "SystemDesignCodingEditorContainer";

export default withErrorBoundary(SystemDesignCodingEditorContainer, {});
