import { App } from "@hireroo/app-store/essential/candidate";
import * as CandidateEssentialStores from "@hireroo/app-store/essential/candidate";
import { Credential } from "@hireroo/app-store/essential/shared";
import { getCurrentUser, getExpireDate, getIdToken, signInAnonymously } from "@hireroo/firebase";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as Sentry from "@sentry/browser";
import { backOff } from "exponential-backoff";

import { initializeGraphqlClientSdkAndRestApiClient } from "./helper";

const setupAuthToken = async ({ refreshToken }: { refreshToken: boolean }) => {
  const authToken = (await getIdToken(refreshToken)) || null;
  if (!authToken) {
    throw new Error("Cannot get auth token");
  }
  const expireDate = await getExpireDate();
  Credential.setAuthToken({
    authToken,
    expireDate: expireDate || null,
    getRefreshAuthToken: async () => {
      const newAuthToken = await getIdToken(true);
      const expireDate = await getExpireDate();
      if (newAuthToken) {
        initializeGraphqlClientSdkAndRestApiClient(newAuthToken);
      }
      return {
        authToken: newAuthToken,
        expireDate,
      };
    },
  });
  initializeGraphqlClientSdkAndRestApiClient(authToken);
};

const createOrGetAnonymousUser = async (): Promise<void> => {
  const currentUser = await getCurrentUser();

  if (!currentUser) {
    const { user } = await signInAnonymously();
    if (user) {
      // Get the Token that is the target of UpdateClaims
      await setupAuthToken({ refreshToken: false });
      const client = getGraphqlClient();
      await client.UpdateClaimsForCandidate({
        input: {
          uid: user.uid,
          role: "CANDIDATE",
          userType: "CANDIDATE",
        },
      });

      /**
       * When updateUserType is executed immediately after creating a candidate user as an Anonymous user,
       * an error occurs if no record exists in the Auth database. To avoid this, polling is performed.
       */
      await backOff(
        async () => {
          return client.UpdateUserTypeForCandidate({
            updateUserTypeInput: {
              uid: user.uid,
              userType: "CANDIDATE",
            },
          });
        },
        {
          delayFirstAttempt: true,
          timeMultiple: 2,
          numOfAttempts: 15,
          maxDelay: 1000,
          startingDelay: 3000,
        },
      ).catch(error => {
        /**
         * If updateUserType should fail, do not throw an error so that the user can take the test.
         * However, in case of failure, a migration of the UserType is required.
         */
        Sentry.captureException(error);
      });

      Credential.setCurrentUserType(Graphql.UserType.Candidate);
      Credential.setCustomClaimInfo({
        signedIn: true,
        customClaim: {
          role: "CANDIDATE",
          user_type: "CANDIDATE" as const,
        },
      });
      CandidateEssentialStores.Auth.initializeUserFromFirebase({
        uid: user.uid,
      });
      // JWT Token is changed after updateClaim, so reacquire it.
      await setupAuthToken({ refreshToken: true });
    } else {
      CandidateEssentialStores.Auth.setError("INVALID_USER");
    }
    return;
  } else if (currentUser.isAnonymous) {
    // Get the Token that is the target of UpdateClaims
    await setupAuthToken({ refreshToken: false });
    const client = getGraphqlClient();
    await client.UpdateClaimsForCandidate({
      input: {
        role: "CANDIDATE",
        uid: currentUser.uid,
      },
    });
    Credential.setCurrentUserType(Graphql.UserType.Candidate);
    /**
     * When updateUserType is executed immediately after creating a candidate user as an Anonymous user,
     * an error occurs if no record exists in the Auth database. To avoid this, polling is performed.
     */
    await backOff(
      async () => {
        return client.UpdateUserTypeForCandidate({
          updateUserTypeInput: {
            uid: currentUser.uid,
            userType: "CANDIDATE",
          },
        });
      },
      {
        delayFirstAttempt: true,
        timeMultiple: 2,
        numOfAttempts: 15,
        maxDelay: 1000,
        startingDelay: 3000,
      },
    ).catch(error => {
      /**
       * If updateUserType should fail, do not throw an error so that the user can take the test.
       * However, in case of failure, a migration of the UserType is required.
       */
      Sentry.captureException(error);
    });

    // JWT Token is changed after updateClaim, so reacquire it.
    await setupAuthToken({ refreshToken: true });
    CandidateEssentialStores.Auth.initializeUserFromFirebase({
      uid: currentUser.uid,
    });
  } else {
    CandidateEssentialStores.Auth.setError("DIFFERENT_USER_TYPE");
  }
};

export type InitializeUnknownArgs = {};

const initialize = async (_args: InitializeUnknownArgs) => {
  App.setStatus("INITIALIZING");
  await createOrGetAnonymousUser();
  App.setStatus("INITIALIZED");
};

export const Candidate = {
  initialize,
};
