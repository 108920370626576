import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

export type GenerateFollowUpQuestionsSectionPropsArgs = {
  entityId: number;
};

export const useGenerateFollowUpQuestionsSectionProps = (
  args: GenerateFollowUpQuestionsSectionPropsArgs,
): Widget.SystemDesignTestReportProps["followUpQuestionsSection"] => {
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const currentSubmission = hooks.useCurrentSubmission();
  const lang = useLanguageCode();

  return {
    questions: (currentSubmission?.followUpQuestions ?? []).map(followUpQuestion => ({
      question: resolveLanguage(followUpQuestion, lang, "question"),
      answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
      purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
    })),
  };
};
