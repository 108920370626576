import { useTranslation } from "@hireroo/i18n";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntityFollowUpQuestionsSection, {
  EntityFollowUpQuestionsSectionProps,
} from "../../modules/EntityFollowUpQuestionsSection/EntityFollowUpQuestionsSection";
import EntityScoreBoard, { EntityScoreBoardProps } from "../../modules/EntityScoreBoard/EntityScoreBoard";
import ReportCheatDetectionSection, {
  ReportCheatDetectionSectionProps,
} from "../../modules/ReportCheatDetectionSection/ReportCheatDetectionSection";
import TestReportSectionHeader, { TestReportSectionHeaderProps } from "../../modules/TestReportSectionHeader/TestReportSectionHeader";
import ChallengeTestCaseAccordion, {
  ChallengeTestCaseAccordionProps,
} from "../../ms-components/Challenge/ChallengeTestCaseAccordion/ChallengeTestCaseAccordion";
import ReportChallengeAnswerDetailSection, {
  ReportChallengeAnswerDetailSectionProps,
} from "../../ms-components/Challenge/ReportChallengeAnswerDetailSection/ReportChallengeAnswerDetailSection";
import ReportChallengePerformanceSection, {
  ReportChallengePerformanceSectionProps,
} from "../../ms-components/Challenge/ReportChallengePerformanceSection/ReportChallengePerformanceSection";
import ReportChallengeReadabilitySection, {
  ReportChallengeReadabilitySectionProps,
} from "../../ms-components/Challenge/ReportChallengeReadabilitySection/ReportChallengeReadabilitySection";
import AnswerSection, { AnswerSectionProps } from "../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionDetailDrawer, { QuestionDetailDrawerProps } from "../../ms-components/Question/QuestionDetailDrawer/QuestionDetailDrawer";
import { FeedbackSuspiciousDegreeDialog, FeedbackSuspiciousDegreeDialogProps } from "../../ms-components/Suspicious";

const EntityReportWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  padding: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

type Answer = Pick<AnswerSectionProps, "title" | "description"> & {
  name: string;
};

export type ChallengeTestReportProps = {
  header: Omit<TestReportSectionHeaderProps, "detailButton">;
  questionDetail: Omit<QuestionDetailDrawerProps, "open" | "onClose" | "variantText" | "answerContents"> & {
    answers: Answer[];
  };
  entityScoreBoard?: Omit<EntityScoreBoardProps, "title">;
  StatisticsContent?: React.ReactNode;
  answerDetailSection?: ReportChallengeAnswerDetailSectionProps;
  cheatDetectionSection?: ReportCheatDetectionSectionProps;
  testCaseSection?: ChallengeTestCaseAccordionProps;
  performanceSection?: ReportChallengePerformanceSectionProps;
  readabilitySection?: ReportChallengeReadabilitySectionProps;
  PlaybackSection?: React.ReactNode;
  announcement?: string;
  SuspiciousDegreeMessageForQuestion?: React.ReactNode;
  feedbackSuspiciousDegreeDialog?: FeedbackSuspiciousDegreeDialogProps;
  followUpQuestionsSection?: EntityFollowUpQuestionsSectionProps;
};

const ChallengeTestReport: React.FC<ChallengeTestReportProps> = props => {
  const { t } = useTranslation();
  const [detailOpen, setDetailOpen] = React.useState(false);

  const header: TestReportSectionHeaderProps = {
    ...props.header,
    detailButton: {
      onClick: () => {
        setDetailOpen(true);
      },
    },
  };

  const { answers, ...questionDetail } = props.questionDetail;

  const answerContents = answers.map((content): QuestionDetailDrawerProps["answerContents"][0] => ({
    id: `answer${content.name}`,
    Component: (
      <Box p={3}>
        <AnswerSection
          title={content.title}
          description={content.description}
          TopComponent={
            props.questionDetail.openButton && (
              <Box mb={2}>
                <Button
                  href={props.questionDetail.openButton.href}
                  rel="noreferrer"
                  target="_blank"
                  component="a"
                  color="secondary"
                  variant="outlined"
                  endIcon={<OpenInNewOutlined />}
                >
                  {t("詳細はこちら")}
                </Button>
              </Box>
            )
          }
        />
      </Box>
    ),
    label: {
      name: content.name,
    },
  }));

  const questionDrawer: QuestionDetailDrawerProps = {
    ...questionDetail,
    answerContents: answerContents,
    open: detailOpen,
    onClose: () => {
      setDetailOpen(false);
    },
    variantText: t("コーディング形式"),
  };

  const entityScoreBoard: EntityScoreBoardProps | undefined = props.entityScoreBoard && {
    ...props.entityScoreBoard,
    title: t("コーディング形式"),
  };

  return (
    <Box>
      <TestReportSectionHeader {...header} />
      <EntityReportWrapper mt={2}>
        {props.SuspiciousDegreeMessageForQuestion && <Box mb={2.5}>{props.SuspiciousDegreeMessageForQuestion}</Box>}
        {props.feedbackSuspiciousDegreeDialog && <FeedbackSuspiciousDegreeDialog {...props.feedbackSuspiciousDegreeDialog} />}
        {entityScoreBoard && <EntityScoreBoard {...entityScoreBoard} />}
        {props.announcement && <Typography>{props.announcement}</Typography>}
        {!props.announcement && (
          <Stack spacing={5} direction="column" my={5}>
            {props.StatisticsContent}
            {props.answerDetailSection && <ReportChallengeAnswerDetailSection {...props.answerDetailSection} />}
            {/* cheat detection */}
            {props.cheatDetectionSection && <ReportCheatDetectionSection {...props.cheatDetectionSection} />}
            {/* testcase */}
            {props.testCaseSection && <ChallengeTestCaseAccordion {...props.testCaseSection} />}
            {/* performance */}
            {props.performanceSection && <ReportChallengePerformanceSection {...props.performanceSection} />}
            {/* readability */}
            {props.readabilitySection && <ReportChallengeReadabilitySection {...props.readabilitySection} />}
            {props.PlaybackSection}
            {props.followUpQuestionsSection && <EntityFollowUpQuestionsSection {...props.followUpQuestionsSection} />}
          </Stack>
        )}
      </EntityReportWrapper>
      <QuestionDetailDrawer {...questionDrawer} />
    </Box>
  );
};

ChallengeTestReport.displayName = "ChallengeTestReport";

export default ChallengeTestReport;
