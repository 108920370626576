import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

export type QuestionSearchDialogProps = Pick<DialogWithCloseIconProps, "open" | "onClose"> & {
  SearchArea: React.ReactNode;
  Table: React.ReactNode;
};

const QuestionSearchDialog: React.FC<QuestionSearchDialogProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { SearchArea, Table, ...rest } = props;

  const dialog: DialogWithCloseIconProps & { children: React.ReactNode } = {
    ...rest,
    title: t("新しいセッションを作成する"),
    children: (
      <Box>
        <Stack direction="column">
          <Stack direction="column" mb={2}>
            {SearchArea}
          </Stack>
          {Table}
        </Stack>
      </Box>
    ),
    optionalDialog: {
      fullWidth: true,
      maxWidth: "md",
      PaperProps: {
        sx: {
          height: "80%",
          width: "75vw",
          minWidth: "75vw",
          bgcolor: theme.palette.background.paper,
        },
      },
    },
  };
  return <DialogWithCloseIcon {...dialog} />;
};

QuestionSearchDialog.displayName = "QuestionSearchDialog";

export default QuestionSearchDialog;
