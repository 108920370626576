import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useQuestionStatusMap = (): Record<Graphql.QuestionStatus, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.QuestionStatus.Published]: t("公開済み"),
    [Graphql.QuestionStatus.Draft]: t("下書き"),
    [Graphql.QuestionStatus.Reviewing]: t("審査中"),
    [Graphql.QuestionStatus.Rejected]: t("差し戻し"),
    [Graphql.QuestionStatus.Archived]: t("削除済み"),
    [Graphql.QuestionStatus.Unknown]: t("不明"),
  };
};

export const useQuestionDifficultyMap = (): Record<Graphql.Difficulty, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.Difficulty.Easy]: t("易しい"),
    [Graphql.Difficulty.Medium]: t("ふつう"),
    [Graphql.Difficulty.Difficult]: t("難しい"),
    [Graphql.Difficulty.Unknown]: t("不明"),
  };
};

type DifficultyType = "EASY" | "MEDIUM" | "DIFFICULT";

export const useQuestionDifficultyTypeMap = (): Record<Graphql.Difficulty, DifficultyType> => {
  return {
    [Graphql.Difficulty.Easy]: "EASY",
    [Graphql.Difficulty.Medium]: "MEDIUM",
    [Graphql.Difficulty.Difficult]: "DIFFICULT",
    [Graphql.Difficulty.Unknown]: "EASY",
  };
};

export const useQuestionVariantMap = (): Record<Graphql.QuestionVariant, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.QuestionVariant.Challenge]: t("コーディング形式"),
    [Graphql.QuestionVariant.Project]: t("実践形式"),
    [Graphql.QuestionVariant.Quiz]: t("クイズ形式"),
    [Graphql.QuestionVariant.SystemDesign]: t("システムデザイン"),
    [Graphql.QuestionVariant.ChallengeAlgorithm]: t("コーディング形式：アルゴリズム"),
    [Graphql.QuestionVariant.ChallengeDatabase]: t("コーディング形式：データベース"),
    [Graphql.QuestionVariant.ChallengeClass]: t("コーディング形式：クラス"),
    [Graphql.QuestionVariant.ProjectFrontend]: t("実践形式：フロントエンド"),
    [Graphql.QuestionVariant.ProjectBackend]: t("実践形式：バックエンド"),
    [Graphql.QuestionVariant.ProjectDataScience]: t("実践形式：データサイエンス"),
    [Graphql.QuestionVariant.ProjectOther]: t("実践形式：他"),
  };
};
