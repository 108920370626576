import { hasSpotStatus } from "@hireroo/app-helper/interview";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateTimelinePropsArgs = {};

export const useGenerateTimelineProps = (_args: GenerateTimelinePropsArgs): Widget.ScreeningTestOverviewProps["timeline"] => {
  const { t } = useTranslation();
  const interview = InterviewOverview.useInterview();
  const isDeclinedOrExpired = interview.status === Graphql.SpotStatus.Declined || interview.status === Graphql.SpotStatus.Expired;
  const isStarted = hasSpotStatus(interview.status, [
    Graphql.SpotStatus.Started,
    Graphql.SpotStatus.Completed,
    Graphql.SpotStatus.Finalized,
    Graphql.SpotStatus.Reviewed,
    Graphql.SpotStatus.Evaluated,
  ]);
  const isEnded = hasSpotStatus(interview.status, [
    Graphql.SpotStatus.Completed,
    Graphql.SpotStatus.Finalized,
    Graphql.SpotStatus.Reviewed,
    Graphql.SpotStatus.Evaluated,
  ]);
  const isFinalized = hasSpotStatus(interview.status, [Graphql.SpotStatus.Finalized, Graphql.SpotStatus.Evaluated]);
  const isEvaluated = interview.status === Graphql.SpotStatus.Evaluated;
  const startedOrDeclinedTime = React.useMemo(() => {
    if (isStarted) {
      return interview.didStartAtSeconds ? TimeFormatter.unixToDateFormat(interview.didStartAtSeconds) : undefined;
    } else if (isDeclinedOrExpired) {
      return interview.willExpireAtSeconds ? TimeFormatter.unixToDateFormat(interview.willExpireAtSeconds) : undefined;
    }
  }, [interview.willExpireAtSeconds, interview.didStartAtSeconds, isDeclinedOrExpired, isStarted]);

  return {
    items: [
      {
        title: t("テスト作成"),
        status: "DONE",
        dateTime: interview.createdAtSeconds ? TimeFormatter.unixToDateFormat(interview.createdAtSeconds) : undefined,
      },
      {
        title: isDeclinedOrExpired ? t("期限切れ") : t("テスト開始"),
        status: React.useMemo(() => {
          if (isDeclinedOrExpired) {
            return "DECLINED";
          }
          if (!isStarted) {
            return "DOING";
          } else {
            return "DONE";
          }
        }, [isStarted, isDeclinedOrExpired]),
        dateTime: startedOrDeclinedTime,
      },
      {
        title: t("テスト終了"),
        status: isEnded ? "DONE" : "DOING",
        dateTime: isEnded && interview.didEndAtSeconds ? TimeFormatter.unixToDateFormat(interview.didEndAtSeconds) : undefined,
      },
      {
        title: t("採点終了"),
        status: isFinalized ? "DONE" : "DOING",
        dateTime: isFinalized && interview.finalizedAtSeconds ? TimeFormatter.unixToDateFormat(interview.finalizedAtSeconds) : undefined,
      },
      {
        title: t("テスト評価"),
        status: isEvaluated ? "DONE" : "DOING",
        dateTime: isEvaluated && interview.evaluatedAtSeconds ? TimeFormatter.unixToDateFormat(interview.evaluatedAtSeconds) : undefined,
      },
    ],
  };
};
