import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { getRef, getTimestamp } from "@hireroo/firebase";
import { PlaybackEvent } from "@hireroo/validator";
import { EventEmitter } from "events";
import type firebase from "firebase/compat/app";

import { revisionFromId, revisionToId } from "../firepad";
import type * as Types from "./ClientType";

export interface Listener {
  selectOption: {
    payload: { questionId: number; optionId: number; action: Extract<PlaybackEvent.QuizPackageQuestionState, { s: "selo" }>["a"] };
    callback: (payload: Listener["selectOption"]["payload"]) => void;
  };
  quizPackageQuestionStateObject: {
    payload: { data: PlaybackEvent.QuizPackageQuestionStateObject };
    callback: (payload: Listener["quizPackageQuestionStateObject"]["payload"]) => void;
  };
  skippedChildAdded: {
    payload: { revisionId: string; data: unknown };
    callback: (payload: Listener["skippedChildAdded"]["payload"]) => void;
  };
}

type EventName = keyof Listener;

export type CreateReferenceArgs = {
  quizId: number;
  quizPackageId: number;
  questionId: number;
};

export type AppendParams = {
  quizId: number;
  quizPackageId: number;
  questionId: number;
  event: PlaybackEvent.QuizPackageQuestionState;
};

export type InitializeConfig = {
  debug?: boolean;
};

/**
 * Firebase Realtime Database Path: quizzes/${quizId}/packages/${quizPackageId}/questions/${questionId}/state
 */
export class QuizPackageQuestionStateClient implements Types.BaseStateClient {
  #emitter = new EventEmitter();
  #isDisposed = false;
  #isConnected = false;
  #disposeClientListener: () => void = () => undefined;

  #restoredIndex = NaN;
  #queueAppendTasks: Map<number, { run: () => void; event: PlaybackEvent.QuizPackageQuestionState; count: number }> = new Map();
  #queueCounter = 0;
  #previousAppendEvent: PlaybackEvent.QuizPackageQuestionState | undefined;

  #debug = false;

  constructor(config: InitializeConfig) {
    this.#debug = config.debug ?? false;
  }

  private static createReference = (config: CreateReferenceArgs) => {
    return getRef("quiz", `quizzes/${config.quizId}/packages/${config.quizPackageId}/questions/${config.questionId}/state`);
  };

  private debugMessage = (methodName: string, message: () => string) => {
    if (this.#debug) {
      console.log(["%c[DEBUG]", `QuizPackageQuestionStateClient#${methodName}`, message()].join(" "), "color: #f3c100;");
    }
  };

  public reconnect = async (config: CreateReferenceArgs) => {
    this.#restoredIndex = NaN;
    this.#previousAppendEvent = undefined;
    this.#disposeClientListener();
    this.#isConnected = false;
    await this.connect(config);
  };

  public connect = async (config: CreateReferenceArgs): Promise<void> => {
    if (this.#isConnected) {
      return;
    }
    if (this.#isDisposed) {
      console.warn(`QuizPackageQuestionStateClient was disposed in connect sequence`);
      return;
    }
    const client = QuizPackageQuestionStateClient.createReference(config);
    this.#isConnected = true;
    await client.once("value", this.handleOnceValue);
    client.on("child_added", this.handleChildAdded);

    this.#disposeClientListener = () => {
      client.off("value", this.handleOnceValue);
      client.off("child_added", this.handleChildAdded);
    };
  };

  public dispose = () => {
    if (this.#isDisposed) {
      return;
    }
    this.debugMessage("dispose", () => {
      return `Remain Queue Length: ${this.#queueAppendTasks.size}`;
    });
    this.#emitter.removeAllListeners();
    this.#disposeClientListener();
    this.#isDisposed = true;
  };

  private getFirstTask = () => {
    const firstTs = Array.from(this.#queueAppendTasks.keys())
      .sort((a, b) => a - b)
      .at(0);
    if (firstTs) {
      return this.#queueAppendTasks.get(firstTs);
    }
  };

  private append = async (params: AppendParams, count = this.#queueCounter + 1): Promise<void> => {
    const { quizId, questionId, quizPackageId, event } = params;
    if (this.#isDisposed) {
      this.debugMessage("append", () => `This client has already disposed!`);
      return;
    }

    /**
     * Skip to prevent double counting.
     *
     * Although double posting by user operation is not allowed in the specification, the possibility of multiple execution of the API cannot be denied, so it is prevented here.
     */
    if (this.#previousAppendEvent?.s === event.s && this.#previousAppendEvent.v === event.v) {
      this.debugMessage("append", () => `The same Event was appended last time. ${JSON.stringify(event)}`);
      return;
    }
    this.#previousAppendEvent = event;

    if (this.#queueCounter < count) {
      this.#queueCounter = count;
    }
    const result = PlaybackEvent.QuizPackageQuestionState.safeParse(event);
    if (!result.success) {
      console.warn({
        event,
        error: result.error,
      });
      return;
    }
    /**
     * Register a timestamp of when the append function was executed and sort the order of execution.
     */
    this.#queueAppendTasks.set(count, {
      count,
      event,
      run: () => {
        this.debugMessage("append/run", () => JSON.stringify({ count, event }));
        this.append(
          {
            ...params,
            event: result.data,
          },
          count,
        );
      },
    });

    this.debugMessage(
      "append",
      () => `Queue Length: ${this.#queueAppendTasks.size}, values=${JSON.stringify([...this.#queueAppendTasks.values()])}`,
    );
    const client = QuizPackageQuestionStateClient.createReference({
      quizId,
      quizPackageId: quizPackageId,
      questionId,
    });

    /**
     * Ensure that the information held by the DB is verified before writing to ensure that the index is incremented.
     */
    const nextIndex = (await QuizPackageQuestionStateClient.fetchLatestIndex(client)) + 1;
    if (nextIndex === this.#restoredIndex) {
      this.debugMessage("append", () => "Next Index has already restored.");
      return;
    }
    const nextTask = this.getFirstTask();
    if (!nextTask) {
      this.debugMessage("append", () => `Not found Next Task. Queue Length: ${this.#queueAppendTasks.size}`);
      return;
    }
    this.#restoredIndex = nextIndex;
    this.#queueAppendTasks.delete(nextTask.count);
    const revisionId = revisionToId(nextIndex);

    this.debugMessage("append", () => `${JSON.stringify(nextTask.event)} ${nextIndex} - ${revisionId} | ${client.toString()}`);
    await client.child(revisionId).set(nextTask.event);
  };

  public on = <T extends EventName>(eventName: T, callback: Listener[T]["callback"]) => {
    this.#emitter.on(eventName, callback);
    return () => {
      this.#emitter.off(eventName, callback);
    };
  };

  private emit = <T extends EventName>(eventName: T, payload: Listener[T]["payload"]) => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.emit(eventName, payload);
  };

  static generateNormalizeEventBySelectOption = (data: PlaybackEvent.QuizPackageQuestionStateObject) => {
    const finalActions: { type: "SINGLE" | "MULTI"; options: Set<number>; questionId: number } = {
      type: "SINGLE",
      options: new Set<number>(),
      questionId: 0,
    };
    Object.entries(data).forEach(([_, value]) => {
      if (value.s === "selo") {
        const optionId = value.v;
        finalActions.questionId = value.qid;
        switch (value.a) {
          case "rep": {
            finalActions.type = "SINGLE";
            finalActions.options.clear();
            finalActions.options.add(optionId);
            break;
          }
          case "set": {
            finalActions.type = "MULTI";
            if (optionId === SKIP_OPTION_ID) {
              finalActions.options = new Set([SKIP_OPTION_ID]);
            } else {
              finalActions.options.delete(SKIP_OPTION_ID);
              finalActions.options.add(optionId);
            }
            break;
          }
          case "uset": {
            finalActions.type = "MULTI";
            finalActions.options.delete(optionId);
            break;
          }
          default: {
            finalActions.type = "SINGLE";
            finalActions.options.clear();
            finalActions.options.add(optionId);
          }
        }
      }
    });

    return finalActions;
  };

  private handleChildAdded = async (snapshot: firebase.database.DataSnapshot) => {
    if (this.#isDisposed) {
      return;
    }
    const data = snapshot.val();
    if (!data || !snapshot.key) {
      return;
    }
    const hasAlreadyBeenRestored = revisionFromId(snapshot.key) <= this.#restoredIndex;
    if (hasAlreadyBeenRestored) {
      this.emit("skippedChildAdded", {
        revisionId: snapshot.key,
        data,
      });

      const task = this.getFirstTask();
      task?.run();
      return;
    }
    const result = PlaybackEvent.QuizPackageQuestionState.safeParse(data);

    if (result.success) {
      switch (result.data.s) {
        case "selo": {
          // result.data.v と現在のquestionIdの確認
          this.emit("selectOption", {
            optionId: result.data.v,
            action: result.data.a,
            questionId: result.data.v,
          });
          break;
        }
        case "subq": {
          /** No Action  */
          break;
        }
        default:
          throw new Error(`Invalid value: ${result.data satisfies never}`);
      }
    } else {
      console.warn({
        raw: data,
        error: result.error,
      });
    }
    /**
     * Get a task from the beginning of the queued task and execute it.
     */
    const task = this.getFirstTask();
    task?.run();
  };

  private static fetchLatestIndex = (client: firebase.database.Reference): Promise<number> => {
    return new Promise(resolve => {
      client.once("value", snapshot => {
        const data = snapshot.val();
        if (!data) {
          return resolve(0);
        }
        const result = PlaybackEvent.QuizPackageQuestionStateObject.safeParse(data);
        if (!result.success) {
          return resolve(0);
        }
        const lastKey = Object.keys(result.data).at(-1);
        if (lastKey) {
          return resolve(revisionFromId(lastKey));
        }
        return resolve(0);
      });
    });
  };

  private handleOnceValue = (snapshot: firebase.database.DataSnapshot) => {
    const data = snapshot.val();
    if (!data) {
      this.debugMessage("handleOnceValue", () => {
        return [`NO DATA (ref: ${snapshot.ref.toString()})`].join("\n");
      });
      return;
    }
    this.debugMessage("handleOnceValue", () =>
      JSON.stringify({
        data,
      }),
    );
    const result = PlaybackEvent.QuizPackageQuestionStateObject.safeParse(data);
    if (result.success) {
      const keys = Object.keys(result.data).sort();
      const lastKey = keys.at(-1);
      if (lastKey) {
        this.#restoredIndex = revisionFromId(lastKey);
        this.debugMessage("handleOnceValue", () => `this.#restoredIndex=${this.#restoredIndex}`);
      }

      this.emit("quizPackageQuestionStateObject", {
        data: result.data,
      });
    } else {
      console.warn({
        raw: data,
        error: result.error,
      });
    }
  };

  // ===== Friendly interface =====

  public unselectOption = (params: Omit<AppendParams, "event">, optionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "selo",
        a: "uset",
        v: optionId,
        qid: params.questionId,
        t: getTimestamp(),
      },
    });
  };
  public selectMultiOption = (params: Omit<AppendParams, "event">, optionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "selo",
        a: "set",
        v: optionId,
        qid: params.questionId,
        t: getTimestamp(),
      },
    });
  };

  public selectSingleOption = (params: Omit<AppendParams, "event">, optionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "selo",
        a: "rep",
        v: optionId,
        qid: params.questionId,
        t: getTimestamp(),
      },
    });
  };

  public submitQuestion = (params: Omit<AppendParams, "event">, questionId: number): Promise<void> => {
    return this.append({
      ...params,
      event: {
        s: "subq",
        v: questionId,
        t: getTimestamp(),
      },
    });
  };
}
