import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeReadonlyCodeEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeReadonlyCodeEditorContainerProps = GenerateChallengeReadonlyCodeEditorPropsArgs;

const ChallengeReadonlyCodeEditorContainer: React.FC<ChallengeReadonlyCodeEditorContainerProps> = props => {
  const challengeReadonlyCodeEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeReadonlyCodeEditor {...challengeReadonlyCodeEditorProps} />
    </ErrorBoundary>
  );
};

ChallengeReadonlyCodeEditorContainer.displayName = "ChallengeReadonlyCodeEditorContainer";

export default withErrorBoundary(ChallengeReadonlyCodeEditorContainer, {});
