//
// This Validator should aggregate Schema definitions for events used in Playback.
//
// [IMPORTANT]
// Note that Breaking Change may make it impossible to read historical data, as it is directly related to the data stored in Firebase.
//

import * as z from "zod";

import * as BrowserEvent from "./BrowserEvent";

/**
 * Firebaseからtimestampの情報を読み取るとき、numberの型で返ってくる。
 * 書き込み時に利用する型定義と、読込み時に利用する型定義が異なるためReadとWrite（後述）で分離されている。
 */
const TimeStampForRead = z.number();
/**
 * FirebaseにTimeStampを書き込むとき、firebase.database.ServerValue.TIMESTAMP を利用するが、
 * この値の実体は`{ .sv: "timestamp" }`であるが、firebaseのライブラリとのやり取りの中でのみ使うため、
 * アプリケーションはこの型定義の詳細まで知る必要はないため、anyを利用する。
 */
const TimeStampForWrite = z.any();

const Key = z.string().optional();

export const SyncOperation = z.object({
  a: z.string(),
  o: z.union([z.string(), z.number()]).array(),
  t: TimeStampForRead,
  /**
   * Key for paste Detection
   */
  k: Key,
});

export type SyncOperation = z.infer<typeof SyncOperation>;

export const SelectLanguageAction = z.object({
  s: z.literal("sell"),
  /** language */
  v: z.string(),
  t: TimeStampForRead,
  k: Key,
});

export type SelectLanguageAction = z.infer<typeof SelectLanguageAction>;

export const UseHintAction = z.object({
  s: z.literal("useh"),
  t: TimeStampForRead,
  /** Hint Number */
  v: z.number(),
  k: Key,
});

export type UseHintAction = z.infer<typeof UseHintAction>;

export const SubmitQuestion = z.object({
  s: z.literal("subq"),
  t: z.union([TimeStampForRead, TimeStampForWrite]),
  /** Question Number */
  v: z.number(),
  k: Key,
});

export type SubmitQuestion = z.infer<typeof SubmitQuestion>;

export const RunCode = z.object({
  s: z.literal("runc"),
  /** Snapshot Id */
  v: z.number(),
  t: TimeStampForRead,
  k: Key,
});

export type RunCode = z.infer<typeof RunCode>;

export const LanguageState = z.union([RunCode, SubmitQuestion]);

export type LanguageState = z.infer<typeof LanguageState>;

export const LanguageStateObject = z.record(LanguageState);

export type LanguageStateObject = z.infer<typeof LanguageStateObject>;

const CutCopyEventValue = z.object({ text: z.string(), position: z.number(), selectionEnd: z.number() }).array();

export const CopyEvent = z.object({
  s: z.literal("copy"),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: CutCopyEventValue,
  t: TimeStampForRead,
  k: Key,
});

export type CopyEvent = z.infer<typeof CopyEvent>;

export const CutEvent = z.object({
  s: z.literal("cut"),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: CutCopyEventValue,
  t: TimeStampForRead,
  k: Key,
});

export type CutEvent = z.infer<typeof CutEvent>;

const PasteEventValue = z.array(z.union([z.string(), z.number()]));

export const PasteEvent = z.object({
  s: z.enum(["paste"]),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: PasteEventValue,
  t: TimeStampForRead,
  k: Key,
});

export type PasteEvent = z.infer<typeof PasteEvent>;

export const QuestionEvent = z.union([
  BrowserEvent.AccessEventPayload,
  BrowserEvent.BlurEventPayload,
  BrowserEvent.FocusEventPayload,
  BrowserEvent.VisibleEventPayload,
  BrowserEvent.HiddenEventPayload,
]);

export type QuestionEvent = z.infer<typeof QuestionEvent>;

export const QuestionEventObject = z.record(QuestionEvent);

export type QuestionEventObject = z.infer<typeof QuestionEventObject>;

export const LanguageEvent = z.union([CopyEvent, CutEvent, PasteEvent]);

export type LanguageEvent = z.infer<typeof LanguageEvent>;

export const LanguageEventObject = z.record(LanguageEvent);

export type LanguageEventObject = z.infer<typeof LanguageEventObject>;

export const ChallengeQuestionState = z.union([SelectLanguageAction, UseHintAction]);

export type ChallengeQuestionState = z.infer<typeof ChallengeQuestionState>;

export const ChallengeQuestionStateObject = z.record(ChallengeQuestionState);

export type ChallengeQuestionStateObject = z.infer<typeof ChallengeQuestionStateObject>;

export const Revision = z.union([SyncOperation, ChallengeQuestionState]);

export type Revision = z.infer<typeof Revision>;

export const RevisionObject = z.record(Revision);

export type RevisionObject = z.infer<typeof RevisionObject>;

export const SelectQuizPackageEvent = z.object({
  s: z.literal("selp"),
  /** package version */
  v: z.number(),
  t: TimeStampForWrite,
});

export type SelectQuizPackageEvent = z.infer<typeof SelectQuizPackageEvent>;

export const SelectQuizQuestionEvent = z.object({
  s: z.literal("selq"),
  /** Quiz Question Id */
  v: z.number(),
  t: TimeStampForWrite,
});

export type SelectQuizQuestionEvent = z.infer<typeof SelectQuizQuestionEvent>;

export const QuizQuestionGetIntoQuizPackageEvent = z.object({
  /** inq = in question */
  s: z.literal("inq"),
  /** Quiz Question Id */
  v: z.number(),
  t: TimeStampForWrite,
});

export type QuizQuestionGetIntoQuizPackageEvent = z.infer<typeof QuizQuestionGetIntoQuizPackageEvent>;

export const QuizQuestionComeOutQuizPackageEvent = z.object({
  /** outq = out question */
  s: z.literal("outq"),
  /** Quiz Question Id */
  v: z.number(),
  t: TimeStampForWrite,
});

export type QuizQuestionComeOutQuizPackageEvent = z.infer<typeof QuizQuestionComeOutQuizPackageEvent>;

export const QuizQuestionSelectSingleOptionEvent = z.object({
  /** selo = Select Option */
  s: z.literal("selo"),
  /** rep = replace */
  a: z.literal("rep"),
  /** Option ID */
  v: z.number(),
  /**
   * EventのDataにquizのquestionIdを載せることにより、
   * questionIdとoptionIdの紐づけがロストしないようにする
   *
   * メンテナンスする人への注意: qidは2025/01に追加されたrequiredのフィールドです。
   * それ以前のデータにはqidは存在しないため、Playbackのためにこのschemaを利用してsafeParseする場合は
   * 別途、optionalのschemaを用意して取り扱うと後方互換性を保つことができます。
   */
  qid: z.number(),
  t: TimeStampForWrite,
});

export type QuizQuestionSelectSingleOptionEvent = z.infer<typeof QuizQuestionSelectSingleOptionEvent>;

export const QuizQuestionSelectMultiOptionEvent = z.object({
  /** selo = Select Option */
  s: z.literal("selo"),
  a: z.literal("set"),
  /** Option ID */
  v: z.number(),
  /**
   * EventのDataにquizのquestionIdを載せることにより、
   * questionIdとoptionIdの紐づけがロストしないようにする
   *
   * メンテナンスする人への注意: qidは2025/01に追加されたrequiredのフィールドです。
   * それ以前のデータにはqidは存在しないため、Playbackのためにこのschemaを利用してsafeParseする場合は
   * 別途、optionalのschemaを用意して取り扱うと後方互換性を保つことができます。
   */
  qid: z.number(),
  t: TimeStampForWrite,
});

export type QuizQuestionSelectMultiOptionEvent = z.infer<typeof QuizQuestionSelectMultiOptionEvent>;

export const QuizQuestionUnSelectOptionEvent = z.object({
  /** selo = Select Option */
  s: z.literal("selo"),
  a: z.literal("uset"),
  /** Option ID */
  v: z.number(),
  /**
   * EventのDataにquizのquestionIdを載せることにより、
   * questionIdとoptionIdの紐づけがロストしないようにする
   *
   * メンテナンスする人への注意: qidは2025/01に追加されたrequiredのフィールドです。
   * それ以前のデータにはqidは存在しないため、Playbackのためにこのschemaを利用してsafeParseする場合は
   * 別途、optionalのschemaを用意して取り扱うと後方互換性を保つことができます。
   */
  qid: z.number(),
  t: TimeStampForWrite,
});

export type QuizQuestionUnSelectOptionEvent = z.infer<typeof QuizQuestionUnSelectOptionEvent>;

/**
 * quizzes/{quizId}/state
 */
export const QuizState = SelectQuizPackageEvent;

export type QuizState = z.infer<typeof QuizState>;

export const QuizStateObject = z.record(QuizState);

export type QuizStateObject = z.infer<typeof QuizStateObject>;

/**
 * quizzes/{quizId}/packages/{packageId}/state
 */
export const QuizPackageState = z.union([
  /** selq */
  SelectQuizQuestionEvent,
  /** inq */
  QuizQuestionGetIntoQuizPackageEvent,
  /** outq */
  QuizQuestionComeOutQuizPackageEvent,
]);

export type QuizPackageState = z.infer<typeof QuizPackageState>;

export const QuizPackageStateObject = z.record(QuizPackageState);

export type QuizPackageStateObject = z.infer<typeof QuizPackageStateObject>;

/**
 * quizzes/{quizId}/packages/{packageId}/questions/{questionId}/state
 */
export const QuizPackageQuestionState = z.union([
  /** selo, uset */
  QuizQuestionUnSelectOptionEvent,
  /** selo, set */
  QuizQuestionSelectMultiOptionEvent,
  /** selo, rep */
  QuizQuestionSelectSingleOptionEvent,
  /** subq */
  SubmitQuestion,
]);

export type QuizPackageQuestionState = z.infer<typeof QuizPackageQuestionState>;

/**
 * Record<revisionId, QuizPackageQuestionState>
 */
export const QuizPackageQuestionStateObject = z.record(QuizPackageQuestionState);

export type QuizPackageQuestionStateObject = z.infer<typeof QuizPackageQuestionStateObject>;

/**
 * Record<questionId, {
 *   questions: any,
 *   state: QuizPackageQuestionStateObject
 * }>
 */
export const QuizPackageQuestionsObject = z.record(
  z.object({
    /**
     * Definition required to avoid zod validation errors.
     * While it is possible to describe the details, the details are not needed where they are actually needed,
     * so `any` is used to make the zod validation logic the lightest.
     */
    questions: z.any(),
    state: QuizPackageQuestionStateObject.optional(),
  }),
);

export type QuizPackageQuestionsObject = z.infer<typeof QuizPackageQuestionsObject>;
/**
 * live coding playback event
 */
export const runtime = z.string();
export const RevisionHistoriesRecord = z.record(
  /**
   * runtime or component type
   */
  runtime,
  z.object({
    history: RevisionObject.optional(),
  }),
);
export type RevisionHistoriesRecord = z.infer<typeof RevisionHistoriesRecord>;

export const RevisionHistories = z.record(runtime, Revision.array());
export type RevisionHistories = z.infer<typeof RevisionHistories>;
