import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { generateEntityKey } from "@hireroo/app-helper/normalizer";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { DemosIdStore } from "@hireroo/app-store/page/c/demos_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import ScreeningTestHeaderContainer, { ScreeningTestHeaderContainerProps } from "../../../../widget/v2/c/ScreeningTestHeader/Container";
import ChallengeCodingEditorInitialContainer, {
  ChallengeCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/ChallengeCodingEditor/InitializeContainer";
import ProjectCodingEditorContainerV3, {
  ProjectCodingEditorInitialContainerV3Props,
} from "../../../../widget/v2/shared/ProjectCodingEditorV3/InitialContainer";
import QuizCodingEditorInitialContainer, {
  QuizCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/QuizCodingEditor/InitialContainer";
import ScreeningTestTutorialContainer from "../../../../widget/v2/shared/ScreeningTestTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import SystemDesignCodingEditorInitialContainer, {
  SystemDesignCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/SystemDesignCodingEditor/InitialContainer";
import TestAnnouncementContainer, { TestAnnouncementContainerProps } from "../../../../widget/v2/shared/TestAnnouncement/Container";

export type GenerateInterviewPropsArgs = {
  demoId: string;
  canConnect: boolean;
};

type EntityComponentMap = Record<string, React.ReactNode>;

export const useGenerateProps = (args: GenerateInterviewPropsArgs): Pages.ScreeningTestProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const status = DemosIdStore.useDemoStatus();
  const candidateName = DemosIdStore.useCandidateName();
  const currentEntityKey = InterviewNavigation.useCurrentSelectedEntityKey();
  const entities = DemosIdStore.useEntities();
  const endInterviewStatus = DemosIdStore.useEndInterviewStatus();
  const loadingStatus = InterviewNavigation.useLoadingStatus();
  const endInterviewLoadingStatus = DemosIdStore.useEndInterviewLoadingStatus();
  const enabledWebSearch = DemosIdStore.useEnabledWebSearch();
  const enabledChatGPT = DemosIdStore.useEnabledChatGPT();
  const allQuestionsHaveBeenSubmitted = InterviewNavigation.useAllQuestionsHaveBeenSubmitted();
  const client = getGraphqlClient();
  useTitle(t("デモ"));

  const interviewNavigationContainerProps: Omit<ScreeningTestHeaderContainerProps, "showingMode"> = {
    onEndInterview: () => {
      DemosIdStore.updateEndInterviewLoadingStatus("PENDING");
      client
        .EndDemo({
          demoId: args.demoId,
        })
        .then(res => {
          DemosIdStore.setDemo(res.endDemo);
        })
        .catch(error => {
          Sentry.captureException(error);
        })
        .finally(() => {
          DemosIdStore.updateEndInterviewLoadingStatus("READY");
        });
    },
    willBlockEndInterview: !allQuestionsHaveBeenSubmitted,
    disabledFinishButton: endInterviewLoadingStatus === "PENDING",
  };

  const ContentMap: EntityComponentMap = React.useMemo<EntityComponentMap>(() => {
    return entities.reduce<EntityComponentMap>((all, entity) => {
      const key = generateEntityKey(entity);
      switch (entity.__typename) {
        case "ChallengeEntity": {
          const challengeCodingEditor: ChallengeCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.challengeEntityId,
            editorKind: "CANDIDATE",
            interviewKind: "DEMO",
            displayName: candidateName,
            enableBrowserEventDetector: true,
            enabledWebSearch: enabledWebSearch,
            enabledChatGPT: enabledChatGPT,
          };
          return { ...all, [key]: <ChallengeCodingEditorInitialContainer key={key} {...challengeCodingEditor} /> };
        }
        case "ProjectEntity": {
          switch (entity.question?.projectVersion) {
            case Graphql.ProjectVersion.V2:
            case Graphql.ProjectVersion.V3:
            case Graphql.ProjectVersion.V4: {
              const projectCodingEditorInitialContainerProps: ProjectCodingEditorInitialContainerV3Props = {
                mode: "DEVELOPMENT",
                uid: uid ?? "",
                entityId: entity.projectEntityId,
                endpointId: entity.projectEntityId,
                questionId: entity.question?.questionId ?? 0,
                questionVersion: entity.question?.version ?? INITIAL_VERSION,
                submissionId: 0,
                userName: "Demo User",
                isCandidate: true,
                enableBrowserEventDetector: true,
                isV4: entity.question?.projectVersion === Graphql.ProjectVersion.V4,
              };
              return { ...all, [key]: <ProjectCodingEditorContainerV3 key={key} {...projectCodingEditorInitialContainerProps} /> };
            }
            case undefined: {
              throw new Error(`Not found entity question`);
            }
            default:
              throw new Error(`Unsupported Project Version: ${entity.question?.projectVersion}`);
          }
        }
        case "QuizEntity": {
          const quizCodingEditorInitialContainerProps: QuizCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.quizEntityId,
            packageId: entity.pb_package?.packageId ?? 0,
            firstQuestionId: entity.pb_package?.questions.at(0)?.id ?? 0,
            interviewKind: "DEMO",
            enableBrowserEventDetector: true,
          };
          return { ...all, [key]: <QuizCodingEditorInitialContainer {...quizCodingEditorInitialContainerProps} /> };
        }
        case "SystemDesignEntity": {
          const systemDesignCodingEditorInitialContainerProps: SystemDesignCodingEditorInitialContainerProps = {
            operator: {
              kind: "CANDIDATE",
              displayName: candidateName,
            },
            interviewKind: "DEMO",
            showingOptions: [],
            uid: uid ?? "",
            entityId: entity.systemDesignEntityId,
            enableBrowserEventDetector: true,
          };
          return {
            ...all,
            [key]: <SystemDesignCodingEditorInitialContainer key={key} {...systemDesignCodingEditorInitialContainerProps} />,
          };
        }
        default: {
          return all;
        }
      }
    }, {});
  }, [candidateName, entities, uid, enabledWebSearch, enabledChatGPT]);

  if (endInterviewStatus) {
    const kindMap: Record<DemosIdStore.EndInterviewStatus, TestAnnouncementContainerProps["kind"]> = {
      TEST_HAS_ALREADY_BEEN_COMPLETED: "DEMO_HAS_ALREADY_BEEN_COMPLETED",
      TIMELIMIT_HAS_EXPIRED: "TIMELIMIT_HAS_EXPIRED",
    };
    return {
      layout: {
        loading: loadingStatus === "LOADING",
        NotificationBanner: null,
        Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
        Footer: <CandidateFooterContainer />,
        Snackbar: <SnackbarContainer />,
        Tutorial: null,
      },
      Content: <TestAnnouncementContainer kind={kindMap[endInterviewStatus]} />,
    };
  }
  switch (status) {
    case "STARTED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="RUNNING" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: args.canConnect && currentEntityKey ? ContentMap[currentEntityKey] : undefined,
      };
    }
    case "COMPLETED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: null,
        },
        Content: <TestAnnouncementContainer kind="FINISH_DEMO" />,
      };
    }
    default:
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: null,
        },
        Content: <TestAnnouncementContainer kind="TEST_HAS_ALREADY_BEEN_COMPLETED" />,
      };
  }
};
