import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { Pages } from "@hireroo/presentation";
import * as React from "react";

import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import RemoteInterviewQuestionSearchAreaContainer from "../../../../widget/v2/e/RemoteInterviewQuestionSearchArea/Container";
import RemoteInterviewTutorialContainer from "../../../../widget/v2/shared/RemoteInterviewTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { useAutoStartTutorial } from "./privateHelper";
import { useGenerateLeftSidePanelProps } from "./useGenerateLeftSidePanelProps";
import { useGenerateMainContentProps } from "./useGenerateMainContentProps";
import { useGenerateQuestionsTableFetchContainerProps } from "./useGenerateQuestionsTableFetchContainerProps";
import { useGenerateRightSidePanelProps } from "./useGenerateRightSidePanelProps";
import QuestionsTableFetchContainer from "./widget/QuestionsTable/FetchContainer";
import RemoteInterviewEmployeeHeaderContainer from "./widget/RemoteInterviewEmployeeHeader/Container";

export type GenerateRemoteInterviewForEmployeePropsArgs = {
  remoteId: string;
  collaborativeAction: LiveCoding.CollaborativeAction;
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useGenerateProps = (args: GenerateRemoteInterviewForEmployeePropsArgs): Pages.RemoteInterviewForEmployeeProps => {
  const [forceClose, setForceClose] = React.useState(false);
  const loadingStatus = RemotesId.QuestionsTableStore.useLoadingStatus();
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const mainContentProps = useGenerateMainContentProps({
    collaborativeState: args.collaborativeState,
    collaborativeAction: args.collaborativeAction,
  });
  useAutoStartTutorial(activeSessionVariant);
  const rightSidePanelProps = useGenerateRightSidePanelProps({
    remoteId: args.remoteId,
  });
  const leftSidePanelProps = useGenerateLeftSidePanelProps();
  const questionsTableFetchContainerProps = useGenerateQuestionsTableFetchContainerProps({
    collaborativeAction: args.collaborativeAction,
    onSelectQuestion: () => {
      setForceClose(prev => !prev);
    },
  });

  const enableLeftSidePanel = React.useMemo(() => {
    if (mainContentProps.tab.items.length === 0) {
      return false;
    }
    return true;
  }, [mainContentProps.tab.items.length]);

  return {
    mode: activeSessionVariant === "SYSTEM_DESIGN" ? "DRAWING" : "OTHER",
    layout: {
      NotificationBanner: null,
      Header: <RemoteInterviewEmployeeHeaderContainer remoteId={args.remoteId} collaborativeAction={args.collaborativeAction} />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <RemoteInterviewTutorialContainer />,
    },
    leftSidePanel: leftSidePanelProps,
    mainContent: mainContentProps,
    rightSidePanel: rightSidePanelProps,
    enableLeftSidePanel: enableLeftSidePanel,
    questionSearchDialog: {
      forceClose,
      SearchArea: <RemoteInterviewQuestionSearchAreaContainer isLoading={loadingStatus === "LOADING"} />,
      Table: <QuestionsTableFetchContainer {...questionsTableFetchContainerProps} />,
    },
  };
};
