import * as Graphql from "@hireroo/graphql/client/urql";

export const STATUS_REVERSE_MAP: Record<string, Graphql.QuestionStatus> = {
  [Graphql.QuestionStatus.Draft]: Graphql.QuestionStatus.Draft,
  [Graphql.QuestionStatus.Reviewing]: Graphql.QuestionStatus.Reviewing,
  [Graphql.QuestionStatus.Published]: Graphql.QuestionStatus.Published,
  [Graphql.QuestionStatus.Rejected]: Graphql.QuestionStatus.Rejected,
};

export const QUESTION_VARIANT_REVERSE_MAP: Record<string, Graphql.QuestionVariant> = {
  [Graphql.QuestionVariant.Challenge]: Graphql.QuestionVariant.Challenge,
  [Graphql.QuestionVariant.ChallengeAlgorithm]: Graphql.QuestionVariant.ChallengeAlgorithm,
  [Graphql.QuestionVariant.ChallengeDatabase]: Graphql.QuestionVariant.ChallengeDatabase,
  [Graphql.QuestionVariant.ChallengeClass]: Graphql.QuestionVariant.ChallengeClass,
  [Graphql.QuestionVariant.Project]: Graphql.QuestionVariant.Project,
  [Graphql.QuestionVariant.ProjectFrontend]: Graphql.QuestionVariant.ProjectFrontend,
  [Graphql.QuestionVariant.ProjectBackend]: Graphql.QuestionVariant.ProjectBackend,
  [Graphql.QuestionVariant.ProjectDataScience]: Graphql.QuestionVariant.ProjectDataScience,
  [Graphql.QuestionVariant.ProjectOther]: Graphql.QuestionVariant.ProjectOther,
  [Graphql.QuestionVariant.Quiz]: Graphql.QuestionVariant.Quiz,
  [Graphql.QuestionVariant.SystemDesign]: Graphql.QuestionVariant.SystemDesign,
};

export const DIFFICULTY_REVERS_MAP: Record<string, Graphql.Difficulty> = {
  [Graphql.Difficulty.Easy]: Graphql.Difficulty.Easy,
  [Graphql.Difficulty.Medium]: Graphql.Difficulty.Medium,
  [Graphql.Difficulty.Difficult]: Graphql.Difficulty.Difficult,
};

export const SORT_METHOD_REVERS_MAP: Record<string, Graphql.SortMethod> = {
  [Graphql.SortMethod.Unknown]: Graphql.SortMethod.Unknown,
  [Graphql.SortMethod.CreatedAt]: Graphql.SortMethod.CreatedAt,
  [Graphql.SortMethod.NumUses]: Graphql.SortMethod.NumUses,
  [Graphql.SortMethod.AccuracyRate]: Graphql.SortMethod.AccuracyRate,
  [Graphql.SortMethod.AverageElapsedTime]: Graphql.SortMethod.AverageElapsedTime,
};

export const QUESTION_LEAK_SCORE_LEVEL_REVERSE_MAP: Record<string, Graphql.QuestionLeakScoreLevel> = {
  [Graphql.QuestionLeakScoreLevel.Low]: Graphql.QuestionLeakScoreLevel.Low,
  [Graphql.QuestionLeakScoreLevel.Medium]: Graphql.QuestionLeakScoreLevel.Medium,
  [Graphql.QuestionLeakScoreLevel.High]: Graphql.QuestionLeakScoreLevel.High,
};

export type Question =
  | Graphql.ListAlgorithmQuestionFragment
  | Graphql.ListMultiChoiceQuestionFragment
  | Graphql.ListProjectQuestionFragment
  | Graphql.ListSystemDesignQuestionFragment;

export const QuestionTypeMap: Record<Question["__typename"], string> = {
  AlgorithmQuestion: "challenge",
  ProjectQuestion: "project",
  MultiChoicePackage: "quiz",
  SystemDesignQuestion: "systemdesign",
};

export type QuestionVariant =
  | "CHALLENGE"
  | "CHALLENGE_ALGORITHM"
  | "CHALLENGE_DATABASE"
  | "CHALLENGE_CLASS"
  | "QUIZ"
  | "PROJECT"
  | "PROJECT_FRONTEND"
  | "PROJECT_BACKEND"
  | "PROJECT_DATA_SCIENCE"
  | "PROJECT_OTHER"
  | "SYSTEM_DESIGN";

export const DefaultAvailableQuestionVariants: QuestionVariant[] = [
  "CHALLENGE",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "QUIZ",
  "PROJECT",
  "PROJECT_FRONTEND",
  "PROJECT_BACKEND",
  "PROJECT_DATA_SCIENCE",
  "PROJECT_OTHER",
  "SYSTEM_DESIGN",
];

export const GraphQLQuestionVariantMap: Record<QuestionVariant, Graphql.QuestionVariant> = {
  CHALLENGE: Graphql.QuestionVariant.Challenge,
  CHALLENGE_ALGORITHM: Graphql.QuestionVariant.ChallengeAlgorithm,
  CHALLENGE_DATABASE: Graphql.QuestionVariant.ChallengeDatabase,
  CHALLENGE_CLASS: Graphql.QuestionVariant.ChallengeClass,
  QUIZ: Graphql.QuestionVariant.Quiz,
  PROJECT: Graphql.QuestionVariant.Project,
  PROJECT_FRONTEND: Graphql.QuestionVariant.ProjectFrontend,
  PROJECT_BACKEND: Graphql.QuestionVariant.ProjectBackend,
  PROJECT_DATA_SCIENCE: Graphql.QuestionVariant.ProjectDataScience,
  PROJECT_OTHER: Graphql.QuestionVariant.ProjectOther,
  SYSTEM_DESIGN: Graphql.QuestionVariant.SystemDesign,
};

export type ListQuestion =
  | Graphql.ListAlgorithmQuestionFragment
  | Graphql.ListMultiChoiceQuestionFragment
  | Graphql.ListProjectQuestionFragment
  | Graphql.ListSystemDesignQuestionFragment;

export const QUERY_PARAMS_FOR_DEFAULT_QUESTIONS = {
  key: "questions",
};
