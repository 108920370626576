import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MemoEditor, { MemoEditorProps } from "../../modules/MemoEditor/MemoEditor";
import StatusInfo, { StatusInfoProps } from "../../modules/StatusInfo/StatusInfo";
import Timeline, { TimelineProps } from "../../modules/Timeline/Timeline";
import QuestionCard, { QuestionCardProps } from "../../ms-components/Question/QuestionCard/QuestionCard";
import QuestionOverviewTable, { QuestionOverviewTableProps } from "./parts/QuestionOverviewTable/QuestionOverviewTable";
import RemoteInfo, { RemoteInfoProps } from "./parts/RemoteInfo/RemoteInfo";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

export type RemoteInterviewOverviewProps = {
  timeline: TimelineProps;
  info: RemoteInfoProps;
  questions: QuestionCardProps[];
  memoEditor: MemoEditorProps;
  statusInfo?: StatusInfoProps;
  questionsTable?: QuestionOverviewTableProps;
};

const RemoteInterviewOverview: React.FC<RemoteInterviewOverviewProps> = props => {
  const { t } = useTranslation();
  const memoEditor: MemoEditorProps = {
    ...props.memoEditor,
  };
  return (
    <Box p={3}>
      {props.statusInfo && (
        <Stack pb={3}>
          <Box display="inline-block" width="100%" alignItems="center" justifyContent="center">
            <StatusInfo {...props.statusInfo} />
          </Box>
        </Stack>
      )}

      <StyledStack direction="column" spacing={2}>
        <Timeline {...props.timeline} />
        <MemoEditor key={`memo-${memoEditor.open.toString()}`} {...memoEditor} />
      </StyledStack>

      <Box my={3}>
        <RemoteInfo {...props.info} />
      </Box>

      <Box>
        <Typography mb={2} fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("出題する問題")}
        </Typography>

        {!props.questionsTable && (
          <StyledBox>
            {props.questions.length === 0 && (
              <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
                {t("出題予定の問題が登録されていません。インタビュー中に出題することも可能です。")}
              </Typography>
            )}

            {props.questions.length > 0 && (
              <Grid container spacing={3}>
                {props.questions.map(question => (
                  <Grid key={question.id} item xs={3} md={3}>
                    <QuestionCard {...question} />
                  </Grid>
                ))}
              </Grid>
            )}
          </StyledBox>
        )}
        {props.questionsTable && (
          <StyledBox>
            <QuestionOverviewTable {...props.questionsTable} />
          </StyledBox>
        )}
      </Box>
    </Box>
  );
};

RemoteInterviewOverview.displayName = "RemoteInterviewOverview";

export default RemoteInterviewOverview;
