import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Tab from "../../../../../primitive/Tab/Tab";
import Tabs, { TabsProps } from "../../../../../primitive/Tabs/Tabs";

type Icon = "CHAT" | "COMMENT" | "QUESTION";
const IconMap = {
  CHAT: <ChatBubbleOutlineOutlinedIcon fontSize="small" />,
  COMMENT: <CommentOutlinedIcon fontSize="small" />,
  QUESTION: <QuestionAnswerOutlinedIcon fontSize="small" />,
} satisfies Record<Icon, React.ReactElement>;

export type CustomLabelTabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  Component: React.ReactNode;
  name: string;
  label?: React.ReactNode;
  onClickTab?: () => void;
  className?: string;
  icon?: Icon;
};

export type CustomLabelTabProps = {
  wrapper?: BoxProps;
  defaultTab?: string;
  tabs?: Omit<TabsProps, "value">;
  items: CustomLabelTabItem[];
  tabBox?: BoxProps;
  tabPanelBox?: BoxProps;
};

const CustomLabelTab: React.FC<CustomLabelTabProps> = props => {
  const defaultTab = props.defaultTab || (props.items.length ? props.items[0].id : "");
  const wrapperBoxDetector = useResizeDetector();
  const tabsDetector = useResizeDetector();

  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
    props.tabs && props.tabs.onChange?.(event, newValue);
  };

  const TabPanels = props.items.map(item => {
    return (
      // disable original mui TabPanel  padding
      <TabPanel key={item.id} value={item.id} sx={{ px: 0, py: 0, height: "100%" }}>
        <Box height="100%">{item.Component}</Box>
      </TabPanel>
    );
  });

  return (
    <Box ref={wrapperBoxDetector.ref} height="100%">
      <TabContext value={currentName}>
        <Box {...props.wrapper} ref={tabsDetector.ref}>
          <Box sx={{ flexGrow: 1 }} {...props.tabBox}>
            <Tabs {...props.tabs} value={currentName} onChange={handleChange}>
              {props.items.map(item => {
                const label = typeof item.label === "string" ? <Typography textTransform="none">{item.label}</Typography> : item.label;
                return (
                  <Tab
                    key={item.id}
                    className={item.className}
                    label={
                      label || (
                        <Typography textTransform="none" variant="body2" fontSize={14}>
                          {item.name}
                        </Typography>
                      )
                    }
                    aria-labelledby={item.name}
                    value={item.id}
                    icon={item.icon ? IconMap[item.icon] : undefined}
                    iconPosition="start"
                    onClick={item.onClickTab}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Box>
        <Box {...props.tabPanelBox} height={`calc(100% - ${tabsDetector.height ?? 0}px)`}>
          {TabPanels}
        </Box>
      </TabContext>
    </Box>
  );
};

CustomLabelTab.displayName = "CustomLabelTab";

export default CustomLabelTab;
