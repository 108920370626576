import { PlaybackManager, PlaybackTickManager } from "@hireroo/app-helper/playback";
import { ChallengeQuestionVariant, initialLanguageMap } from "@hireroo/challenge/definition";
import { PlaybackEvent } from "@hireroo/validator";
import { ref } from "valtio";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (interview: Types.Interview): void => {
  state.interview = interview;
  const sessions = interview.entity?.liveCoding?.sessions;
  interview.entity?.liveCoding?.participants.forEach(participant => {
    if (participant) {
      state.participantMap.set(participant.userId, participant);
    }
  });
  sessions?.forEach(session => {
    if (session) {
      state.activeSessionMap.set(session.liveCodingSessionId, session);
    }
  });
  const variant = sessions?.[0]?.questionVariant;
  if (variant) {
    state.activeVariant = variant;
  }
  const sessionId = sessions?.[0]?.liveCodingSessionId;
  if (sessionId) {
    state.activeSessionId = sessionId;
  }
};

export const initializeComponentTypesHistories = (histories: Types.ComponentHistories) => {
  state.systemDesign.histories = histories;
  state.systemDesign.historiesStatus = "INITIALIZED";
  const editorOptions = Object.keys(histories);

  if (editorOptions.length === 0) {
    /**
     * If there are no data, set the default Component Type.
     */
    state.editorOptions = ["default"];
    state.selectedComponentType = "default";
    return;
  }

  state.editorOptions = editorOptions;
  const firstEditorOption = editorOptions.at(0);
  /**
   * Since Playback is in its final state when initially displayed, specify the last Revision Index.
   */
  if (firstEditorOption) {
    state.selectedEditorOption = firstEditorOption;
    if (firstEditorOption in histories) {
      state.sliderValue = histories[firstEditorOption].length - 1;
    }
  }
};

const handlePlaybackManagerOnEditorOptionChange = (selectedEditorOption: Types.EditorOption) => {
  const playbackManager = state.challenge.playbackManagerMap.get(selectedEditorOption);
  if (!playbackManager) return;
  playbackManager.setTickIndex(playbackManager.lastTickIndex);
  state.sliderValue = playbackManager.lastTickIndex;
};

const challengeVariantMap: Record<Types.LiveCodingQuestionVariant, ChallengeQuestionVariant> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  SYSTEM_DESIGN: "ALGORITHM",
  UNKNOWN: "ALGORITHM",
};

export const initializeRevisionHistories = (histories: Types.Histories) => {
  state.challenge.histories = histories;
  const editorOptions = Object.keys(histories);
  let maxHistoryLength = 0;
  let highestLengthOption: string | null = null;
  editorOptions.forEach(editorOption => {
    const playbackTickManager = new PlaybackTickManager();
    histories[editorOption]?.forEach(revision => {
      const syncOperation = PlaybackEvent.SyncOperation.safeParse(revision);
      if (syncOperation.success) {
        playbackTickManager.addTick(revision.t, {
          kind: "CODE_EDITOR",
          textOperations: syncOperation.data.o,
          userId: syncOperation.data.a,
          key: revision.k ?? "",
          ts: revision.t,
        });
      }
    });

    const playbackManager = new PlaybackManager(playbackTickManager);

    playbackManager.initialize();

    state.challenge.playbackManagerMap.set(editorOption, ref(playbackManager));

    // Find the editor option with the highest revision length
    const historyLength = histories[editorOption]?.length ?? 0;
    if (historyLength > maxHistoryLength) {
      maxHistoryLength = historyLength;
      highestLengthOption = editorOption;
    }
  });
  const challengeVariant = challengeVariantMap[state.activeVariant];
  /**
   * set initial language if the data is empty
   */
  state.editorOptions = editorOptions.length > 0 ? editorOptions : [initialLanguageMap[challengeVariant]];

  /**
   * set first editor option to the one with the highest revision length
   */
  const firstEditorOption = highestLengthOption || editorOptions.at(0);
  /**
   * Since Playback is in its final state when initially displayed, specify the last Revision Index.
   */
  if (firstEditorOption) {
    state.selectedEditorOption = firstEditorOption;
    handlePlaybackManagerOnEditorOptionChange(firstEditorOption);
  }
  state.challenge.historiesStatus = "INITIALIZED";
};

export const updateSelectedEditorOption = (editorOption: Types.EditorOption) => {
  state.selectedEditorOption = editorOption;
  handlePlaybackManagerOnEditorOptionChange(editorOption);
};

export const updateSliderValue = (sliderValue: number) => {
  state.sliderValue = sliderValue;
  state.challenge.playbackManagerMap.get(state.selectedEditorOption)?.setTickIndex(sliderValue);
};

export const updatePlayStatus = (playStatus: Types.PlayStatus) => {
  state.playStatus = playStatus;
};

export const clearSystemDesign = () => {
  state.systemDesign.histories = {};
  state.systemDesign.historiesStatus = "NOT_INITIALIZED";
};

export const clearChallenge = () => {
  state.challenge.histories = {};
  state.challenge.playbackManagerMap = proxyMap();
  state.challenge.historiesStatus = "NOT_INITIALIZED";
};

export const clear = () => {
  state.interview = null;
  state.activeSessionId = 0;
  state.activeSessionMap = proxyMap();
  state.activeVariant = "UNKNOWN";
  state.selectedRuntime = "";
  state.selectedComponentType = "default";
  state.participantMap = proxyMap();

  clearSystemDesign();
  clearChallenge();
};

export const updateQuestionVariant = (variant: Types.LiveCodingQuestionVariant) => {
  state.activeVariant = variant;
};

export const updateActiveSessionId = (newValue: Types.LiveCodingSessionId) => {
  state.activeSessionId = newValue;
};

export const updateActiveSession = (sessionId: Types.LiveCodingSessionId, variant: Types.LiveCodingQuestionVariant) => {
  state.activeSessionId = sessionId;
  state.activeVariant = variant;
  // playbackManagerMap depend on sessionId. When switching sessions, the map needs to be cleared.
  state.challenge.playbackManagerMap.clear();
};

export const setRefresh = (callback: Types.RefreshCallback) => {
  state.refresh = ref(callback);
};
