import { TwilioConversationsProvider, TwilioVideoProvider } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { ChallengeCodingEditorProvider } from "@hireroo/challenge/store";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { SystemDesignProvider } from "@hireroo/system-design/react/FlowChart";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../../widget/v2/c/PageErrorPanel/Container";
import RemoteInterviewForCandidateContainer, { RemoteInterviewForCandidateContainerProps } from "./Container";
import { useGenerateWaitingRoomProps } from "./useGenerateWaitingRoomProps";
import EntryRemoteInterviewContainer from "./widget/EntryRemoteInterview/Container";

export type RemoteInterviewForCandidateMiddleContainerProps = RemoteInterviewForCandidateContainerProps;

const challengeAcceptableVariantMap: Record<RemotesId.LiveCodingQuestionVariant, "DATABASE" | "ALGORITHM" | "CLASS"> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  SYSTEM_DESIGN: "ALGORITHM",
  UNKNOWN: "ALGORITHM",
};

const RemoteInterviewForCandidateMiddleContainer: React.FC<RemoteInterviewForCandidateMiddleContainerProps> = props => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const isJoined = RemotesId.useCheckJoinStatus(uid);
  const systemDesignId = RemotesId.useSystemDesignId();
  const algorithmId = RemotesId.useAlgorithmId();
  const status = RemotesId.useInterviewStatus();
  const variant = RemotesId.useLiveCodingQuestionVariant();

  const waitingRoomProps = useGenerateWaitingRoomProps({
    remoteId: props.remoteId,
    enablePolling: status === "CREATED",
  });

  switch (status) {
    case "COMPLETED":
    case "EVALUATED":
      return (
        <ErrorPanelContainer
          title={t("インタビューはすでに終了しているためアクセスできません。")}
          message={t("インタビューの結果等は企業様からのご連絡をお待ち下さい。")}
        />
      );
    case "CREATED":
      return (
        <ErrorBoundary>
          <Pages.WaitingRoom {...waitingRoomProps} />
        </ErrorBoundary>
      );
    case "UNKNOWN":
    case undefined:
      return <ErrorPanelContainer title={t("ページが見つかりませんでした。")} message={t("URLをご確認の上、再度お試し下さい。")} />;
    case "STARTED":
      break;
    default:
      throw new Error(`Unknown type: ${(status as { type: "__invalid__" }).type}`);
  }

  if (!isJoined) {
    return <EntryRemoteInterviewContainer />;
  }

  return (
    <ErrorBoundary>
      <ChallengeCodingEditorProvider
        id={algorithmId}
        variant={challengeAcceptableVariantMap[variant]}
        options={{
          // When switching tabs, the variant changes before unmounting
          disableSetStateOnUnmount: true,
        }}
      >
        <SystemDesignProvider id={systemDesignId}>
          <TwilioVideoProvider>
            <TwilioConversationsProvider>
              <RemoteInterviewForCandidateContainer {...props} />
            </TwilioConversationsProvider>
          </TwilioVideoProvider>
        </SystemDesignProvider>
      </ChallengeCodingEditorProvider>
    </ErrorBoundary>
  );
};

RemoteInterviewForCandidateMiddleContainer.displayName = "RemoteInterviewForCandidateMiddleContainer";

export default withErrorBoundary(RemoteInterviewForCandidateMiddleContainer, {});
