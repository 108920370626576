import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Test, entityId: Types.UniqueEntityId): void => {
  state.test = newValue;
  state.selectedEntityId = entityId;
};

export const updateTest = (newValue: Types.Test): void => {
  state.test = newValue;
};

export const clear = () => {
  state.test = null;
  state.selectedEntityId = null;
  state.dialogStatus = "CLOSED";
};

export const updateSelectedEntityId = (newValue: Types.UniqueEntityId) => {
  state.selectedEntityId = newValue;
};

export const updateDialogStatus = (newValue: Types.DialogStatus) => {
  state.dialogStatus = newValue;
};

export const setRefresh = (callback: Types.RefreshCallback) => {
  state.refresh = ref(callback);
};
