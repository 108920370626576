import { Mixpanel } from "@hireroo/app-monitoring";
import * as RouterStore from "@hireroo/app-store/essential/router";
import { Pages } from "@hireroo/presentation";
import { useMatchedPattern } from "@hireroo/router/hooks";
import * as React from "react";
import { Outlet } from "react-router-dom";

import ErrorPanelContainer from "../widget/shared/ErrorPanel/Container";
import SnackbarContainer from "../widget/shared/Snackbar/Container";

const BasicLayout: React.FC = () => {
  const errorStatus = RouterStore.useErrorStatus();
  const routing = useMatchedPattern();
  React.useEffect(() => {
    if (routing) {
      Mixpanel.trackPageView({
        routing: routing,
      });
    }
  }, [routing]);

  if (errorStatus) {
    return <ErrorPanelContainer />;
  }
  const emptyPageProps: Pages.EmptyProps = {
    layout: {
      loading: true,
      Bottom: <SnackbarContainer />,
    },
  };
  return (
    <React.Suspense fallback={<Pages.Empty {...emptyPageProps} />}>
      <Outlet />
    </React.Suspense>
  );
};

BasicLayout.displayName = "BasicLayout";

export default BasicLayout;
