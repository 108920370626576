import * as React from "react";

import { type Listener, type QuizEventRecordService } from "./QuizEventRecordService";

type QuestionId = number;
type SelectedOptionId = number;
type SelectedOptionsMap = Map<QuestionId, Set<SelectedOptionId>>;

export const useQuizEventRecordState = (service: QuizEventRecordService) => {
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<QuestionId>(service.selectedQuestionId);
  const [selectedQuizPackageId, setSelectedQuizPackageId] = React.useState(service.selectedPackageId);
  const [selectedOptionsMap, setSelectedOptionsMap] = React.useState<SelectedOptionsMap>(
    new Map([[service.selectedQuestionId, service.selectedOptions]]),
  );

  /**
   * 再レンダリングや、非同期的な更新による多重実行が考慮された、現在選択中の選択肢の更新関数
   */
  const updateSelectedOptions = React.useCallback(
    (questionId: number, newOptions: Set<number>) => {
      if (questionId !== selectedQuestionId) {
        return;
      }
      setSelectedOptionsMap(prevMap => {
        prevMap.set(questionId, newOptions);
        return new Map(prevMap);
      });
    },
    [selectedQuestionId],
  );

  React.useEffect(() => {
    const handleSelectPackage: Listener["selectPackage"]["callback"] = payload => {
      setSelectedQuizPackageId(payload.packageId);
    };
    const handleRestoreQuestionId: Listener["restoreQuestionId"]["callback"] = payload => {
      setSelectedQuestionId(payload.questionId);
      const selectedOptions = service.getSelectedOptionsByQuestionId(payload.questionId);
      updateSelectedOptions(payload.questionId, selectedOptions);
    };
    const handleChangeQuestionId: Listener["changeQuestionId"]["callback"] = payload => {
      setSelectedQuestionId(payload.questionId);
      const selectedOptions = service.getSelectedOptionsByQuestionId(payload.questionId);
      updateSelectedOptions(payload.questionId, selectedOptions);
    };
    const handleChangeSingleSelectedOptions: Listener["changeSingleSelectedOptions"]["callback"] = payload => {
      setSelectedQuestionId(payload.questionId);
      updateSelectedOptions(payload.questionId, payload.optionIds);
    };
    const handleClearOptions: Listener["clearOptions"]["callback"] = payload => {
      setSelectedOptionsMap(prevMap => {
        const prev = prevMap.get(payload.questionId);
        if (!prev) {
          return prevMap;
        }
        if (prev.size === 0) {
          return prevMap;
        }
        prevMap.delete(payload.questionId);
        return new Map(prevMap);
      });
    };
    const handleChangeMultiSelectedOptions: Listener["changeMultiSelectedOptions"]["callback"] = payload => {
      updateSelectedOptions(payload.questionId, payload.optionIds);
    };

    const cleanups = [
      service.on("selectPackage", handleSelectPackage),
      service.on("restoreQuestionId", handleRestoreQuestionId),
      service.on("changeQuestionId", handleChangeQuestionId),
      service.on("changeSingleSelectedOptions", handleChangeSingleSelectedOptions),
      service.on("clearOptions", handleClearOptions),
      service.on("changeMultiSelectedOptions", handleChangeMultiSelectedOptions),
    ];

    return () => {
      for (const cleanup of cleanups) {
        cleanup();
      }
    };
  }, [service, updateSelectedOptions]);
  const selectedOptions = selectedOptionsMap.get(selectedQuestionId) || new Set<SelectedOptionId>();

  React.useEffect(() => {
    /**
     * 解答する問題を高速に切り替えると、切り替える前の問題のイベント残っているケースが有り、表示(Reactで管理)と処理中(serviceで管理)のデータがずれることがある
     * これを表示側の状態に合わせるためにこのuseEffectが利用される
     */
    if (selectedQuestionId !== service.selectedQuestionId) {
      service.selectQuestion(selectedQuestionId);
    }
  }, [selectedQuestionId, service]);

  return {
    selectedQuestionId,
    selectedQuizPackageId,
    selectedOptions: selectedOptions,
  };
};

export type QuizEventRecordState = ReturnType<typeof useQuizEventRecordState>;
