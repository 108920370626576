import { copyToClipboard } from "@hireroo/app-helper/copy-to-clipboard";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledSinSClassPhase1 } from "@hireroo/app-helper/feature";
import { hasSpotStatus } from "@hireroo/app-helper/interview";
import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useExportPdf } from "./useExportPdf";

export type GenerateHeaderPropsArgs = {
  screeningTestId: string;
};

type Option = Widget.ScreeningTestDetailForEmployeeProps["header"]["moreButton"]["options"][0];
export const useGenerateHeaderProps = (args: GenerateHeaderPropsArgs): Widget.ScreeningTestDetailForEmployeeProps["header"] => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const interviewDetail = ScreeningsTestsIdDetailStore.useInterview();
  const navigate = useTransitionNavigate();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const pdfExport = ScreeningsTestsIdDetailStore.usePdfExport(args.screeningTestId);
  const validDownloadPdfStatuses: Graphql.SpotStatus[] = ["EVALUATED", "REVIEWED", "FINALIZED"];
  const canDownloadPdf = validDownloadPdfStatuses.includes(interviewDetail.status);
  const enabledSinSClassPhase1 = useEnabledSinSClassPhase1();
  const client = getGraphqlClient();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const { runPdfExport } = useExportPdf({
    spotId: args.screeningTestId,
    language: (
      {
        ja: "JA",
        en: "EN",
      } as const
    )[lang],
    outputFilename: `${interviewDetail.name}_${interviewDetail.candidateName}.pdf`,
  });

  const shareButton = React.useMemo((): Widget.ScreeningTestDetailForEmployeeProps["header"]["shareButton"] => {
    const common: Omit<Widget.ScreeningTestDetailForEmployeeProps["header"]["shareButton"], "disabled" | "title"> = {
      onClick: () => {
        ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_SHARE_LINK");
      },
    };
    if (!matchingRole.ADMIN_OR_MANAGER.matched) {
      return {
        ...common,
        disabled: true,
        title: t2("PermissionTooltip", {
          actualRole: currentRoleDisplayText,
          expectedRole: expectedRole,
        }),
      };
    }
    const isFinalized = hasSpotStatus(interviewDetail.status, ["FINALIZED", "EVALUATED"]);
    return {
      ...common,
      disabled: !isFinalized,
      title: !isFinalized ? t("採点終了後にレポート共有することができます。") : t("レポートの共有"),
    };
  }, [currentRoleDisplayText, expectedRole, interviewDetail.status, matchingRole.ADMIN_OR_MANAGER.matched, t, t2]);

  return {
    screeningButton:
      interviewDetail.screening && interviewDetail.screening.deletedAtSeconds === null
        ? {
            children: interviewDetail.screening.name,
            onClick: () => {
              navigate("/e/screenings/:id/detail", { pathParams: { id: interviewDetail.screening?.screeningId ?? "" } });
            },
          }
        : undefined,
    candidateName: `${interviewDetail.originId === interviewDetail.id ? "" : t("（再試験）")}${
      interviewDetail.candidateName || interviewDetail.name
    }`,
    candidateEmail: interviewDetail.candidateEmail,
    shareButton: shareButton,
    reviewButton:
      isAvailableFeature("test.technical-comment.create") && ["COMPLETED", "FINALIZED", "EVALUATED"].includes(interviewDetail.status)
        ? {
            onClick: () => {
              ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_TECH_REVIEW");
            },
            disabled: false,
          }
        : undefined,
    evaluateButton:
      interviewDetail.status === "FINALIZED"
        ? {
            onClick: () => {
              ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_EVALUATION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          }
        : undefined,
    createInterviewButton:
      enabledSinSClassPhase1 && (interviewDetail.status === "FINALIZED" || interviewDetail.status === "EVALUATED")
        ? {
            onClick: () => {
              navigate("/e/remotes/create", {
                queryParams: {
                  spotId: interviewDetail.id,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          }
        : undefined,
    moreButton: {
      options: React.useMemo((): Option[] => {
        const disableSpotStatus = hasSpotStatus(interviewDetail.status, ["STARTED", "COMPLETED"]);
        return [
          {
            startIcon: "INSERT_LINK",
            value: "copy-test-link",
            displayName: t("テストリンクをコピー"),
            onClick: () => {
              const url = generateCurrentOriginUrl("/c/screenings/tests/:id", {
                pathParams: {
                  id: interviewDetail.id,
                },
              });
              copyToClipboard(url).then(() => {
                Snackbar.notify({
                  message: t("クリップボードにコピーされました"),
                  severity: "info",
                });
              });
            },
            disabled: hasSpotStatus(interviewDetail.status, ["DECLINED", "EVALUATED", "REVIEWED", "FINALIZED", "COMPLETED"]),
            disabledText: hasSpotStatus(interviewDetail.status, ["DECLINED", "EVALUATED", "REVIEWED", "FINALIZED", "COMPLETED"])
              ? t("すでにこのテストは終了しています。")
              : undefined,
          },
          {
            value: "recreate-test",
            displayName: t("再試験を発行"),
            onClick: () => {
              ScreeningsTestsIdDetailStore.updateIsSpotForRetakeLoading(true);
              client
                .GetSpotForRetakeInterviewsIdDetail({
                  id: interviewDetail.id,
                  withAnswers: true,
                  ignoreInheritance: true,
                })
                .then(res => {
                  ScreeningsTestsIdDetailStore.updateSpotForRetake(res.spot);
                })
                .catch(error => {
                  Sentry.captureException(error);
                  const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                    error,
                    t("試験の取得に失敗しました。しばらくしてから再度お試しください。"),
                  );
                  Snackbar.notify({
                    severity: "error",
                    message: errorNotification.message,
                  });
                })
                .finally(() => {
                  ScreeningsTestsIdDetailStore.updateIsSpotForRetakeLoading(false);
                });
              ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_RECREATION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || hasSpotStatus(interviewDetail.status, ["CREATED", "ACCEPTED", "STARTED"]),
            disabledText: !matchingRole.ADMIN_OR_MANAGER.matched
              ? t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                })
              : t("テストが開始するまで再発行することはできません。"),
            divider: false,
            startIcon: "REPLAY",
          },
          {
            value: "pdf-download",
            displayName: t("PDFをダウンロード"),
            onClick: () => {
              runPdfExport();
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || !canDownloadPdf || pdfExport?.status === "PENDING",
            disabledText: !matchingRole.ADMIN_OR_MANAGER.matched
              ? t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                })
              : undefined,
            divider: false,
            startIcon: pdfExport?.status === "PENDING" ? "LOADING" : "DOWNLOAD",
          },
          {
            value: "delete",
            displayName: t("削除する"),
            color: "error",
            onClick: () => {
              ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_DELETION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || disableSpotStatus,
            disabledText: !matchingRole.ADMIN_OR_MANAGER.matched
              ? t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                })
              : t("実施中または採点中の候補者は削除できません。"),
            divider: false,
            startIcon: "DELETE",
          },
        ];
      }, [
        t,
        matchingRole.ADMIN_OR_MANAGER.matched,
        t2,
        currentRoleDisplayText,
        expectedRole,
        interviewDetail.status,
        interviewDetail.id,
        canDownloadPdf,
        pdfExport?.status,
        client,
        runPdfExport,
      ]),
    },
  };
};
