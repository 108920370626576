import { useTranslation } from "@hireroo/i18n";
import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type SubmitStatus = "SUBMITTED" | "NONE" | "SKIP";

const EllipsisText = styled(ListItemText)({
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&.MuiListItemButton-root": {
    "&:hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
}));

const MarkBox = styled(Box)`
  width: 53px;
  height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
`;

export type QuestionListItemProps = {
  id: string;
  questionId: number;
  title: string;
  subTitle: string;
  selected?: boolean;
  status: SubmitStatus;
  onClick: () => void;
  disabled?: boolean;
};

const QuestionListItem: React.FC<QuestionListItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ListItem disablePadding alignItems="center">
      <StyledListItemButton onClick={props.onClick} selected={props.selected} disabled={props.disabled}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {props.status === "SUBMITTED" && (
            <MarkBox>
              <Tooltip title={t("解答済み")}>
                <Check color="success" fontSize="small" />
              </Tooltip>
            </MarkBox>
          )}

          {props.status === "SKIP" && (
            <MarkBox>
              <Tooltip title={t("スキップ")}>
                <Typography color="disabled" variant="caption" sx={{ color: theme.palette.text.disabled }}>
                  SKIP
                </Typography>
              </Tooltip>
            </MarkBox>
          )}

          {props.status === "NONE" && (
            <MarkBox>
              <Tooltip title={t("未提出")}>
                <Typography color="disabled" variant="caption" fontSize={11} sx={{ color: theme.palette.text.disabled }}>
                  {t("未提出")}
                </Typography>
              </Tooltip>
            </MarkBox>
          )}
          <Box minWidth="50px">
            <Typography variant="body2" color="textSecondary" textAlign="left">
              {props.subTitle}
            </Typography>
          </Box>
          <EllipsisText primary={props.title} />
        </Stack>
      </StyledListItemButton>
    </ListItem>
  );
};

QuestionListItem.displayName = "QuestionListItem";

export default QuestionListItem;
