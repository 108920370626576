import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.questionObjects !== null;
};

export const useSearchQuery = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchQuery;
};

export const useCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.count;
};

export const useQuestions = () => {
  const snapshot = useSnapshotState();
  return (snapshot.questionObjects || [])
    .map((questionObject): Types.NormalizedQuestion | undefined => {
      const { question } = questionObject;
      switch (question.__typename) {
        case "AlgorithmQuestion": {
          return {
            id: question.questionId,
            questionVariant: questionObject.questionVariant,
            liveCodingQuestionType: "ALGORITHM",
            liveCodingVariant: question.variant,
            status: question.status,
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            isPrivate: question.isPrivate,
            titleJa: question.titleJa,
            titleEn: question.titleEn,
            descriptionJa: question.descriptionJa,
            descriptionEn: question.descriptionEn,
            version: question.version,
            supportedLanguages: [...question.supportedLanguages],
            numUses: question.numUses,
            leakScore: question.leakScore,
            accuracyRate: question.accuracyRate,
            averageElapsedTimeSeconds: question.averageElapsedTimeSeconds,
            timeLimitSeconds: question.timeLimitSeconds,
          };
        }
        case "SystemDesignQuestion": {
          return {
            id: question.questionId,
            questionVariant: questionObject.questionVariant,
            liveCodingQuestionType: "SYSTEM_DESIGN",
            liveCodingVariant: "SYSTEM_DESIGN",
            status: "PUBLISHED",
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            isPrivate: question.isPrivate,
            titleJa: question.titleJa,
            titleEn: question.titleEn,
            descriptionJa: question.descriptionJa,
            descriptionEn: question.descriptionEn,
            version: INITIAL_VERSION,
            numUses: question.numUses,
            accuracyRate: question.accuracyRate,
            leakScore: null,
            averageElapsedTimeSeconds: question.averageElapsedTimeSeconds,
            timeLimitSeconds: question.timeLimitSeconds,
          };
        }
      }
    })
    .filter(question => question !== undefined) as Types.NormalizedQuestion[];
};

export const useHiddenQuestionSet = () => {
  const snapshot = useSnapshotState();
  return snapshot.hiddenQuestionSet;
};

export const useLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.loadingStatus;
};
