import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  test: null,
  selectedEntityId: null,
  dialogStatus: "CLOSED",
  refresh: () => undefined,
});
