import { extractUniqueEntity } from "@hireroo/app-helper/entity";
import { App, Auth } from "@hireroo/app-store/essential/employee";
import { TestReport } from "@hireroo/app-store/widget/e/TestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import TestReportContainer, { TestReportContainerProps } from "./Container";

export type ScreeningTestReportFetchContainerProps = {
  interviewId: string;
};

const ScreeningTestReportFetchContainer: React.FC<ScreeningTestReportFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = TestReport.useInitialized();
  const currentUid = Auth.useCurrentUid();
  const [result, refresh] = Graphql.useGetInterviewReportQuery({
    variables: {
      id: props.interviewId,
      withAnswers: true,
      currentEmployeeId: currentUid,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    return () => {
      TestReport.clear();
    };
  }, []);
  React.useEffect(() => {
    if (result.data?.spot) {
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      const isValid = result.data.spot.entities.some(entity => entity.entityId === uniqueEntity?.id);
      const firstUniqueEntityId = TestReport.generateUniqueEntityId(result.data.spot.entities[0]);
      const entityId: TestReport.UniqueEntityId = uniqueEntity && isValid ? `${uniqueEntity.type}-${uniqueEntity.id}` : firstUniqueEntityId;
      TestReport.initialize(result.data.spot, entityId);
    }
  }, [result.data]);

  React.useEffect(() => {
    TestReport.setRefresh(refresh);
  }, [refresh]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: TestReportContainerProps = {};
  return (
    <ErrorBoundary>
      <TestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningTestReportFetchContainer.displayName = "TestReportFetchContainer";

export default withErrorBoundary(ScreeningTestReportFetchContainer, {});
