import { UNSUPPORTED_READABILITY_RUNTIMES } from "@hireroo/app-definition/challenge";
import { colorFromUserId } from "@hireroo/app-helper/color";
import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { useEnabledProjectReportV4 } from "@hireroo/app-helper/feature";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { safeJsonParse } from "@hireroo/app-helper/parser";
import { Payment } from "@hireroo/app-store/essential/employee";
import { TestReport } from "@hireroo/app-store/widget/e/TestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { updateHashParam } from "@hireroo/router/api";
import { ProjectV4Metrics } from "@hireroo/validator";
import * as React from "react";

import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateCustomScoreAllocationProps } from "./useGenerateCustomScoreAllocationProps";

type EntityScoreSelector = Widget.ScreeningTestReportProps["summary"]["entityScoreSelector"];
type ScoreSummaryDetailItem = Exclude<EntityScoreSelector["detailDialog"], undefined>["items"][0];
type ScoreSummaryItem = Widget.ScreeningTestReportProps["summary"]["entityScoreSelector"]["items"][0];
type ScoreBar = Exclude<ScoreSummaryDetailItem["scoreBars"], undefined>[0];

export const useGenerateEntityScoreSelector = (): EntityScoreSelector => {
  const { t } = useTranslation();
  const test = TestReport.useTest();
  const lang = useCurrentLanguage();
  const selectedEntityId = TestReport.useSelectedEntityId();
  const [forceClose, setForceClose] = React.useState(false);
  const customScoreAllocationProps = useGenerateCustomScoreAllocationProps();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const enabledProjectReportV4 = useEnabledProjectReportV4();

  const scoreSummaryItems = test.entities.map((entity, index): ScoreSummaryItem => {
    const questionNumber = `Q${index + 1}. `;
    if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
      const question = entity.challengeQuestion;
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      const isSuspicious = (() => {
        if (entity.suspiciousInference === null) {
          return false;
        }

        return entity.suspiciousInference.suspiciousDegree === Graphql.ChallengeSuspiciousDegree.Suspicious;
      })();

      return {
        id: entity.id,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        scoreInteger: formatScore(entity.totalScore),
        difficulty: entity.challengeQuestion.difficulty,
        toggleButton: {
          onClick: () => {
            TestReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
            scrollToContentForReportV2(ENTITY_REPORT_TOP);
          },
          selected: selectedEntityId === uniqueEntityId,
        },
        showSuspiciousIcon: isAvailableFeature("test.suspicious-degree.read") && isSuspicious,
      };
    } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
      const pkg = entity.pb_package;
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      return {
        id: entity.id,
        title: questionNumber + resolveLanguage(pkg, lang, "title"),
        scoreInteger: formatScore(entity.totalScore),
        difficulty: entity.pb_package.difficulty,
        toggleButton: {
          onClick: () => {
            TestReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
            scrollToContentForReportV2(ENTITY_REPORT_TOP);
          },
          selected: selectedEntityId === uniqueEntityId,
        },
        showSuspiciousIcon: false,
      };
    } else if (entity.__typename === "ProjectEntity" && entity.question) {
      const question = entity.question;
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      return {
        id: entity.id,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        scoreInteger: formatScore(entity.totalScore),
        difficulty: entity.question.difficulty,
        toggleButton: {
          onClick: () => {
            TestReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
            scrollToContentForReportV2(ENTITY_REPORT_TOP);
          },
          selected: selectedEntityId === uniqueEntityId,
        },
        showSuspiciousIcon: false,
      };
    } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
      const question = entity.question;
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      return {
        id: entity.id,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        scoreInteger: formatScore(entity.totalScore),
        difficulty: entity.question.difficulty,
        toggleButton: {
          onClick: () => {
            TestReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
            scrollToContentForReportV2(ENTITY_REPORT_TOP);
          },
          selected: selectedEntityId === uniqueEntityId,
        },
        showSuspiciousIcon: false,
      };
    } else {
      throw new Error("unknown entity type");
    }
  });

  const scoreSummaryDetailItems = test.entities.map((entity, index): ScoreSummaryDetailItem => {
    const questionNumber = `Q${index + 1}. `;
    if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
      const question = entity.challengeQuestion;
      const submission = entity.submissions.filter(s => s.questionId === question.questionId && s.isBest)[0];
      const readability = submission?.readability ? formatScore(submission.readability) : 0;
      const hasReadability = submission?.readabilityTestResult === "" ? false : !UNSUPPORTED_READABILITY_RUNTIMES.includes(submission?.runtime);
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      const scoreBars = (() => {
        if (!submission) {
          return [];
        }
        const items: ScoreBar[] = [
          {
            title: t("正解率"),
            score: formatScore(submission.coverage),
            color: "secondary",
          },
          {
            title: t("パフォーマンス"),
            score: formatScore(submission.performance),
            color: "info",
          },
        ];
        if (hasReadability) {
          items.push({
            title: t("可読性"),
            score: readability,
            color: "warning",
          });
        }
        return items;
      })();

      return {
        id: uniqueEntityId,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        difficulty: entity.challengeQuestion.difficulty,
        variantText: t("コーディング形式"),
        scoreChart: {
          score: formatScore(entity.totalScore),
        },
        scoreBars: scoreBars,
        status: entity.challengeStatus === "EVALUATED" ? "EVALUATED" : "EVALUATING",
        detailButton: {
          onClick: () => {
            updateHashParam(hashKey);
            TestReport.updateSelectedEntityId(uniqueEntityId);
            setForceClose(true);
            // It has to wait until the dialog is closed
            scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
          },
        },
      };
    } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
      const pkg = entity.pb_package;
      const hashKey = generateSmoothTargetId(entity);

      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      return {
        id: uniqueEntityId,
        title: questionNumber + resolveLanguage(pkg, lang, "title"),
        difficulty: entity.pb_package.difficulty,
        variantText: t("クイズ形式"),
        scoreChart: {
          score: formatScore(entity.totalScore),
        },
        scoreBars: [
          {
            title: t("正解率"),
            score: formatScore(entity.totalScore),
            color: "secondary",
          },
        ],
        status: "EVALUATED",
        detailButton: {
          onClick: () => {
            updateHashParam(hashKey);
            TestReport.updateSelectedEntityId(uniqueEntityId);
            setForceClose(true);
            // It has to wait until the dialog is closed
            scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
          },
        },
      };
    } else if (entity.__typename === "ProjectEntity" && entity.question) {
      const question = entity.question;
      const hashKey = generateSmoothTargetId(entity);
      const submission = entity.submissions.find(s => s.isBest);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      const isProjectV4 = enabledProjectReportV4 && entity.question.projectVersion === Graphql.ProjectVersion.V4;
      const projectV4ScoreBars: ScoreBar[] = [];
      if (isProjectV4 && submission) {
        const result = ProjectV4Metrics.ProjectMetrics.safeParse(safeJsonParse(submission.metrics));
        if (result.success) {
          const items: ScoreBar[] = result.data.metrics.map(metric => {
            return {
              title: resolveLanguage(metric, lang, "title"),
              score: formatScore(metric.score),
              color: "info",
              // generate random color from metric title en
              bgColor: colorFromUserId(metric.title_en),
            };
          });
          projectV4ScoreBars.push(...items);
        }
      }

      return {
        id: uniqueEntityId,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        difficulty: entity.question.difficulty,
        variantText: t("実践形式"),
        scoreChart: {
          score: formatScore(entity.totalScore),
        },
        scoreBars: isProjectV4
          ? projectV4ScoreBars
          : [
              {
                title: t("正解率"),
                score: submission?.coverage ? formatScore(submission.coverage) : 0,
                color: "secondary",
              },
              {
                title: t("パフォーマンス"),
                score: submission?.performance ? formatScore(submission.performance) : 0,
                color: "info",
              },
            ],
        status: "EVALUATED",
        detailButton: {
          onClick: () => {
            updateHashParam(hashKey);
            TestReport.updateSelectedEntityId(uniqueEntityId);
            setForceClose(true);
            // It has to wait until the dialog is closed
            scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
          },
        },
      };
    } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
      const question = entity.question;
      const hashKey = generateSmoothTargetId(entity);
      const uniqueEntityId = TestReport.generateUniqueEntityId(entity);
      const submission = entity.submissions.find(s => s.isBest);
      const lastEvaluation = submission?.evaluations[submission.evaluations.length - 1];
      const scoreBars = (() => {
        if (!lastEvaluation) {
          return [];
        }
        const items: ScoreBar[] = [];
        if (lastEvaluation.availability !== null) {
          items.push({
            title: t("可用性"),
            score: formatScore(lastEvaluation.availability),
            color: "secondary",
          });
        }
        if (lastEvaluation.scalability !== null) {
          items.push({
            title: t("スケーラビリティ"),
            score: formatScore(lastEvaluation.scalability),
            color: "info",
          });
        }
        if (lastEvaluation.consistency !== null) {
          items.push({
            title: t("一貫性"),
            score: formatScore(lastEvaluation.consistency),
            color: "warning",
          });
        }
        return items;
      })();
      return {
        id: entity.id,
        title: questionNumber + resolveLanguage(question, lang, "title"),
        difficulty: entity.question.difficulty,
        variantText: t("システムデザイン形式"),
        scoreChart: {
          score: formatScore(entity.totalScore),
        },
        scoreBars: scoreBars,
        status: entity.systemDesignStatus === "EVALUATED" ? "EVALUATED" : "EVALUATING",
        detailButton: {
          onClick: async () => {
            updateHashParam(hashKey);
            TestReport.updateSelectedEntityId(uniqueEntityId);
            setForceClose(true);
            // It has to wait until the dialog is closed
            scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
          },
        },
      };
    } else {
      throw new Error("unknown entity type");
    }
  });

  return {
    detailDialog: {
      items: scoreSummaryDetailItems,
      forceClose: forceClose,
      onClose: () => {
        setForceClose(false);
      },
    },
    items: scoreSummaryItems,
    defaultValue: selectedEntityId || "",
    customScoreAllocation: customScoreAllocationProps,
  };
};
