import { stringComponentTypeToGraphqlComponentTypeGraphql } from "@hireroo/app-helper/system-design";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { Fields } from "@hireroo/validator";

export const convertEntitySourceToSpotEntitySourceForEntityTrackInput = (
  entitySource: Fields.EntitySource.EntitySource,
): Graphql.SpotEntitySourceForEntityTrackInput => {
  switch (entitySource.type) {
    case "CHALLENGE":
      return {
        challengeQuestion: {
          questionId: entitySource.questionId,
          questionVersion: entitySource.questionVersion,
          enabledLanguages: entitySource.enabledLanguages,
        },
      };
    case "PROJECT":
      return {
        projectQuestion: {
          questionId: entitySource.questionId,
          questionVersion: entitySource.questionVersion,
        },
      };
    case "QUIZ":
      return {
        quizPackage: {
          packageId: entitySource.packageId,
          packageVersion: entitySource.packageVersion,
        },
      };
    case "SYSTEM_DESIGN":
      return {
        systemDesignQuestion: {
          questionId: entitySource.questionId,
          componentTypes: entitySource.componentTypes.map(componentType => stringComponentTypeToGraphqlComponentTypeGraphql[componentType]),
        },
      };
    case "REMOTE_ALGORITHM":
    case "REMOTE_SYSTEM_DESIGN":
      throw new Error(`Unsupported Entity Type: ${entitySource.type}`);
    default:
      throw new Error(`Invalid Entity Source: ${entitySource satisfies never}`);
  }
};

export const convertEntitySourceToSpotEntitySourceInput = (
  entitySource: Fields.EntitySource.EntitySource,
  questionScoreWeight: number,
): Graphql.SpotEntitySourceInput => {
  switch (entitySource.type) {
    case "CHALLENGE":
      return {
        entityType: "CHALLENGE",
        questionId: entitySource.questionId,
        challengeQuestion: {
          questionId: entitySource.questionId,
          questionVersion: entitySource.questionVersion,
          enabledLanguages: entitySource.enabledLanguages,
        },
        questionWeight: questionScoreWeight,
      };
    case "PROJECT":
      return {
        entityType: "PROJECT",
        questionId: entitySource.questionId,
        projectQuestion: {
          questionId: entitySource.questionId,
          questionVersion: entitySource.questionVersion,
        },
        questionWeight: questionScoreWeight,
      };
    case "QUIZ":
      return {
        entityType: "QUIZ",
        questionId: entitySource.packageId,
        quizPackage: {
          packageId: entitySource.packageId,
          packageVersion: entitySource.packageVersion,
        },
        questionWeight: questionScoreWeight,
      };
    case "SYSTEM_DESIGN":
      return {
        entityType: "SYSTEM_DESIGN",
        questionId: entitySource.questionId,
        systemDesignQuestion: {
          questionId: entitySource.questionId,
          componentTypes: entitySource.componentTypes.map(componentType => stringComponentTypeToGraphqlComponentTypeGraphql[componentType]),
        },
        questionWeight: questionScoreWeight,
      };
    case "REMOTE_ALGORITHM":
    case "REMOTE_SYSTEM_DESIGN":
      throw new Error(`Unsupported Entity Type: ${entitySource.type}`);
    default:
      throw new Error(`Invalid Entity Source: ${entitySource satisfies never}`);
  }
};
