import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import RemoteInterviewChallengePlaybackEditorContainer from "./RemoteInterviewChallengePlaybackEditor/Container";
import { useGenerateContentLeftSidePanelProps } from "./useGenerateContentLeftSidePanelProps";

export const useGenerateContentProps = (): Widget.RemoteInterviewEntityPlaybackProps["content"] => {
  const normalizedActiveSession = RemoteInterviewPlayback.useNormalizedActiveSession();
  const selectedEditorOption = RemoteInterviewPlayback.useSelectedEditorOption();
  const leftSidePanel = useGenerateContentLeftSidePanelProps();

  const key = React.useMemo(() => {
    return `${normalizedActiveSession?.liveCodingSessionId}-${selectedEditorOption}`;
  }, [normalizedActiveSession?.liveCodingSessionId, selectedEditorOption]);

  const VariantEditorMap = React.useMemo((): Record<Graphql.RemoteQuestionVariant, React.ReactNode> => {
    return {
      ALGORITHM: <RemoteInterviewChallengePlaybackEditorContainer key={`algorithm-${key}`} />,
      DATABASE: <RemoteInterviewChallengePlaybackEditorContainer key={`database-${key}`} />,
      CLASS: <RemoteInterviewChallengePlaybackEditorContainer key={`class-${key}`} />,
      SYSTEM_DESIGN: null,
      UNKNOWN: null,
    };
  }, [key]);

  return {
    leftSidePanel: leftSidePanel,
    Editor: normalizedActiveSession ? VariantEditorMap[normalizedActiveSession.variant] : <Widget.Loading kind={"CENTER_%"} />,
  };
};
