import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useQuestionScoreWeight = () => {
  const { t } = useTranslation();
  return z.preprocess(
    v => (isNaN(Number(v)) ? 1 : Number(v)),
    z
      .number()
      .int({ message: t("整数のみ入力可能です") })
      .min(1, { message: t("1以上100以下の値を入力してください") })
      .max(100, { message: t("1以上100以下の値を入力してください") })
      .default(1),
  );
};

const useChallengeEntitySource = () => {
  const { t } = useTranslation();
  return z.object({
    type: z.literal("CHALLENGE"),
    uniqueKey: z.string(),
    questionId: z.number(),
    questionVersion: z.string(),
    enabledLanguages: z
      .string()
      .array()
      .min(1, { message: t("少なくとも1つ以上の言語を選択してください。") }),
    isSelectable: z.boolean(),
  });
};

export type ChallengeEntitySource = z.infer<ReturnType<typeof useChallengeEntitySource>>;

const useQuizEntitySource = () => {
  return z.object({
    type: z.literal("QUIZ"),
    uniqueKey: z.string(),
    packageId: z.number(),
    packageVersion: z.string(),
    isSelectable: z.boolean(),
  });
};

export type QuizEntitySource = z.infer<ReturnType<typeof useQuizEntitySource>>;

export const useSystemDesignComponentType = () => {
  return z.union([z.literal("default"), z.literal("gcp"), z.literal("aws"), z.literal("azure")]);
};

export type SystemDesignComponentType = z.infer<ReturnType<typeof useSystemDesignComponentType>>;

const useSystemDesignEntitySource = () => {
  const { t } = useTranslation();
  const componentType = useSystemDesignComponentType();
  return z.object({
    type: z.literal("SYSTEM_DESIGN"),
    uniqueKey: z.string(),
    questionId: z.number(),
    componentTypes: componentType.array().min(1, { message: t("少なくとも1つ以上のコンポーネントタイプを選択してください。") }),
    isSelectable: z.boolean(),
  });
};

export type SystemDesignEntitySource = z.infer<ReturnType<typeof useSystemDesignEntitySource>>;

const useProjectEntitySource = () => {
  return z.object({
    type: z.literal("PROJECT"),
    uniqueKey: z.string(),
    questionId: z.number(),
    questionVersion: z.string(),
    isSelectable: z.boolean(),
  });
};

export type ProjectEntitySource = z.infer<ReturnType<typeof useProjectEntitySource>>;

export const useRemoteSystemDesignEntitySource = () => {
  return z.object({
    type: z.literal("REMOTE_SYSTEM_DESIGN"),
    uniqueKey: z.string(),
    questionId: z.number(),
    isSelectable: z.boolean(),
  });
};
export type RemoteSystemDesignEntitySource = z.infer<ReturnType<typeof useRemoteSystemDesignEntitySource>>;

export const useRemoteAlgorithmEntitySource = () => {
  return z.object({
    type: z.literal("REMOTE_ALGORITHM"),
    uniqueKey: z.string(),
    questionId: z.number(),
    questionVersion: z.string(),
    isSelectable: z.boolean(),
  });
};
export type RemoteAlgorithmEntitySource = z.infer<ReturnType<typeof useRemoteAlgorithmEntitySource>>;

export const useTestEntitySource = () => {
  const quiz = useQuizEntitySource();
  const challenge = useChallengeEntitySource();
  const systemDesign = useSystemDesignEntitySource();
  const project = useProjectEntitySource();
  return z.union([quiz, challenge, systemDesign, project]);
};
export type TestEntitySource = z.infer<ReturnType<typeof useTestEntitySource>>;

export const useEntitySource = () => {
  const quiz = useQuizEntitySource();
  const challenge = useChallengeEntitySource();
  const systemDesign = useSystemDesignEntitySource();
  const project = useProjectEntitySource();
  const remoteSystemDesign = useRemoteSystemDesignEntitySource();
  const remoteAlgorithm = useRemoteAlgorithmEntitySource();

  return z.union([quiz, challenge, systemDesign, project, remoteSystemDesign, remoteSystemDesign, remoteAlgorithm]);
};

export type EntitySource = z.infer<ReturnType<typeof useEntitySource>>;
