import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { type QuizEventRecordService, type QuizEventRecordServiceListener, type QuizEventRecordState } from "@hireroo/app-helper/quiz";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as GraphqlClientRequest from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { shuffleOptions } from "./privateHelper";
import QuizFreeTextEditorContainer, { QuizFreeTextEditorContainerProps } from "./widget/QuizFreeTextEditorContainer/Container";

type QuestionListItemProps = Widget.QuizCodingEditorProps["questionListSelector"]["questionListItems"][0];

type QuizCardContentPropsArgs = {
  service: QuizEventRecordService;
  uid: string;
  quizId: number;
  question?: QuizCodingEditor.Question;
  state: QuizEventRecordState;
  disabled: boolean;
};

type QuizQuestionCardContentProps = Widget.QuizCodingEditorProps["quizQuestionCard"]["quizQuestionCardContent"];

const useQuizCardContentProps = (args: QuizCardContentPropsArgs): QuizQuestionCardContentProps => {
  const lang = useLanguageCode();
  const { question, service, state: firebaseState } = args;
  const shuffledOptions = React.useMemo(() => {
    return shuffleOptions(question?.options || []);
  }, [question?.options]);

  if (question && firebaseState.selectedQuestionId === question.id && service.selectedQuestionId === question.id) {
    if (question.variant === "SINGLE_CHOICE") {
      const selectedOptions = [...firebaseState.selectedOptions];
      return {
        kind: "SINGLE_CHOICE",
        content: {
          selectedValue: selectedOptions.at(0)?.toString() ?? "",
          description: resolveLanguage(question, lang, "description"),
          disabledAll: args.disabled,
          items: shuffledOptions.map(option => {
            return {
              title: resolveLanguage(option, lang, "title"),
              description: resolveLanguage(option, lang, "description"),
              value: `${option.id}`,
            };
          }),
          onChange: value => {
            service.selectSingleOption(question.id, Number(value));
          },
        },
      };
    }

    if (question.variant === "MULTI_CHOICE") {
      return {
        kind: "MULTI_CHOICE",
        content: {
          disabledAll: args.disabled,
          description: resolveLanguage(question, lang, "description"),
          items: shuffledOptions.map(option => {
            return {
              id: option.id,
              title: resolveLanguage(option, lang, "title"),
              description: resolveLanguage(option, lang, "description"),
              checked: firebaseState.selectedOptions.has(option.id),
            };
          }),
          skipped: firebaseState.selectedOptions.has(SKIP_OPTION_ID),
          onSelect: value => service.selectMultiOption(question.id, Number(value)),
          onUnselect: value => service.unselectMultiOption(question.id, Number(value)),
        },
      };
    }

    if (question.variant === "FREE_TEXT") {
      const editorProps: QuizFreeTextEditorContainerProps = {
        firepad: {
          entityId: args.quizId,
          uid: args.uid,
          displayName: "",
        },
        firebaseState,
        description: resolveLanguage(question, lang, "description"),
      };
      return {
        kind: "FREE_TEXT",
        Content: (
          <QuizFreeTextEditorContainer
            key={`${firebaseState.selectedQuizPackageId}-${firebaseState.selectedQuizPackageId}-${question.id}`}
            {...editorProps}
          />
        ),
      };
    }
  }

  // TODO: Please handle when question is undefined
  Sentry.captureException("Please check the kind of quiz question");
  return {
    kind: "LOADING",
    Content: <Widget.Loading kind="CENTER_%" />,
  };
};

type SubmissionStatus = "NONE" | "SUBMITTED" | "SKIP";

const SUBMISSION_OPTION_MAP: Record<SubmissionStatus, SubmissionStatus> = {
  NONE: "NONE",
  SKIP: "SKIP",
  SUBMITTED: "SUBMITTED",
};

export type GenerateQuizCodingEditorPropsArgs = {
  service: QuizEventRecordService;
  uid: string;
  entityId: number;
  state: QuizEventRecordState;
};

export const useGenerateProps = (args: GenerateQuizCodingEditorPropsArgs): Widget.QuizCodingEditorProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { service, state: firebaseState } = args;
  const lang = useLanguageCode();
  const client = GraphqlClientRequest.getGraphqlClient();
  const quizStatus = QuizCodingEditor.useQuizStatus();
  const { useSelectedQuestion, usePackage, useAnswerText, useSubmissionQuestionIdMap } = QuizCodingEditor.useCreateQuizEntityHooks(
    args.entityId,
  );
  const answerText = useAnswerText(firebaseState.selectedQuestionId);
  const quizEntityAction = QuizCodingEditor.createQuizEntityAction(args.entityId);
  const [disabledChangeQuestion, setDisabledChangeQuestion] = React.useState(false);
  const [connectingStatus, setConnectingStatus] = React.useState<"CONNECTING" | "CONNECTED">("CONNECTED");

  const quizPackage = usePackage();
  const submittedQuestionIdMap = useSubmissionQuestionIdMap();
  const question = useSelectedQuestion(firebaseState.selectedQuestionId);
  const quizCardContentProps = useQuizCardContentProps({
    uid: args.uid,
    quizId: args.entityId,
    question: question,
    service: service,
    state: firebaseState,
    disabled: connectingStatus !== "CONNECTED" || disabledChangeQuestion,
  });

  const nextQuestion = React.useMemo(() => {
    const questions = quizPackage?.questions || [];
    const nextQuestionIndex = questions.findIndex(question => question.id === firebaseState.selectedQuestionId) + 1;
    return questions.at(nextQuestionIndex);
  }, [firebaseState.selectedQuestionId, quizPackage?.questions]);

  const isFirstQuestion = React.useMemo(() => {
    const questions = quizPackage?.questions;
    if (!questions || questions.length <= 0) return false;
    return questions[0].id === firebaseState.selectedQuestionId;
  }, [firebaseState.selectedQuestionId, quizPackage?.questions]);

  const handleBackQuestion = React.useCallback(() => {
    const questions = quizPackage?.questions;
    const index = (questions || []).findIndex(question => question.id === firebaseState.selectedQuestionId);
    if (index > 0 && questions) {
      const prevQuestion = questions[index - 1];
      service.selectQuestion(prevQuestion.id);
    }
  }, [service, firebaseState.selectedQuestionId, quizPackage?.questions]);

  const calculateQuestionOrderText = React.useMemo(() => {
    if (quizPackage?.questions && question) {
      const nowQuestionIndex = quizPackage.questions.findIndex(all => {
        return `${all.id}-${all.version}` === `${question.id}-${question.version}`;
      });
      return `${t2("CurrentQuestionNumber", {
        number: nowQuestionIndex + 1,
      })} / ${t2("QuizQuestionNumbers", {
        number: quizPackage.questions.length,
      })}`;
    }
    return t("不明");
  }, [question, quizPackage?.questions, t, t2]);

  const submissionStatus = React.useCallback(
    (questionId: number, variant: QuizCodingEditor.Question["variant"]): SubmissionStatus => {
      const optionIds = submittedQuestionIdMap.get(questionId);
      if (!optionIds) return SUBMISSION_OPTION_MAP.NONE;
      if (optionIds.length === 0 && variant === "FREE_TEXT") return SUBMISSION_OPTION_MAP.SUBMITTED;
      if (optionIds.length === 1 && optionIds[0] === SKIP_OPTION_ID) return SUBMISSION_OPTION_MAP.SKIP;
      return SUBMISSION_OPTION_MAP.SUBMITTED;
    },
    [submittedQuestionIdMap],
  );

  const optionIds = React.useMemo((): number[] => {
    switch (question?.variant) {
      case "SINGLE_CHOICE":
        return Array.from(firebaseState.selectedOptions);
      case "MULTI_CHOICE":
        return Array.from(firebaseState.selectedOptions);
      case "FREE_TEXT":
        return [];
      default:
        return [];
    }
  }, [question, firebaseState.selectedOptions]);

  /**
   * 現在、選択中のoptionIdが表示されているoptionIdと位置しているか確認する
   * 一つでも違う場合はfalseを返す
   */
  const isIncludingValidSelectedQuestion = React.useMemo(() => {
    if (!question) {
      return false;
    }
    if (question.variant === "FREE_TEXT") {
      return true;
    }
    const displayedOptionIds = question.options.map(option => option.id);
    return Array.from(firebaseState.selectedOptions).every(selectedOptionId => {
      if (SKIP_OPTION_ID === selectedOptionId) {
        return true;
      }
      return displayedOptionIds.includes(selectedOptionId);
    });
  }, [firebaseState.selectedOptions, question]);

  const submitButtonDisabled = React.useMemo(() => {
    if (connectingStatus === "CONNECTING") {
      return true;
    }

    if (quizStatus.submit === "LOADING") {
      return true;
    }

    if (!question) {
      return true;
    }

    if (service.selectedQuestionId !== question.id) {
      return true;
    }

    if (firebaseState.selectedQuestionId !== question.id) {
      return true;
    }

    if (!isIncludingValidSelectedQuestion) {
      console.warn("Include Invalid selected question's optionIds.");
      return true;
    }

    if (question.variant === "SINGLE_CHOICE") {
      return firebaseState.selectedOptions.size === 0;
    }

    if (question.variant === "MULTI_CHOICE") {
      return firebaseState.selectedOptions.size === 0;
    }

    return false;
  }, [
    connectingStatus,
    isIncludingValidSelectedQuestion,
    quizStatus.submit,
    question,
    service.selectedQuestionId,
    firebaseState.selectedQuestionId,
    firebaseState.selectedOptions.size,
  ]);

  const backButtonDisabled = React.useMemo((): boolean => {
    if (!question) {
      return true;
    }
    if (connectingStatus === "CONNECTING") {
      return true;
    }
    if (quizStatus.submit === "LOADING") {
      return true;
    }
    if (service.selectedQuestionId !== question.id) {
      return true;
    }
    if (isFirstQuestion) {
      return true;
    }
    if (disabledChangeQuestion) {
      return true;
    }
    return false;
  }, [connectingStatus, disabledChangeQuestion, isFirstQuestion, question, quizStatus.submit, service.selectedQuestionId]);

  const submitQuestion = React.useCallback(() => {
    if (quizPackage && question) {
      QuizCodingEditor.setSubmitStatus("LOADING");
      setDisabledChangeQuestion(true);
      client
        .SubmitQuizQuestionForQuizCodingEditor({
          input: {
            quizId: args.entityId,
            packageId: quizPackage?.packageId,
            packageVersion: quizPackage.version,
            questionId: firebaseState.selectedQuestionId,
            questionVersion: question.version,
            optionIds: question.variant !== "FREE_TEXT" ? optionIds : [],
            answerText: question.variant === "FREE_TEXT" ? answerText : "",
          },
        })
        .then(res => {
          service.submitQuestion();
          quizEntityAction.setSubmittedQuestionIdWithOptionIds(
            res.submitQuizQuestion.questionId,
            question.variant !== "FREE_TEXT" ? optionIds : [],
          );

          if (nextQuestion) {
            // Clear option only when there is a screen transition
            service.selectQuestion(nextQuestion.id);
          } else {
            // When there are no nextQuestionId, it is going to move to different entity
            // and finally process won't subscribe change
            QuizCodingEditor.setSubmitStatus("READY");
          }
        })
        .catch(err => {
          Snackbar.notify({
            severity: "error",
            message: t("問題の提出が失敗しました。再度お試しください。"),
          });
          Sentry.captureException(err);
        })
        .finally(() => {
          QuizCodingEditor.setSubmitStatus("READY");
          setDisabledChangeQuestion(false);
        });
    }
  }, [
    quizPackage,
    question,
    client,
    args.entityId,
    firebaseState.selectedQuestionId,
    optionIds,
    answerText,
    quizEntityAction,
    nextQuestion,
    service,
    t,
  ]);

  const questionAnswerInfo = React.useMemo((): Widget.QuizCodingEditorProps["questionAnswerInfo"] => {
    const count = {
      notSubmitted: 0,
      skip: 0,
      submitted: 0,
    };

    for (const question of quizPackage?.questions || []) {
      const status = submissionStatus(question.id, question.variant);
      switch (status) {
        case "NONE": {
          count.notSubmitted += 1;
          break;
        }
        case "SKIP": {
          count.skip += 1;
          break;
        }
        case "SUBMITTED": {
          count.submitted += 1;
          break;
        }
        default:
          throw new Error(`Invalid submit status: ${status satisfies never}`);
      }
    }

    return {
      status: (() => {
        if (count.notSubmitted === 0 && count.skip === 0) {
          return "ALL_SUBMITTED";
        }
        if (count.skip > 0 && count.notSubmitted === 0) {
          return "REMAIN_SKIPPED_QUESTION";
        }
        return "NOT_SUBMITTED";
      })(),
      items: [
        {
          title: t("未提出"),
          value: count.notSubmitted.toString(),
        },
        {
          title: "SKIP",
          value: count.skip.toString(),
        },
        {
          title: t("提出済", {
            context: "quiz",
          }),
          value: count.submitted.toString(),
        },
      ],
    };
  }, [quizPackage?.questions, submissionStatus, t]);

  React.useEffect(() => {
    const callback: QuizEventRecordServiceListener["changeConnect"]["callback"] = payload => {
      switch (payload.status) {
        case "CONNECTED": {
          setConnectingStatus("CONNECTED");
          break;
        }
        case "CONNECTING": {
          setConnectingStatus("CONNECTING");
          break;
        }
        default:
          throw new Error(`Invalid status: ${payload.status satisfies never}`);
      }
    };
    const cleanup = service.on("changeConnect", callback);
    return () => {
      cleanup();
    };
  }, [service]);

  return {
    status: !question ? "LOADING" : "READY",
    codingToolbar: {},
    packageTitle: resolveLanguage(quizPackage || {}, lang, "title"),
    quizQuestionCard: {
      remainQuestions: calculateQuestionOrderText,
      quizQuestionCardContent: quizCardContentProps,
    },
    buttonGroup: {
      submitButton: {
        onClick: submitQuestion,
        disabled: submitButtonDisabled,
        children: t("提出して次の問題"),
        size: "medium",
      },
      backButton: {
        onClick: handleBackQuestion,
        disabled: backButtonDisabled,
        children: t("前の問題"),
        size: "medium",
      },
    },
    questionListSelector: {
      totalNum: quizPackage?.questions.length || 0,
      submittedNum: React.useMemo(() => {
        return (quizPackage?.questions || []).filter(question => submissionStatus(question.id, question.variant) === "SUBMITTED").length;
      }, [quizPackage?.questions, submissionStatus]),
      questionListItems: (quizPackage?.questions || []).map((question, index): QuestionListItemProps => {
        return {
          id: `${question.id}-${question.version}`,
          questionId: firebaseState.selectedQuestionId,
          title: resolveLanguage(question, lang, "title"),
          disabled: disabledChangeQuestion || connectingStatus === "CONNECTING",
          subTitle: t2("CurrentQuestionNumber", {
            number: index + 1,
          }),
          selected: question.id === firebaseState.selectedQuestionId,
          status: submissionStatus(question.id, question.variant),
          onClick: () => {
            service.selectQuestion(question.id);
          },
        };
      }),
    },
    questionAnswerInfo: questionAnswerInfo,
  };
};
