import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuizFreeTextEditorContainerPropsArgs, useGenerateProps } from "./useGenerateProps";

const { QuizFreeTextEditor } = QuizCodingEditor;

export type QuizFreeTextEditorContainerProps = GenerateQuizFreeTextEditorContainerPropsArgs;

const QuizFreeTextEditorContainer: React.FC<QuizFreeTextEditorContainerProps> = props => {
  React.useEffect(() => {
    QuizFreeTextEditor.initialize({
      quizId: props.firepad.entityId,
      packageId: props.firebaseState.selectedQuizPackageId,
      questionId: props.firebaseState.selectedQuestionId,
    });
  });

  const quizFreeTextEditorContainer = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuizFreeTextEditor {...quizFreeTextEditorContainer} />
    </ErrorBoundary>
  );
};

QuizFreeTextEditorContainer.displayName = "QuizFreeTextEditorContainer";

export default withErrorBoundary(QuizFreeTextEditorContainer, {});
