import { CodeEditorInputEvent } from "@hireroo/app-helper/playback";
import type { Mark } from "@hireroo/app-helper/remote";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { COMPONENT_TYPE, ComponentType } from "@hireroo/system-design/features";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useInterview = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) throw Error("need to initialize interivew");
  return snapshot.interview;
};

export const useActiveSessionId = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeSessionId;
};
export const useActiveVariant = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeVariant;
};

export const useActiveSessions = () => {
  const snapshot = useSnapshotState();
  return React.useMemo(() => Array.from(snapshot.activeSessionMap.values()), [snapshot.activeSessionMap]);
};

export const useNormalizedSessions = () => {
  const activeSessions = useActiveSessions();
  return React.useMemo(() => {
    return activeSessions.reduce<Types.NormalizedSession[]>((all, session) => {
      if (!session) {
        return all;
      }
      if (session.algorithmQuestion) {
        all.push({
          questionId: session.algorithmQuestion.questionId,
          questionVersion: session.algorithmQuestion.version,
          variant: session.algorithmQuestion.variant,
          liveCodingId: session.liveCodingId,
          liveCodingSessionId: session.liveCodingSessionId,
          titleJa: session.algorithmQuestion.titleJa,
          titleEn: session.algorithmQuestion.titleEn,
          descriptionJa: session.algorithmQuestion.descriptionJa,
          descriptionEn: session.algorithmQuestion.descriptionEn,
          difficulty: session.algorithmQuestion.difficulty,
          isArchived: session.algorithmQuestion.status === "ARCHIVED",
          answers: session.algorithmQuestion.answers.map(answer => {
            return {
              id: `algorithm-${answer.id}`,
              variant: "ALGORITHM",
              label: answer.label,
              titleJa: answer.titleJa,
              titleEn: answer.titleEn,
              descriptionJa: answer.descriptionJa,
              descriptionEn: answer.descriptionEn,
            };
          }),
        });
      } else if (session.systemDesignQuestion) {
        all.push({
          questionId: session.systemDesignQuestion.questionId,
          questionVersion: "",
          variant: "SYSTEM_DESIGN",
          liveCodingId: session.liveCodingId,
          liveCodingSessionId: session.liveCodingSessionId,
          titleJa: session.systemDesignQuestion.titleJa,
          titleEn: session.systemDesignQuestion.titleEn,
          descriptionJa: session.systemDesignQuestion.descriptionJa,
          descriptionEn: session.systemDesignQuestion.descriptionEn,
          difficulty: session.systemDesignQuestion.difficulty,
          isArchived: session.systemDesignQuestion.status === "ARCHIVED",
          answers: session.systemDesignQuestion.answers.map((answer, index) => {
            return {
              id: `system-design-${answer.id}`,
              variant: "SYSTEM_DESIGN",
              label: `${index + 1}`,
              titleJa: answer.titleJa,
              titleEn: answer.titleEn,
              descriptionJa: answer.descriptionJa,
              descriptionEn: answer.descriptionEn,
            };
          }),
        });
      }
      return all;
    }, []);
  }, [activeSessions]);
};

export const useNormalizedActiveSession = () => {
  const snapshot = useSnapshotState();
  const normalizedSessions = useNormalizedSessions();
  return React.useMemo(
    () => normalizedSessions.find(session => session.liveCodingSessionId === snapshot.activeSessionId),
    [normalizedSessions, snapshot.activeSessionId],
  );
};

export const useParticipantMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.participantMap;
};

export const useSortedParticipants = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.participantMap.values()).sort((a, b) => {
    // candidate < others
    if (a.userType === b.userType) return 0;
    return a.userType === "CANDIDATE" ? -1 : 1;
  });
};

export const useRefresh = (): Types.RefreshCallback => {
  const snapshot = useSnapshotState();
  return snapshot.refresh;
};

export const useHasPlaybackManager = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.challenge.playbackManagerMap.get(snapshot.selectedEditorOption);
};

export const useChallengeHistoriesInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.challenge.historiesStatus === "INITIALIZED";
};

export const useIsHistoriesInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.systemDesign.historiesStatus === "INITIALIZED";
};

export const usePlaybackManager = () => {
  const snapshot = useSnapshotState();
  const playbackManager = snapshot.challenge.playbackManagerMap.get(snapshot.selectedEditorOption);
  if (!playbackManager) throw new Error(`PlaybackManager not found, ${snapshot.selectedEditorOption}`);
  return playbackManager;
};

export const useCodeEditorInputEvents = () => {
  const playbackManager = usePlaybackManager();
  return React.useMemo((): (CodeEditorInputEvent | undefined)[] => {
    return playbackManager.ticks.map((tick): CodeEditorInputEvent | undefined => {
      /**
       * It should also include `undefined` to store the number of tick events.
       */
      const codeEditorInputEvent = tick.events.find(event => event.kind === "CODE_EDITOR") as CodeEditorInputEvent | undefined;
      return codeEditorInputEvent;
    });
  }, [playbackManager]);
};

export const useLastCodeEditorInputEventsIndex = () => {
  const codeEditorInputEvents = useCodeEditorInputEvents();
  return React.useMemo(() => {
    return codeEditorInputEvents.length - 1;
  }, [codeEditorInputEvents]);
};

export const useEditorOptions = () => {
  const snapshot = useSnapshotState();
  return snapshot.editorOptions;
};

export const useSelectedEditorOption = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEditorOption;
};

export const useSliderValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.sliderValue;
};

export const usePlayStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.playStatus;
};

export const useRuntimes = () => {
  const snapshot = useSnapshotState();
  return snapshot.editorOptions;
};
const isComponentType = (value: string): value is ComponentType => {
  return Object.values(COMPONENT_TYPE).includes(value as ComponentType);
};

export const useComponentTypes = (): DeepReadonly<ComponentType>[] => {
  const snapshot = useSnapshotState();
  return snapshot.editorOptions.reduce<DeepReadonly<ComponentType>[]>((all, option) => {
    if (isComponentType(option)) {
      all.push(option);
    }
    return all;
  }, []);
};

export const useChallengeRevisions = () => {
  const snapshot = useSnapshotState();
  return snapshot.challenge.histories[snapshot.selectedEditorOption];
};

export const useSystemDesignRevisions = () => {
  const snapshot = useSnapshotState();
  return snapshot.systemDesign.histories[snapshot.selectedEditorOption] as Types.SystemDesignRevisions | undefined;
};

export const useChallengeAuthorMap = (): Map<string, Mark[]> => {
  const revisions = useChallengeRevisions();
  return React.useMemo(() => {
    const map = new Map<string, Mark[]>();
    revisions?.forEach((history, i) => {
      // FIXME: declare schema validator
      if ("a" in history) {
        const values = map.get(history.a) ?? [];
        values.push({ value: i, timeStampInMilliseconds: history.t });
        map.set(history.a, values);
      }
    });
    return map;
  }, [revisions]);
};
