import { App, Auth, Company, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { ScreeningResourceEditor } from "@hireroo/app-store/widget/e/ScreeningResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../ErrorPanel/Container";
import ScreeningResourceEditorContainer, { ScreeningResourceEditorContainerProps } from "./Container";
import { candidateAccessPolicy, convertGraphqlEntitySourceToEntitySourceToValidator, languageMap } from "./privateHelper";

export type ScreeningResourceEditorFetchContainerProps = {
  screeningId: string;
} & Omit<ScreeningResourceEditorContainerProps, "minAcceptableLimit">;

type EntityTrack = Exclude<ScreeningResourceEditorContainerProps["defaultValues"], undefined>["testQuestionSetup"]["entityTracks"][0];

const ScreeningResourceEditorFetchContainer: React.FC<ScreeningResourceEditorFetchContainerProps> = props => {
  const currentUid = Auth.useCurrentUid();
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const enableProject = App.useEnableProject();
  const isAvailableQuestion = Payment.useIsAvailableQuestionToUse("test");

  const { mode } = props;
  const [result] = Graphql.useGetScreeningResourceEditorQuery({
    variables: {
      screeningId: props.screeningId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.screening.viewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "EmployeeGroup": {
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        }
        case "User": {
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: data?.screening.createdBy?.uid === currentUid,
            },
          });
        }
        case undefined:
          return all;
        default:
          throw new Error(`Invalid type: ${viewer satisfies never}`);
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  React.useEffect(() => {
    if (result.data) {
      const entitySourceQuestions = result.data.screening.entityTracks.reduce<QuestionSelectFieldForResourceEditor.EntitySourceQuestion[]>(
        (all, entityTrack) => {
          switch (entityTrack.__typename) {
            case "ScreeningFixedEntityTrack":
              if (entityTrack.entitySourceQuestion.__typename === "ScreeningProjectEntitySourceQuestion") {
                // コピーして新規作成の場合はスキップ。編集は復元するため、選択済みのentitySourceとして取り扱う
                if (!enableProject && mode === "CREATE") {
                  break;
                }
              }
              all.push(entityTrack.entitySourceQuestion);
              break;
            case "ScreeningRandomFromQuestionsEntityTrack":
            case "ScreeningSelectableFromQuestionsEntityTrack": {
              const entitySourceQuestions = entityTrack.entitySourceQuestions.filter(entitySourceQuestion => {
                if (entitySourceQuestion.__typename === "ScreeningProjectEntitySourceQuestion") {
                  // コピーして新規作成の場合はスキップ。編集は復元するため、選択済みのentitySourceとして取り扱う
                  return enableProject && mode === "CREATE";
                }
                return true;
              });
              all.push(...entitySourceQuestions);
              break;
            }
            default:
              throw new Error(`Invalid EntityTrack: ${entityTrack satisfies never}`);
          }
          return all;
        },
        [],
      );
      QuestionSelectFieldForResourceEditor.setQuestionByEntitySourceQuestions(entitySourceQuestions);
      /**
       * only when mode is EDIT, because when mode is CREATE, fetched spot is past created spot
       */
      if (mode === "EDIT") {
        ScreeningResourceEditor.updateTargetScreeningId(result.data.screening.screeningId);
      }
    }
  }, [enableProject, mode, result.data]);
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }
  const { data } = result;
  if (!data && props.mode === "CREATE") {
    return <ScreeningResourceEditorContainer {...props} mode="CREATE" />;
  }
  if (!data) {
    return <ErrorPanelContainer graphqlErrors={result.error?.graphQLErrors ?? []} mode="PERCENTAGE" />;
  }

  const entityTracks = (() => {
    const results: EntityTrack[] = [];
    for (const entityTrack of data.screening.entityTracks) {
      switch (entityTrack.__typename) {
        case "ScreeningFixedEntityTrack": {
          // コピーして新規作成の場合はスキップ。編集は復元するため、選択済みのentitySourceとして取り扱う
          if (!enableProject && mode === "CREATE" && entityTrack.entitySourceQuestion.__typename === "ScreeningProjectEntitySourceQuestion") {
            break;
          }
          results.push({
            type: "FIXED",
            entitySource: convertGraphqlEntitySourceToEntitySourceToValidator(entityTrack.entitySourceQuestion, isAvailableQuestion),
            questionScoreWeight: entityTrack.questionScoreWeight,
          });
          break;
        }
        case "ScreeningRandomFromQuestionsEntityTrack": {
          results.push({
            type: "RANDOM_FROM_QUESTIONS",
            entitySources: entityTrack.entitySourceQuestions.reduce<Fields.EntitySource.EntitySource[]>((all, entitySourceQuestion) => {
              // コピーして新規作成の場合はスキップ。編集は復元するため、選択済みのentitySourceとして取り扱う
              if (!enableProject && mode === "CREATE" && entitySourceQuestion.__typename === "ScreeningProjectEntitySourceQuestion") {
                return all;
              }
              const entitySource = convertGraphqlEntitySourceToEntitySourceToValidator(entitySourceQuestion, isAvailableQuestion);
              all.push(entitySource);
              return all;
            }, []),
            questionScoreWeight: entityTrack.questionScoreWeight,
          });
          break;
        }
        case "ScreeningSelectableFromQuestionsEntityTrack": {
          results.push({
            type: "SELECTABLE_FROM_QUESTIONS",
            entitySources: entityTrack.entitySourceQuestions.reduce<Fields.EntitySource.EntitySource[]>((all, entitySourceQuestion) => {
              // コピーして新規作成の場合はスキップ。編集は復元するため、選択済みのentitySourceとして取り扱う
              if (!enableProject && mode === "CREATE" && entitySourceQuestion.__typename === "ScreeningProjectEntitySourceQuestion") {
                return all;
              }
              const entitySource = convertGraphqlEntitySourceToEntitySourceToValidator(entitySourceQuestion, isAvailableQuestion);
              all.push(entitySource);
              return all;
            }, []),
            questionScoreWeight: entityTrack.questionScoreWeight,
          });
          break;
        }
        default:
          throw new Error(`Invalid EntityTrack: ${entityTrack satisfies never}`);
      }
    }
    return results;
  })();

  const containerProps: ScreeningResourceEditorContainerProps = {
    ...props,
    minAcceptableLimit: data.screening.numAccepted,
    defaultValues: {
      testQuestionSetup: {
        companyId: companyId,
        name: data.screening.name,
        description: data.screening.description,
        enabledChatGPT: data.screening.allowChatGPT,
        enabledWebSearch: data.screening.allowWebSearch,
        enabledHint: data.screening.allowHint,
        entityTracks: entityTracks,
        timeLimitMinutes: Math.round((data.screening.timeLimitSeconds ?? 0) / 60) || 5,
        timeLimitType: "CUSTOM",
      },
      testSetup: {
        customFields: data.screening.variables.map((variable): Fields.CustomField.CustomField => {
          switch (variable.variableField.__typename) {
            case "ScreeningIntegerFieldVariable": {
              return {
                label: variable.label,
                description: variable.description,
                formType: "INPUT_NUMBER",
                valueType: "INTEGER",
                validationRule: {
                  min: variable.variableField.validationRule.minIntegerValue ?? undefined,
                  max: variable.variableField.validationRule.maxIntegerValue ?? undefined,
                  required: variable.variableField.validationRule.required,
                },
              };
            }
            case "ScreeningStringFieldVariable": {
              return {
                label: variable.label,
                description: variable.description,
                formType: variable.variableField.formType === "INPUT_TEXT" ? "INPUT_TEXT" : "TEXTAREA",
                valueType: "STRING",
                validationRule: {
                  required: variable.variableField.validationRule.required,
                  minLength: variable.variableField.validationRule.minLength ?? undefined,
                  maxLength: variable.variableField.validationRule.maxLength ?? undefined,
                },
              };
            }
            case "ScreeningBooleanFieldVariable": {
              throw new Error(`Unexpected Field Variable: ${variable.variableField.__typename}`);
            }
            default: {
              throw new Error(`Variable is invalid: ${variable.variableField satisfies never}`);
            }
          }
        }),
        acceptableLimit: data.screening.acceptableLimit || null,
        deadlineDate: data.screening.deadlineSeconds ? new Date(data.screening.deadlineSeconds * 1000) : null,
        isActive: data.screening.isActive,
      },
      reportSetup: {
        memo: data.screening.memo || undefined,
        isPublic: data.screening.spotIsPublic,
        editorUid: currentUid ?? null,
        viewers: viewers,
        tags: data.screening.tags.map(t => ({ value: { name: t.name } })),
      },
      testInviteSetup: {
        candidateAccessPolicy: candidateAccessPolicy[data.screening.candidateAccessPolicy],
        messageForCandidate: data.screening.messageForCandidate || null,
        invitationLanguage: languageMap[data.screening.invitationLanguage],
        ownerEmailAddress: data.screening.ownerEmailAddress || null,
      },
    },
  };

  return <ScreeningResourceEditorContainer {...containerProps} />;
};

ScreeningResourceEditorFetchContainer.displayName = "ScreeningResourceEditorFetchContainer";

export default withErrorBoundary(ScreeningResourceEditorFetchContainer, {});
