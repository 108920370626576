import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

export type GenerateFollowUpQuestionsSectionPropsArgs = {
  challengeId: number;
};

export const useGenerateFollowUpQuestionsSectionProps = (
  args: GenerateFollowUpQuestionsSectionPropsArgs,
): Widget.ChallengeTestReportProps["followUpQuestionsSection"] => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();
  const submissionMap = challengeHooks.useSubmissionMap();
  const submission = submissionMap[submissionId];
  const lang = useLanguageCode();

  return {
    questions: (submission?.followUpQuestions ?? []).map(followUpQuestion => ({
      question: resolveLanguage(followUpQuestion, lang, "question"),
      answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
      purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
    })),
  };
};
