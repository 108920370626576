import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import * as QuestionSelectFieldV2Subscriber from "../QuestionSelectFieldV2/Subscriber";
import RemoteTemplateResourceEditorContainer, { RemoteTemplateResourceEditorContainerProps } from "./Container";

export type RemoteTemplateResourceEditorMiddleContainerProps = RemoteTemplateResourceEditorContainerProps & {
  defaultValues?: Widget.RemoteTemplateResourceEditorProviderProps["defaultValues"];
};

const RemoteTemplateResourceEditorMiddleContainer: React.FC<RemoteTemplateResourceEditorMiddleContainerProps> = props => {
  const containerProps: RemoteTemplateResourceEditorContainerProps = {
    ...props,
  };

  const providerProps: Widget.RemoteTemplateResourceEditorProviderProps = {
    defaultValues: props.defaultValues ?? {
      remote: {
        name: "",
        candidateName: "",
        memo: null,
        isPublic: true,
        editorUid: null,
        viewers: [],
        tags: [],
      },
      entityTracks: [],
      templateTitle: "",
      templateDescription: null,
      variables: null,
    },
  };

  React.useEffect(() => {
    const cleanup1 = QuestionSelectFieldV2Subscriber.startSubscribeSelectedQuestions({ target: "remote", enableProject: false });
    return () => {
      cleanup1();
    };
  }, []);

  return (
    <Widget.RemoteTemplateResourceEditorProvider {...providerProps}>
      <RemoteTemplateResourceEditorContainer {...containerProps} />
    </Widget.RemoteTemplateResourceEditorProvider>
  );
};

RemoteTemplateResourceEditorMiddleContainer.displayName = "RemoteTemplateResourceEditorMiddleContainer";

export default withErrorBoundary(RemoteTemplateResourceEditorMiddleContainer, {});
