import { useSnapshot } from "valtio";

import * as Def from "./definitions";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useListQuestionsResponse = () => {
  const snapshot = useSnapshotState();
  return snapshot.listQuestionsResponse;
};

export const useCurrentQuestionSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.questionSearchQuery.searchFilter;
};

export const useQuestionsPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.questionSearchQuery.pager;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return Def.SortParams[snapshot.questionSearchQuery.pager.sortFieldValue];
};

export const useQuestionMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.questionMap;
};

export const useRecommendTestTime = () => {
  const THRESHOLD_MINUTES = 5;
  const { questionMap, selectedUniqueKeysByEntityTracks } = useSnapshotState();
  const questionNum = selectedUniqueKeysByEntityTracks.length;
  const totalTimeLimitSeconds = selectedUniqueKeysByEntityTracks.reduce<number>((totalTimelimitSeconds, selectedEntityTrack) => {
    const timelimitSecondsList = selectedEntityTrack.uniqueKeys.map((uniqueKey): number => {
      const question = questionMap.get(uniqueKey);
      if (question && "timeLimitSeconds" in question) {
        return question.timeLimitSeconds ?? 0;
      }
      return 0;
    });
    const maxTimelimitSeconds = Math.max(...timelimitSecondsList);
    return totalTimelimitSeconds + maxTimelimitSeconds;
  }, THRESHOLD_MINUTES);

  const totalTimeLimitMinutes = Math.round(totalTimeLimitSeconds / 60);
  const bufferTimeMinutes = questionNum * THRESHOLD_MINUTES;

  const minimumMinutes = totalTimeLimitMinutes - bufferTimeMinutes;
  /**
   * Calculate from TIGHT time not to fall below threshold
   */
  const tightMinutes = minimumMinutes > THRESHOLD_MINUTES ? minimumMinutes : THRESHOLD_MINUTES;
  return {
    tight: tightMinutes,
    recommend: tightMinutes + bufferTimeMinutes,
    relax: tightMinutes + 2 * bufferTimeMinutes,
  };
};

export const useFetchQuestionsStatus = () => {
  const { fetchQuestionsStatus } = useSnapshotState();
  return fetchQuestionsStatus;
};

export const useFetchQuestionPackagesStatus = () => {
  const { fetchQuestionPackagesStatus } = useSnapshotState();
  return fetchQuestionPackagesStatus;
};

export const useSelectedListToDisplay = () => {
  const { listToDisplay } = useSnapshotState();
  return listToDisplay;
};

export const useListQuestions = () => {
  const { listToDisplay, listQuestionsResponse } = useSnapshotState();
  if (listToDisplay.kind === "ALL_QUESTIONS") {
    return listQuestionsResponse?.questions ?? [];
  }
  return [];
};

export const useQuestionSelectState = () => {
  const { questionSelectState } = useSnapshotState();
  return questionSelectState;
};

export const useSkillTagRes = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.res;
};

export const useCurrentSkillTags = () => {
  const snapshot = useSnapshotState();
  const skillTagIds = Array.from(snapshot.skillTagState.currentSkillTagIdsSet);
  if (skillTagIds.length === 0 && snapshot.skillTagState.textFilter.length > 0) {
    return [];
  }
  if (skillTagIds.length === 0) {
    return Array.from(snapshot.skillTagState.skillTagSources.values());
  }
  return skillTagIds.reduce<Types.SkillTag[]>((all, skillTagId) => {
    const skillTag = snapshot.skillTagState.skillTagSources.get(skillTagId);
    if (skillTag) {
      all.push(skillTag);
    }
    return all;
  }, []);
};
export const useSkillTagSources = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.skillTagState.skillTagSources.values());
};

export const useSkillTagTextFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.textFilter;
};

export const useSkillTagNextOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.nextOffset;
};
