import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company, Payment, Role } from "@hireroo/app-store/essential/employee";
import { App } from "@hireroo/app-store/essential/shared";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { SupportLanguage, useChangeLanguage, useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { useMemo } from "react";

import EmployeeMenuContainer from "../EmployeeMenu/Container";
import { useGenerateNotificationButtonProps } from "./useGenerateNotificationButtonProps";

export type GenerateEmployeeNavigationPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (args: GenerateEmployeeNavigationPropsArgs): Widget.EmployeeNavigationProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const companies = Company.useCompanies();
  const language = useCurrentLanguage();
  const currentUser = Auth.useCurrentUser();
  const changeLanguage = useChangeLanguage();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const enableTalentScore = App.useEnableTalentScore();
  const role = Role.useCurrentRole();

  const isAvailableFeature = Payment.useIsAvailableFeature();

  const notificationButtonProps = useGenerateNotificationButtonProps(args);

  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: currentUser.uid,
            displayName: currentUser.displayName,
            language: lang,
            photoUrl: currentUser.photoUrl,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateUser.language]);
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          Auth.setUser(res.updateUser);
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [changeLanguage, client, currentUser.displayName, currentUser.photoUrl, currentUser.uid, t],
  );

  const rightMenuProps: Widget.EmployeeNavigationProps["rightMenu"] = useMemo(() => {
    return {
      options: [
        {
          text: "日本語",
          value: SupportLanguage.JA,
          onClick: () => {
            changeLanguage(SupportLanguage.JA);
            updateLanguage(languageMap[SupportLanguage.JA]);
          },
          default: language === SupportLanguage.JA,
        },
        {
          text: "English",
          value: SupportLanguage.EN,
          onClick: () => {
            changeLanguage(SupportLanguage.EN);
            updateLanguage(languageMap[SupportLanguage.EN]);
          },
          default: language === SupportLanguage.EN,
        },
      ],
      role: "language",
      menuId: "select-language-menu",
    };
  }, [changeLanguage, language, updateLanguage]);

  return {
    brandLogo: {
      href: generatePath("/e/home"),
      onClick: () => {
        navigate("/e/home");
      },
    },
    navigationLinks: [
      isAvailableFeature("interview.read")
        ? {
            children: t("インタビュー一覧"),
            href: generatePath("/e/remotes"),
            onClick: () => navigate("/e/remotes"),
          }
        : undefined,
      {
        children: t("コーディングテスト一覧"),
        href: generatePath("/e/screenings"),
        onClick: () => navigate("/e/screenings"),
      },
      {
        children: t("候補者一覧"),
        href: generatePath("/e/screenings/tests"),
        onClick: () => navigate("/e/screenings/tests"),
      },
      {
        children: t("問題一覧"),
        href: generatePath("/e/questions"),
        onClick: () => navigate("/e/questions"),
      },
      enableTalentScore
        ? {
            children: t("タレントスコア"),
            href: generatePath("/e/assessments"),
            onClick: () => navigate("/e/assessments"),
          }
        : undefined,
    ].flatMap(v => (v ? [v] : [])),
    leftMenu: {
      options: companies.map(company => ({
        default: company.companyId === activeCompanyId,
        text: company.name,
        value: company.companyId.toString(),
        onClick: async () => {
          const res = await client.SwitchActiveCompanyForEmployeeV2({
            input: {
              companyId: company.companyId,
              employeeId: uid,
              role,
            },
          });
          if (res.switchActiveCompanyForEmployeeV2) {
            Company.updateActiveCompanyId(company.companyId);
          }
        },
      })),
      role: "company",
      menuId: "select-company-menu",
    },
    rightMenu: rightMenuProps,
    notificationButton: notificationButtonProps,
    EmployeeMenu: <EmployeeMenuContainer />,
  };
};
