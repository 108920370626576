import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import QuizCodingEditorFetchContainer, { QuizCodingEditorFetchContainerProps } from "./FetchContainer";
import * as Subscriber from "./Subscriber";

export type QuizCodingEditorInitialContainerProps = QuizCodingEditorFetchContainerProps;

const QuizCodingEditorInitialContainer: React.FC<QuizCodingEditorInitialContainerProps> = props => {
  React.useEffect(() => {
    const stopSubscribeAnswerText = Subscriber.startSubscribeAnswerText();
    return () => {
      stopSubscribeAnswerText();
    };
  }, []);

  const initialized = QuizCodingEditor.useInitialized(props.entityId);

  React.useEffect(() => {
    if (initialized) {
      ScreeningTestTutorial.autoStartTutorial("QUIZ");
    }
    return () => {
      ScreeningTestTutorial.clear();
    };
  }, [initialized]);
  if (!initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <QuizCodingEditorFetchContainer key={`${props.entityId}-${props.packageId}`} {...props} />
    </ErrorBoundary>
  );
};

QuizCodingEditorInitialContainer.displayName = "QuizCodingEditorInitialContainer";

export default withErrorBoundary(QuizCodingEditorInitialContainer, {});
