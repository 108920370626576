import { DetailErrorCode, ServerErrorCode } from "@hireroo/graphql/types";
import { useTranslation } from "@hireroo/i18n";

type PickedErrorMessageObject = {
  name: string;
  message: string;
  additionalMessage?: string;
};

export type ErrorObject = {
  serverErrorCode: ServerErrorCode;
  detailErrorCode?: DetailErrorCode;
};

type Mode = "FOR_CANDIDATE" | "FOR_EMPLOYEE";

export const useDetailErrorCodeMap = (mode: Mode): Record<DetailErrorCode, PickedErrorMessageObject> => {
  const { t } = useTranslation();
  const permissionErrorMessageMap: Record<Mode, string> = {
    FOR_EMPLOYEE: t("閲覧権限を持っているユーザーに閲覧権限をリクエストしてください。"),
    FOR_CANDIDATE: t("現在アクセス中のURLが有効なリンクか確認してください。"),
  };
  const permissionErrorMessage = permissionErrorMessageMap[mode];
  return {
    "ASSESSMENT.SERVICE_UNAVAILABLE": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "ASSESSMENT.NOT_FOUND": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "ASSESSMENT.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "ASSESSMENT.PERMISSION_DENIED": {
      name: t("閲覧権限がありません"),
      message: permissionErrorMessage,
    },
    "ASSESSMENT.UNKNOWN": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "ASSESSMENT.ALREADY_EXISTS": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "SPOT.SERVICE_UNAVAILABLE": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "SPOT.NOT_FOUND": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "SPOT.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "SPOT.PERMISSION_DENIED": {
      name: t("閲覧権限がありません"),
      message: permissionErrorMessage,
    },
    "SPOT.UNKNOWN": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "SPOT.ALREADY_EXISTS": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "REMOTE.SERVICE_UNAVAILABLE": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "REMOTE.NOT_FOUND": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "REMOTE.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "REMOTE.PERMISSION_DENIED": {
      name: t("閲覧権限がありません"),
      message: permissionErrorMessage,
    },
    "REMOTE.UNKNOWN": {
      name: t("テストの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "REMOTE.ALREADY_EXISTS": {
      name: t("テストの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "RESUME.SERVICE_UNAVAILABLE": {
      name: t("書類選考の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "RESUME.NOT_FOUND": {
      name: t("書類選考の取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    "RESUME.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "RESUME.PERMISSION_DENIED": {
      name: t("この機能を利用する権限がありません。管理者に権限を付与してもらうことで利用可能になります。"),
      message: permissionErrorMessage,
    },
    "RESUME.UNKNOWN": {
      name: t("書類選考の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "RESUME.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "DEMO.SERVICE_UNAVAILABLE": {
      name: t("テスト（デモ）を取得することができませんでした。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "DEMO.NOT_FOUND": {
      name: t("テスト（デモ）を取得することができませんでした。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "DEMO.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "DEMO.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "DEMO.UNKNOWN": {
      name: t("テスト（デモ）を取得することができませんでした。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "DEMO.ALREADY_EXISTS": {
      name: t("テスト（デモ）を取得することができませんでした。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TEMPLATE.SERVICE_UNAVAILABLE": {
      name: t("テストテンプレートの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TEMPLATE.NOT_FOUND": {
      name: t("テストテンプレートの取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TEMPLATE.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "TEMPLATE.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TEMPLATE.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TEMPLATE.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "COMPANY.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "COMPANY.NOT_FOUND": {
      name: t("会社情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "COMPANY.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "COMPANY.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "COMPANY.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "COMPANY.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC.NOT_FOUND": {
      name: t("評価指標情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "EVALUATION_METRIC.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "EVALUATION_METRIC.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC_GROUP.NOT_FOUND": {
      name: t("評価指標情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC_GROUP.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC_GROUP.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "EVALUATION_METRIC_GROUP.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "EVALUATION_METRIC_GROUP.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "EVALUATION_METRIC_GROUP.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "ALGORITHM.NOT_FOUND": {
      name: t("コーディング形式の問題が見つかりませんでした。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "ALGORITHM.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "ALGORITHM.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "ALGORITHM.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "ALGORITHM.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "ALGORITHM.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TRANSLATION.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TRANSLATION.NOT_FOUND": {
      name: t("評価指標情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TRANSLATION.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "TRANSLATION.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "TRANSLATION.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "TRANSLATION.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "MULTI_CHOICE.NOT_FOUND": {
      name: t("クイズ形式の問題取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "MULTI_CHOICE.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "MULTI_CHOICE.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "MULTI_CHOICE.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "MULTI_CHOICE.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "MULTI_CHOICE.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "QUIZ.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "QUIZ.NOT_FOUND": {
      name: t("クイズ形式の問題取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "QUIZ.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "QUIZ.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "QUIZ.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "QUIZ.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "VIDEO_CHAT.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "VIDEO_CHAT.NOT_FOUND": {
      name: t("ビデオ通話の接続失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "VIDEO_CHAT.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "VIDEO_CHAT.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "VIDEO_CHAT.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "VIDEO_CHAT.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "PAYMENT.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "PAYMENT.NOT_FOUND": {
      name: t("請求情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "PAYMENT.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "PAYMENT.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "PAYMENT.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "PAYMENT.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SECURITY.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SECURITY.NOT_FOUND": {
      name: t("セキュリティ情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SECURITY.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "SECURITY.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SECURITY.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "SECURITY.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SELECTION.ALREADY_EXISTS": {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SELECTION.NOT_FOUND": {
      name: t("選考情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SELECTION.PERMISSION_DENIED": {
      name: t("アクセスする権限がありません。"),
      message: permissionErrorMessage,
    },
    "SELECTION.SERVICE_UNAVAILABLE": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    "SELECTION.UNAUTHENTICATED": {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    "SELECTION.UNKNOWN": {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
  };
};

export const useServerErrorMessageMap = (): Record<ServerErrorCode, PickedErrorMessageObject> => {
  const { t } = useTranslation();
  return {
    NOT_FOUND: {
      name: t("リソースの取得に失敗しました。"),
      message: t("アクセスしたURLが間違っているか、すでに削除されている可能性があります。お手数ですがホーム画面に戻って再度操作をして下さい。"),
    },
    SERVICE_UNAVAILABLE: {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    UNKNOWN: {
      name: t("エラーが発生しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
    UNAUTHENTICATED: {
      name: t("認証に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    PERMISSION_DENIED: {
      name: t("アクセスする権限がありません。"),
      message: t("しばらくしてから再度お試しいただくか、一度サインアウトしてからお試し下さい。"),
    },
    ALREADY_EXISTS: {
      name: t("すでにリソースが存在しています。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    },
  };
};
