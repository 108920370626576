import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.quizzes.has(entityId);
};

export const useQuizStatus = () => {
  const snapshot = useSnapshotState();
  return {
    submit: snapshot.submitStatus,
  };
};

export const useCreateQuizEntityHooks = (entityId: Types.QuizEntityId) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.quizzes.get(entityId);

  if (!entityState) {
    throw new Error(`Please initialize entityId=${entityId}`);
  }

  const useEntity = () => {
    return entityState.entity;
  };

  const useService = () => {
    return entityState.service;
  };

  const usePackage = () => {
    return entityState.package;
  };

  // TODO: You can remove after design renewal.
  const useSubmittedQuestionIdSet = () => {
    return entityState.submittedQuestionId;
  };

  const useAnswerText = (questionId: number) => {
    return entityState.answerText.get(questionId) || "";
  };

  const useSelectedQuestion = (questionId: number) => {
    const version = entityState.questionIdVersionMap.get(questionId);
    if (version) {
      return entityState.questions.get(`${questionId}-${version}`);
    }
  };

  const useSubmissionQuestionIdMap = () => {
    return entityState.submittedQuestionIdOptionMap;
  };

  const useIpAddress = () => {
    const snapshot = useSnapshotState();
    return snapshot.ipAddress;
  };

  const useGeolocation = () => {
    const snapshot = useSnapshotState();
    return snapshot.geolocation;
  };

  return {
    useEntity,
    usePackage,
    useAnswerText,
    useSelectedQuestion,
    useSubmittedQuestionIdSet,
    useSubmissionQuestionIdMap,
    useIpAddress,
    useGeolocation,
    useService,
  };
};
