import { useAlgorithmVariantLabelMap, useProjectVariantLabelMap, useQuestionTypeLabelMap } from "@hireroo/app-helper/question";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

type EntitySources = Widget.QuestionDetailEditableListProps["entitySources"];

export type GenerateQuestionDetailEditableListPropsArgs = {
  name: Widget.QuestionDetailEditableListProps["name"];
};

export const useGenerateProps = (args: GenerateQuestionDetailEditableListPropsArgs): Widget.QuestionDetailEditableListProps => {
  const questions = QuestionSelectFieldForResourceEditor.useQuestionMap();
  const lang = useLanguageCode();
  const componentTypeMap = useComponentTypesMap();
  const projectVariantLabelMap = useProjectVariantLabelMap();
  const questionTypeLabelMap = useQuestionTypeLabelMap();
  const algorithmVariantLabelMap = useAlgorithmVariantLabelMap();
  const questionSearchAndSelectableAreaContext = Widget.useQuestionSearchAndSelectableAreaContext();
  const entitySources = React.useMemo((): EntitySources => {
    return Array.from(questions.keys()).reduce<EntitySources>((entitySources, uniqueKey) => {
      const question = questions.get(uniqueKey);
      if (!question) {
        return entitySources;
      }
      const title = [resolveLanguage(question, lang, "title"), `(${PrivateHelper.getVersion(question)})`].join(" ");
      switch (question.__typename) {
        case "SystemDesignQuestion": {
          const componentTypes: Graphql.SystemDesignComponentType[] = [
            Graphql.SystemDesignComponentType.Default,
            Graphql.SystemDesignComponentType.Aws,
            Graphql.SystemDesignComponentType.Gcp,
            Graphql.SystemDesignComponentType.Azure,
          ];
          entitySources[uniqueKey] = {
            title: title,
            name: `${args.name}.${uniqueKey}`,
            variant: questionTypeLabelMap.SystemDesignQuestion,
            options: componentTypes.map(componentType => {
              return {
                displayName: componentTypeMap[componentType],
                value: componentType,
              };
            }),
          };
          break;
        }
        case "MultiChoicePackage": {
          entitySources[uniqueKey] = {
            title: title,
            name: `${args.name}.${uniqueKey}`,
            variant: questionTypeLabelMap.MultiChoicePackage,
          };
          break;
        }
        case "AlgorithmQuestion": {
          const variantText = [questionTypeLabelMap["AlgorithmQuestion"], algorithmVariantLabelMap[question.algorithmQuestionVariant]]
            .filter(Boolean)
            .join(" - ");
          entitySources[uniqueKey] = {
            title: title,
            name: `${args.name}.${uniqueKey}`,
            variant: variantText,
            options: question.supportedLanguages.map(language => {
              return {
                displayName: languageMapForDisplay[language],
                value: language,
              };
            }),
          };
          break;
        }
        case "ProjectQuestion": {
          const variantText = [questionTypeLabelMap.ProjectQuestion, projectVariantLabelMap[question.projectQuestionVariant]]
            .filter(Boolean)
            .join(" - ");
          entitySources[uniqueKey] = {
            title: title,
            name: `${args.name}.${uniqueKey}`,
            variant: variantText,
          };
          break;
        }
      }

      return entitySources;
    }, {});
  }, [questions, lang, args.name, questionTypeLabelMap, componentTypeMap, algorithmVariantLabelMap, projectVariantLabelMap]);

  return {
    name: args.name,
    entitySources: entitySources,
    onClickSelectQuestionsButton: (type, index, entitySources) => {
      switch (type) {
        case "FIXED":
          break;
        case "RANDOM_FROM_QUESTIONS": {
          questionSearchAndSelectableAreaContext.updateSelectedEntitySources(entitySources);
          QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
            mode: "EDIT_RANDOM",
            openQuestionSelectDialog: true,
            selectedEntityTrackFieldIndex: index,
          });
          break;
        }
        case "SELECTABLE_FROM_QUESTIONS": {
          questionSearchAndSelectableAreaContext.updateSelectedEntitySources(entitySources);
          QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
            mode: "EDIT_SELECTABLE",
            openQuestionSelectDialog: true,
            selectedEntityTrackFieldIndex: index,
          });
          break;
        }
        default:
          throw new Error(`Unsupported type: ${type satisfies never}`);
      }
    },
  };
};
