import * as Def from "./definitions";
import { SKILL_TAG_SIZE } from "./definitions";
import { state } from "./State";
import type * as Types from "./types";

export const addQuestions = (questions: Types.QuestionObject[]) => {
  questions.forEach(questionObject => {
    const question = questionObject.spotQuestion;
    if (question) {
      state.questionMap.set(question.key, question);
    }
    const liveCodingQuestion = questionObject.liveCodingQuestion;
    if (liveCodingQuestion) {
      state.questionMap.set(liveCodingQuestion.key, liveCodingQuestion);
    }
  });
};

export const setListQuestionsResponse = (res: Types.ListQuestionsResponse): void => {
  state.listQuestionsResponse = res;
  addQuestions(res.questions);
};

export const setQuestion = (questions: Types.Question[]) => {
  questions.forEach(question => {
    state.questionMap.set(question.key, question);
  });
};

export const setQuestionByEntitySourceQuestions = (entitySourceQuestions: Types.EntitySourceQuestion[]) => {
  entitySourceQuestions.forEach(entitySourceQuestion => {
    switch (entitySourceQuestion.__typename) {
      case "ScreeningChallengeEntitySourceQuestion":
      case "ScreeningProjectEntitySourceQuestion":
      case "ScreeningSystemDesignEntitySourceQuestion":
        state.questionMap.set(entitySourceQuestion.question.key, entitySourceQuestion.question);
        break;
      case "ScreeningQuizEntitySourcePackage":
        state.questionMap.set(entitySourceQuestion.pb_package.key, entitySourceQuestion.pb_package);
        break;
      /**
       * for live coding
       */
      case "AlgorithmQuestion":
      case "SystemDesignQuestion":
        state.questionMap.set(entitySourceQuestion.key, entitySourceQuestion);
        break;
    }
  });
};

export const clear = (): void => {
  state.selectedUniqueKeysByEntityTracks = [];
  state.questionSearchQuery = {
    searchFilter: {
      questionVariants: [],
      statuses: [],
      difficulties: [],
      textFilter: "",
      skillTags: [],
      leakScoreLevels: [],
    },
    pager: {
      ...Def.DefaultListQuestionsPager,
    },
  };
  state.questionPackageSearchQuery = {
    searchFilter: {
      difficulties: [],
      textFilter: "",
    },
    pager: {
      ...Def.DefaultListQuestionPackagesPager,
    },
  };

  /** Reset page when search criteria are changed */
  state.listQuestionsResponse = null;
  state.fetchQuestionsStatus = "READY";
  state.fetchQuestionPackagesStatus = "READY";
  state.listToDisplay = {
    kind: "ALL_QUESTIONS",
  };
  state.reachedIndexCache = null;
  clearCurrentSkillTagState();
};

export const updateCurrentQuestionSearchFilter = (filter: Types.QuestionSearchFilter) => {
  state.questionSearchQuery = {
    searchFilter: filter,
    /** Reset page when search criteria are changed */
    pager: {
      ...state.questionSearchQuery.pager,
      page: 0,
      offset: 0,
    },
  };
};

export const updateCurrentQuestionPackagesSearchFilter = (filter: Types.QuestionPackagesSearchFilter) => {
  state.questionPackageSearchQuery = {
    searchFilter: filter,
    pager: {
      ...state.questionPackageSearchQuery.pager,
      page: 0,
      offset: 0,
    },
  };
  state.reachedIndexCache = null;
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.questionSearchQuery = {
    searchFilter: state.questionSearchQuery.searchFilter,
    pager: {
      ...state.questionSearchQuery.pager,
      sortFieldValue: sortFieldValue,
      page: 0,
      offset: 0,
    },
  };
};

export const updateListQuestionPage = (page: number) => {
  state.questionSearchQuery = {
    ...state.questionSearchQuery,
    pager: {
      ...state.questionSearchQuery.pager,
      page,
      offset: page * state.questionSearchQuery.pager.size,
    },
  };
};

export const updateListQuestionPageSize = (size: number) => {
  state.questionSearchQuery = {
    ...state.questionSearchQuery,
    pager: {
      ...state.questionSearchQuery.pager,
      page: 0,
      offset: 0,
      size: size,
    },
  };
};

export const initializeListQuestionPager = (pager: Omit<Types.Pager, "offset">) => {
  state.questionSearchQuery.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const updateSelectedUniqueKeys = (selectedEntityTracks: Types.SelectedUniqueKeysByEntityTrack[]) => {
  if (state.selectedUniqueKeysByEntityTracks.length === selectedEntityTracks.length) {
    return;
  }
  state.selectedUniqueKeysByEntityTracks = selectedEntityTracks;
};

export const updateFetchQuestionsStatus = (fetchStatus: Types.FetchStatus) => {
  state.fetchQuestionsStatus = fetchStatus;
};

export const updateFetchQuestionPackagesStatus = (fetchStatus: Types.FetchStatus) => {
  state.fetchQuestionPackagesStatus = fetchStatus;
  if (fetchStatus === "READY") {
    state.reachedIndexCache = null;
  }
};

export const forceEmitSubscribeQuestionSearchFilterEvent = () => {
  state.questionSearchQuery = {
    ...state.questionSearchQuery,
  };
};

export const forceEmitSubscribeQuestionPackagesSearchFilterEvent = () => {
  state.questionPackageSearchQuery = {
    ...state.questionPackageSearchQuery,
  };
};

export const selectListToDisplay = (listToDisplay: Types.ListToDisplay) => {
  state.listToDisplay = listToDisplay;
};

export const setOpenQuestionSelectDialog = (newState: Types.QuestionSelectState) => {
  state.questionSelectState = newState;
};

export const updateSkillTags = (res: Types.SkillTagRes) => {
  state.skillTagState.res = res;

  res.skillTagNodes.forEach(skillTag => {
    state.skillTagState.currentSkillTagIdsSet.add(skillTag.skillTagNodeId);
    state.skillTagState.skillTagSources.set(skillTag.skillTagNodeId, skillTag);
  });
};

export const updateSkillTagTextFilter = (textFilter: string) => {
  state.skillTagState.textFilter = textFilter;
};

export const updateSkillTagNextOffset = () => {
  state.skillTagState.nextOffset += SKILL_TAG_SIZE;
};

export const clearCurrentSkillTagState = () => {
  state.skillTagState.res = null;
  state.skillTagState.currentSkillTagIdsSet.clear();
  state.skillTagState.nextOffset = 0;
  state.skillTagState.textFilter = "";
};
