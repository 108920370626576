import { useTranslation } from "@hireroo/i18n";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../TestReportEvaluationItem/TestReportEvaluationItem";
import IpAddressDetectionReport, { IpAddressDetectionReportProps } from "./parts/IpAddressDetectionReport/IpAddressDetectionReport";
import SimilarCodes, { SimilarCodesProps } from "./parts/SimilarCodes/SimilarCodes";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon">;

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  marginBottom: "24px",
}));

export type ReportCheatDetectionSectionProps = {
  numTabEvents?: Item;
  numPasteEvents?: Item & { onClick?: () => void };
  numAccessEvents?: Item;
  similarityScore?: Item;
  similarCodes?: SimilarCodesProps;
  ipAddressSection: IpAddressDetectionReportProps | null;
  canShowTooltip: boolean;
};

const ReportCheatDetectionSection: React.FC<ReportCheatDetectionSectionProps> = props => {
  const { t } = useTranslation();

  const pastedEventsItem: TestReportEvaluationItemProps | undefined = props.numPasteEvents && {
    ...props.numPasteEvents,
    title: t("ペースト回数"),
    canShowTooltip: props.canShowTooltip,
    AdditionalContent: props.numPasteEvents.onClick && (
      <IconButtonWithTooltip onClick={props.numPasteEvents.onClick} title={t("提出コードからどこでペーストがあったか確認することができます。")}>
        <PlayCircleOutlinedIcon fontSize="small" color="secondary" />
      </IconButtonWithTooltip>
    ),
  };

  const leftSections: TestReportEvaluationItemProps[] = [
    pastedEventsItem,
    props.numAccessEvents && {
      ...props.numAccessEvents,
      title: t("IPアドレス検知回数"),
      canShowTooltip: props.canShowTooltip,
    },
  ].filter(value => !!value);

  const rightSections: TestReportEvaluationItemProps[] = [
    props.numTabEvents && {
      ...props.numTabEvents,
      title: t("離脱回数"),
      canShowTooltip: props.canShowTooltip,
    },
    props.similarityScore && {
      ...props.similarityScore,
      title: t("類似コード"),
      canShowTooltip: props.canShowTooltip,
    },
  ].filter(value => !!value);

  return (
    <Box>
      <Heading variant="subtitle1">{t("行動検知")}</Heading>
      <Stack spacing={2} direction="column">
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <List component="nav" aria-label="answer-detail-1">
              {leftSections.map(section => (
                <TestReportEvaluationItem key={section.title} {...section} />
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <List component="nav" aria-label="answer-detail-1">
              {rightSections.map(section => (
                <TestReportEvaluationItem key={section.title} {...section} />
              ))}
            </List>
          </Grid>
        </Grid>
        {props.ipAddressSection && <IpAddressDetectionReport {...props.ipAddressSection} />}
        {props.similarCodes && <SimilarCodes {...props.similarCodes} />}
      </Stack>
    </Box>
  );
};

ReportCheatDetectionSection.displayName = "ReportCheatDetectionSection";

export default ReportCheatDetectionSection;
