import { App } from "@hireroo/app-store/essential/shared";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as QuestionSelectFieldV2Subscriber from "../QuestionSelectFieldV2/Subscriber";
import { GenerateAssessmentResourceEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssessmentResourceEditorContainerProps = GenerateAssessmentResourceEditorPropsArgs;

const AssessmentResourceEditorContainer: React.FC<AssessmentResourceEditorContainerProps> = props => {
  const assessmentResourceEditorProps = useGenerateProps(props);
  const enableProject = App.useEnableProject();
  React.useEffect(() => {
    const cleanup1 = QuestionSelectFieldV2Subscriber.startSubscribeSelectedQuestions({
      target: "spot",
      enableProject,
    });
    return () => {
      cleanup1();
    };
  }, [enableProject]);

  return (
    <ErrorBoundary>
      <Widget.AssessmentResourceEditorV2Provider>
        <Widget.AssessmentResourceEditorV2 {...assessmentResourceEditorProps} />
      </Widget.AssessmentResourceEditorV2Provider>
    </ErrorBoundary>
  );
};

AssessmentResourceEditorContainer.displayName = "AssessmentResourceEditorContainer";

export default withErrorBoundary(AssessmentResourceEditorContainer, {});
