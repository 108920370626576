import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box, { BoxProps } from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton/IconButton";
import QuestionArchivedBadge from "../QuestionArchivedBadge/QuestionArchivedBadge";

type CopyContentEvent = {
  text: string | null;
};

export type QuestionSectionProps = React.PropsWithChildren<{
  title: string;
  description: string;
  difficultyText?: string;
  hideButton?: Pick<IconButtonProps, "onClick">;
  isArchived: boolean;
  onCopyQuestionContent?: (event: CopyContentEvent) => void;
}>;

const TitleText = styled(Typography)`
  font-weight: bold;
`;

const QuestionSection: React.FC<QuestionSectionProps> = props => {
  const { t } = useTranslation();
  const markdownWrapper = React.useMemo((): BoxProps => {
    return {
      /**
       * onCopyを利用することで、ショートカットとContext Menuからのコピー両方のコピーイベントを検知することができる
       */
      onCopy: () => {
        const copiedText = window.getSelection();
        props?.onCopyQuestionContent?.({
          text: copiedText ? copiedText.toString() : null,
        });
      },
    };
  }, [props]);
  /**
   * Cache because it may be re-rendered due to changes in other props
   */
  const MarkdownCache = React.useMemo(() => {
    return (
      <React.Suspense>
        <Markdown size="middle" children={props.description} />
      </React.Suspense>
    );
  }, [props.description]);
  return (
    <Box {...markdownWrapper}>
      <Box display="flex">
        <TitleText variant="h5" color="textPrimary" gutterBottom>
          {props.title}
        </TitleText>
        <Box mr={1} />
        {props.hideButton && (
          <IconButton {...props.hideButton}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
      </Box>

      {props.difficultyText && (
        <Box my={3}>
          <TitleText variant="h6" gutterBottom>
            {t("難易度")}
          </TitleText>
          <Divider />
          <Box mt={1}>
            <Typography variant="body2">{props.difficultyText}</Typography>
          </Box>
        </Box>
      )}
      {props.isArchived && (
        <Box sx={{ marginBottom: "10px" }}>
          <QuestionArchivedBadge />
        </Box>
      )}
      {MarkdownCache}
      {props.children && <Box mt={2}>{props.children}</Box>}
    </Box>
  );
};

QuestionSection.displayName = "QuestionSection";

export default QuestionSection;
