import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { useAnswerLabelMap } from "@hireroo/challenge/definition";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";

export const useGenerateContentLeftSidePanelProps = (): Widget.RemoteInterviewEntityPlaybackProps["content"]["leftSidePanel"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const normalizedActiveSession = RemoteInterviewPlayback.useNormalizedActiveSession();
  const algorithmAnswerMap = useAnswerLabelMap();

  if (!normalizedActiveSession) {
    return {
      questionAndAnswer: {
        uniqueKey: "",
        questionSection: {
          title: "",
          description: "",
          isArchived: false,
        },
        answerSections: [],
      },
    };
  }

  return {
    questionAndAnswer: {
      uniqueKey: normalizedActiveSession.liveCodingSessionId.toString(),
      questionSection: {
        title: resolveLanguage(normalizedActiveSession, lang, "title"),
        description: resolveLanguage(normalizedActiveSession, lang, "description"),
        isArchived: normalizedActiveSession.isArchived,
      },
      answerSections: normalizedActiveSession.answers.map(
        (answer): Widget.RemoteInterviewEntityPlaybackProps["content"]["leftSidePanel"]["questionAndAnswer"]["answerSections"][number] => {
          const answerTabNameMap: Record<RemoteInterviewPlayback.QuestionVariant, string> = {
            ALGORITHM: algorithmAnswerMap[answer.label],
            SYSTEM_DESIGN: `${t("模範解答")}${answer.label}`,
          };

          return {
            tabName: answerTabNameMap[answer.variant],
            title: resolveLanguage(normalizedActiveSession || {}, lang, "title"),
            description: resolveLanguage(answer, lang, "description"),
          };
        },
      ),
    },
  };
};
