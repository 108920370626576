import { useQuestionDifficultyTypeMap } from "@hireroo/app-definition/question";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import * as React from "react";

import ChallengeReadonlyCodeEditorContainer from "./widgets/ChallengeReadonlyCodeEditor/Container";
import RemoteInterviewStaticFlowChartContainer from "./widgets/RemoteInterviewStaticFlowChart/Container";

type QuestionsTable = Exclude<Widget.RemoteInterviewOverviewProps["questionsTable"], undefined>;
type FollowUpQuestions = Exclude<QuestionsTable["rows"][number]["details"], undefined>["followUpQuestions"];

export const useGenerateQuestionsTableProps = (): QuestionsTable => {
  const { t } = useTranslation();
  const interview = RemoteInterviewOverview.useInterview();
  const lang = useLanguageCode();
  const difficultyTypeMap = useQuestionDifficultyTypeMap();

  const rows = React.useMemo((): QuestionsTable["rows"] => {
    const items: QuestionsTable["rows"] = [];
    for (const session of interview.entity?.liveCoding?.sessions || []) {
      let index = 0;
      if (!session) {
        continue;
      }

      const qNumber = `Q${index + 1}`;
      const questions: FollowUpQuestions = session.followUpQuestions.map((followUpQuestion): FollowUpQuestions[0] => ({
        question: resolveLanguage(followUpQuestion, lang, "question"),
        answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
        purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
      }));
      if (session.questionType === "ALGORITHM" && session.algorithmQuestion) {
        const question = session.algorithmQuestion;
        const questionExtra =
          session.questionExtras?.__typename === "LiveCodingAlgorithmQuestionExtras"
            ? {
                initialCode: session.questionExtras.initialCode,
                runtime: session.questionExtras.initialRuntime,
              }
            : null;
        items.push({
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")} （${t("コーディング形式")}）`,
            href: generatePath("/e/questions/challenge/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            }),
          },
          variant: t("コーディング形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          isArchived: question.status === "ARCHIVED",
          details: session.isInherited
            ? {
                title: t("提出コード"),
                InheritedSection: questionExtra?.runtime && questionExtra?.initialCode && (
                  <ChallengeReadonlyCodeEditorContainer runtime={questionExtra.runtime} code={questionExtra.initialCode} />
                ),
                followUpQuestions: questions,
              }
            : undefined,
        });
      } else if (session.questionType === "SYSTEM_DESIGN" && session.systemDesignQuestion) {
        const question = session.systemDesignQuestion;
        const questionExtra =
          session.questionExtras?.__typename === "LiveCodingSystemDesignQuestionExtras"
            ? {
                initialComponentType: session.questionExtras.initialComponentType,
                initialFlowChart: session.questionExtras.initialFlowChart,
              }
            : null;
        const parsedFlowChartSnapshot = questionExtra?.initialFlowChart ? parseFlowChartSnapshot(questionExtra?.initialFlowChart) : null;

        items.push({
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")}`,
            href: generatePath("/e/questions/systemdesign/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
            }),
          },
          variant: t("システムデザイン形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          isArchived: question.status === "ARCHIVED",
          details: session.isInherited
            ? {
                title: t("提出された解答"),
                InheritedSection: parsedFlowChartSnapshot?.result ? (
                  <RemoteInterviewStaticFlowChartContainer
                    componentType={parsedFlowChartSnapshot.result.componentType}
                    elements={parsedFlowChartSnapshot.result.elements}
                  />
                ) : null,
                followUpQuestions: questions,
              }
            : undefined,
        });
      }
      index++;
    }
    return items;
  }, [difficultyTypeMap, interview.entity?.liveCoding?.sessions, lang, t]);
  return {
    rows: rows,
  };
};
