import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ContactUsDialog, { ContactUsDialogProps } from "../../../../ms-components/Payment/ContactUsDialog/ContactUsDialog";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "space-around",
  width: "100%",
}));

const TextWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  height: 36,
}));

const StyledButton = styled(Button)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  textTransform: "none",
}));

const BorderedLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.text.secondary}`,
  fontSize: 12,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  borderRadius: 2,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.text.primary,
  borderRadius: 4,
  display: "inline-block",
  padding: "4px 8px",
  marginRight: theme.spacing(1),
  textTransform: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type PlanProps = {
  planLabel: string;
  planSubLabel?: string;
  contactUsButton?: Pick<ButtonProps, "onClick">;
};

const Plan: React.FC<PlanProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const contactUsDialogProps: ContactUsDialogProps = {
    open,
    title: t("トライアルの延長をリクエストしますか？"),
    onClose: () => {
      setOpen(false);
    },
  };

  const contactUsButton: ButtonProps | undefined = props.contactUsButton
    ? {
        children: t("トライアル延長"),
        variant: "outlined",
        color: "primary",
        disableRipple: true,
        onClick: () => {
          setOpen(true);
        },
      }
    : undefined;

  return (
    <Wrapper elevation={4}>
      <ContactUsDialog {...contactUsDialogProps} />

      <Stack direction="column" spacing={1}>
        <TextWrapper>
          <Label variant="body2">{props.planLabel}</Label>
          <Typography variant="subtitle2" color="text.secondary" noWrap>
            {t("プラン")}
          </Typography>
        </TextWrapper>
        {props.planSubLabel && <BorderedLabel>{props.planSubLabel}</BorderedLabel>}
      </Stack>

      <Box width="100%" />

      <Stack direction="row" spacing={2}>
        {contactUsButton && <StyledButton {...contactUsButton} />}
      </Stack>
    </Wrapper>
  );
};

Plan.displayName = "Plan";

export default Plan;
