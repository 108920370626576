import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledScoringFeedback } from "@hireroo/app-helper/feature";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Mixpanel } from "@hireroo/app-monitoring";
import { Auth, Company, Payment } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { TestReport } from "@hireroo/app-store/widget/e/TestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { ScreeningTestRetakeForm, ShareSettingsForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import ScreeningTestFeedbackFetchContainer from "../../../../../widget/v2/e/ScreeningTestFeedback/FetchContainer";
import ScreeningTestOverviewFetchContainer from "../../../../../widget/v2/e/ScreeningTestOverview/FetchContainer";
import ScreeningTestReportFetchContainer from "../../../../../widget/v2/e/ScreeningTestReport/FetchContainer";
import ScreeningTestRetakeDialogContainer, {
  ScreeningTestRetakeDialogContainerProps,
} from "../../../../../widget/v2/e/ScreeningTestRetakeDialog/Container";
import TechnicalCommentPopperForSpotContainer from "../../../../../widget/v2/e/TechnicalCommentPopperForSpot/Container";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

type EntityUniqueId = `${ScreeningTestRetakeForm.EntityIdSchema["entityId"]}-${ScreeningTestRetakeForm.EntityIdSchema["entityType"]}`;

type ShareFieldName = Widget.ScreeningTestDetailForEmployeeProps["generateSharedLinkDialog"]["shareSettingsFields"]["enabledFields"][0];

export type GenerateScreeningTestDetailForEmployeePropsArgs = {
  screeningTestId: string;
  hasScoringPrecisionFeedback: boolean;
};

export const useGenerateProps = (args: GenerateScreeningTestDetailForEmployeePropsArgs): Widget.ScreeningTestDetailForEmployeeProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();
  const interviewDetail = ScreeningsTestsIdDetailStore.useInterview();
  const interviewOverviewRefresh = InterviewOverview.useRefresh();
  const navigate = useTransitionNavigate();
  const currentTab = ScreeningsTestsIdDetailStore.useCurrentTab();
  const sharedLink = ScreeningsTestsIdDetailStore.useSharedLink();
  const sharedUrl = generateCurrentOriginUrl("/c/screenings/tests/:id/detail", {
    pathParams: { id: interviewDetail.id },
    queryParams: { share: sharedLink?.linkHash ?? "" },
  });
  const [, setParams] = useSearchParams();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const activeCompanyId = Company.useStrictActiveCompanyId();

  const dialogStatus = ScreeningsTestsIdDetailStore.useDialogStatus();
  const enabledScoringFeedback = useEnabledScoringFeedback();
  const reportRefresh = TestReport.useRefresh();

  const [evaluationStatus, setEvaluationStatus] = React.useState<"LOADING" | "READY">("READY");
  const [recreateStatus, setRecreateStatus] = React.useState<"LOADING" | "READY">("READY");
  const [deletionStatus, setDeletionStatus] = React.useState<"LOADING" | "READY">("READY");
  const [shareStatus, setShareStatus] = React.useState<"LOADING" | "READY">("READY");

  const [generateSharedLinkDialogStep, setGenerateSharedLinkDialogStep] = React.useState<"SETTINGS" | "COPY">("SETTINGS");

  const validReviewStatuses: Graphql.SpotStatus[] = ["COMPLETED", "EVALUATED", "REVIEWED", "FINALIZED", "DECLINED"];
  const canReview = validReviewStatuses.includes(interviewDetail.status);
  const spotForRetake = ScreeningsTestsIdDetailStore.useSpotForRetake();
  const isSpotForRetakeLoading = ScreeningsTestsIdDetailStore.useIsSpotForRetakeLoading();
  const header = useGenerateHeaderProps(args);
  const pdfExport = ScreeningsTestsIdDetailStore.usePdfExport(args.screeningTestId);
  const pdfDownloadUrl = pdfExport?.downloadUrl;

  useTitle(interviewDetail.name);

  const handleSubmitShareSettings = React.useCallback(
    async (fields: ShareSettingsForm.ShareSettingsFormSchema) => {
      setShareStatus("LOADING");
      await client
        .GenerateSpotSharedLink({
          input: {
            spotId: interviewDetail.id,
            showScore: fields.showScore,
            showRank: isAvailableFeature("test.rank.read") ? fields.showRank : false,
            showAnswer: fields.showAnswer,
            showPlayback: isAvailableFeature("test.playback.read") ? fields.showPlayback : false,
            showReview: isAvailableFeature("test.technical-comment.create") ? fields.showReview : false,
            showRelativeEvaluation: isAvailableFeature("test.statics.read") ? fields.showRelativeEvaluation : false,
            periodSeconds: fields.periodDays * 24 * 60 * 60,
          },
        })
        .then(res => {
          if (res.generateSpotSharedLink) {
            ScreeningsTestsIdDetailStore.updateSharedLink(res.generateSpotSharedLink);
            setGenerateSharedLinkDialogStep("COPY");
          }
          Snackbar.notify({
            severity: "success",
            message: t("共有リンクを生成しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("共有リンクの生成に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setShareStatus("READY");
        });
    },
    [client, interviewDetail.id, isAvailableFeature, t],
  );

  const handleConfirmGenerateSharedLinkDialog = React.useMemo(() => {
    const handleConfirmForCopy = () => {
      ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
      // Provide 1 sec to avoid step back before closing dialog
      window.setTimeout(() => {
        setGenerateSharedLinkDialogStep("SETTINGS");
      }, 1000);
    };
    const confirmFnMap: Record<"SETTINGS" | "COPY", () => void> = {
      SETTINGS: () => undefined,
      COPY: handleConfirmForCopy,
    };
    return confirmFnMap[generateSharedLinkDialogStep];
  }, [generateSharedLinkDialogStep]);

  const handleCancelGenerateSharedLinkDialog = React.useMemo(() => {
    const handleCancelForSettings = () => {
      ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
    };
    const handleCancelForCopy = () => {
      setGenerateSharedLinkDialogStep("SETTINGS");
    };
    const cancelFnMap: Record<"SETTINGS" | "COPY", () => void> = {
      SETTINGS: handleCancelForSettings,
      COPY: handleCancelForCopy,
    };
    return cancelFnMap[generateSharedLinkDialogStep];
  }, [generateSharedLinkDialogStep]);

  const defaultTab = React.useMemo((): ScreeningsTestsIdDetailStore.TabValue | null => {
    if (!canReview && currentTab === "review") {
      return "summary";
    }
    return currentTab;
  }, [canReview, currentTab]);

  const retakeDialogContainerProps: ScreeningTestRetakeDialogContainerProps = {
    open: dialogStatus === "OPEN_RECREATION",
    onClose: () => {
      ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
    },
    buttonDisabled: recreateStatus === "LOADING",
    onSubmit: async fields => {
      if (!spotForRetake || !spotForRetake.timeLimitSeconds) return;
      setRecreateStatus("LOADING");

      const selectedEntityIdSet = new Set(fields.selectedEntityIds.map((v): EntityUniqueId => `${v.entityId}-${v.entityType}`));
      /**
       * completedEntityIds have to be opposite of selectedEntityIds
       */
      const completedEntityIds = spotForRetake.entities.reduce<Graphql.SpotEntityIdInput[]>((all, entity) => {
        if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
          const uniqueId: EntityUniqueId = `${entity.challengeEntityId}-CHALLENGE`;
          if (!selectedEntityIdSet.has(uniqueId)) {
            return all.concat({
              entityId: entity.challengeEntityId,
              entityType: "CHALLENGE",
            });
          }
        } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
          const uniqueId: EntityUniqueId = `${entity.quizEntityId}-QUIZ`;
          if (!selectedEntityIdSet.has(uniqueId)) {
            return all.concat({
              entityId: entity.quizEntityId,
              entityType: "QUIZ",
            });
          }
        } else if (entity.__typename === "ProjectEntity" && entity.question) {
          const uniqueId: EntityUniqueId = `${entity.projectEntityId}-PROJECT`;
          if (!selectedEntityIdSet.has(uniqueId)) {
            return all.concat({
              entityId: entity.projectEntityId,
              entityType: "PROJECT",
            });
          }
        } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
          const uniqueId: EntityUniqueId = `${entity.systemDesignEntityId}-SYSTEM_DESIGN`;
          if (!selectedEntityIdSet.has(uniqueId)) {
            return all.concat({
              entityId: entity.systemDesignEntityId,
              entityType: "SYSTEM_DESIGN",
            });
          }
        }
        return all;
      }, []);

      await client
        .RecreateInterview({
          input: {
            spotId: interviewDetail.id,
            willEndAtSeconds: Math.floor(fields.willEndAt.getTime() / 1000),
            completedEntityIds: completedEntityIds,
            elapsedTimeSeconds: spotForRetake.timeLimitSeconds - fields.timeLimitMinutes * 60,
            recreateReason: fields.reasonType,
            recreateReasonComment: fields.reasonComment,
          },
        })
        .then(res => {
          if (res.recreateSpot) {
            ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
            navigate("/e/screenings/tests/:id/detail", { pathParams: { id: res.recreateSpot.id } });
            interviewOverviewRefresh();
            ScreeningsTestsIdDetailStore.updateInterview(res.recreateSpot);
            ScreeningsTestsIdDetailStore.updateCurrentTab("summary");
          }
          Snackbar.notify({
            severity: "success",
            message: t("再試験の作成に成功しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          Snackbar.notify({
            severity: "error",
            message: t("再試験の作成に失敗しました。しばらくしてから再度お試しください。"),
          });
        })
        .finally(() => {
          setRecreateStatus("READY");
        });
    },
    interview: spotForRetake,
    loading: isSpotForRetakeLoading,
  };

  return {
    header: header,
    title: interviewDetail.originId === interviewDetail.id ? interviewDetail.name : `${t("（再試験）")} ${interviewDetail.name}`,
    defaultTabId: defaultTab || undefined,
    items: [
      {
        id: "summary",
        Component: activeCompanyId && <ScreeningTestOverviewFetchContainer interviewId={interviewDetail.id} companyId={activeCompanyId} />,
        label: {
          name: t("テスト概要"),
          onClick: () => {
            ScreeningsTestsIdDetailStore.updateCurrentTab("summary");
            setParams({ tab: "summary" });
          },
          icon: "DESCRIPTION" as const,
        },
      },
      {
        id: "report",
        Component: <ScreeningTestReportFetchContainer interviewId={interviewDetail.id} />,
        label: {
          name: t("レポート"),
          onClick: () => {
            ScreeningsTestsIdDetailStore.updateCurrentTab("report");
            setParams({ tab: "report" });
          },
          icon: "TRENDING_UP" as const,
        },
      },
      isAvailableFeature("test.technical-comment.create") && {
        id: "review",
        Component: <ScreeningTestFeedbackFetchContainer interviewId={interviewDetail.id} />,
        label: {
          name: t("技術レビュー"),
          onClick: () => {
            ScreeningsTestsIdDetailStore.updateCurrentTab("review");
            setParams({ tab: "review" });
          },
          disabled: !canReview,
          disabledTooltip: t("試験が終了すると利用可能になります。"),
          icon: "COMMENT" as const,
        },
      },
    ].flatMap(v => (v ? [v] : [])),
    evaluationDialog: {
      testName: interviewDetail.name,
      candidateName: interviewDetail.candidateName,
      url: generateCurrentOriginUrl("/e/screenings/tests/:id/detail", {
        pathParams: {
          id: interviewDetail.id,
        },
      }),
      open: dialogStatus === "OPEN_EVALUATION",
      onClose: () => {
        ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
      },
      onSubmit: async fields => {
        setEvaluationStatus("LOADING");
        await client
          .EvaluateInterview({
            spotId: interviewDetail.id,
            employeeId: currentUid,
            isPassed: fields.result === "PASSED",
            evaluationComment: fields.comment,
          })
          .then(res => {
            if (res.evaluateSpot) {
              interviewOverviewRefresh();
              ScreeningsTestsIdDetailStore.updateInterview(res.evaluateSpot);
            }
            Snackbar.notify({
              severity: "success",
              message: t("テストの評価を完了しました。"),
            });
            ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
            if (enabledScoringFeedback && !args.hasScoringPrecisionFeedback) {
              setTimeout(() => ScreeningsTestsIdDetailStore.updateDialogStatus("OPEN_SCORING_FEEDBACK"), 100);
              Mixpanel.track({
                name: "TestReport-ScoringFeedbackDialog",
                properties: {
                  openedBy: "AfterEvaluation",
                },
              });
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("テストの評価に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setEvaluationStatus("READY");
          });
      },
      yesButton: {
        disabled: evaluationStatus === "LOADING",
      },
      noButton: {
        disabled: evaluationStatus === "LOADING",
      },
    },
    deletionDialog: {
      targetName: interviewDetail.name,
      open: dialogStatus === "OPEN_DELETION",
      onClose: () => {
        ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
      },
      yesButton: {
        onClick: async () => {
          setDeletionStatus("LOADING");
          await client
            .DeleteInterview({
              spotId: interviewDetail.id,
            })
            .then(() => {
              navigate("/e/screenings/tests");
              Snackbar.notify({
                severity: "success",
                message: t("テストを削除しました。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("テストの削除に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              setDeletionStatus("READY");
            });
        },
        disabled: deletionStatus === "LOADING",
      },
      noButton: {
        disabled: deletionStatus === "LOADING",
      },
    },
    pdfDownloadResultDialog: pdfDownloadUrl
      ? {
          open: !!pdfDownloadUrl,
          title: t2("GeneratedPdf", { name: interviewDetail.candidateName }),
          url: pdfDownloadUrl,
          onClose: () => {
            ScreeningsTestsIdDetailStore.updatePdfExportState(args.screeningTestId, {
              downloadUrl: null,
            });
          },
        }
      : undefined,
    generateSharedLinkDialog: {
      shareSettingsFields: {
        enabledFields: [
          "showScore",
          isAvailableFeature("test.rank.read") && "showRank",
          isAvailableFeature("test.playback.read") && "showPlayback",
          isAvailableFeature("test.statics.read") && "showRelativeEvaluation",
          isAvailableFeature("test.technical-comment.create") && "showReview",
        ].flatMap(v => (v ? [v as ShareFieldName] : [])),
      },
      dialog: {
        title: t2("Share", {
          interviewName: interviewDetail.name,
        }),
        open: dialogStatus === "OPEN_SHARE_LINK",
        yesButton: {
          disabled: shareStatus === "LOADING",
          onClick: handleConfirmGenerateSharedLinkDialog,
        },
        noButton: {
          disabled: shareStatus === "LOADING",
          onClick: handleCancelGenerateSharedLinkDialog,
        },
      },
      copyLinkField: {
        textField: {
          value: sharedUrl,
        },
        copyToClipboardButton: {
          url: sharedUrl,
          name: t("コピーする"),
          onCopy: () => {
            Snackbar.notify({
              severity: "info",
              message: t("クリップボードにコピーされました"),
            });
          },
          buttonWithTooltip: {},
        },
      },
      step: generateSharedLinkDialogStep,
      onSubmit: handleSubmitShareSettings,
      // We don't need to save share settings of the created link in store,
      // hence it's okay default values are fixed values
      defaultValues: {
        showScore: false,
        showRank: false,
        showAnswer: false,
        showPlayback: false,
        showRelativeEvaluation: false,
        showReview: false,
        periodDays: 14,
      },
    },
    scoringFeedbackDialog: enabledScoringFeedback
      ? {
          dialog: {
            open: dialogStatus === "OPEN_SCORING_FEEDBACK",
            onClose: () => {
              ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE");
            },
            closeText: t("今はしない"),
          },
          onSubmit: (fields, controller) => {
            controller.setLoading(true);
            client
              .SendScoringPrecisionFeedbackForScreeningTestReport({
                input: {
                  spotId: interviewDetail.id,
                  employeeId: currentUid,
                  answer: fields.answer,
                  comment: fields.comment,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("フィードバックを送信しました。"),
                });
                controller.close();
                reportRefresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                Snackbar.notify({
                  severity: "error",
                  message: t("フィードバックの送信に失敗しました。"),
                });
              })
              .finally(() => {
                controller.setLoading(false);
              });
          },
        }
      : undefined,
    RecreateDialog: <ScreeningTestRetakeDialogContainer {...retakeDialogContainerProps} />,
    Prompt: activeCompanyId && dialogStatus === "OPEN_TECH_REVIEW" && (
      <TechnicalCommentPopperForSpotContainer
        companyId={activeCompanyId}
        spotId={interviewDetail.id}
        onClose={() => ScreeningsTestsIdDetailStore.updateDialogStatus("CLOSE")}
      />
    ),
  };
};
