import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { useAnswerLabelMap } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useMemo } from "react";

import RemoteInterviewStaticFlowChartContainer from "./RemoteInterviewStaticFlowChart/Container";

export const useGenerateContentProps = (): Widget.RemoteInterviewEntityPlaybackProps["content"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const normalizedActiveSession = RemoteInterviewPlayback.useNormalizedActiveSession();
  const algorithmAnswerMap = useAnswerLabelMap();
  const selectedEditorOption = RemoteInterviewPlayback.useSelectedEditorOption();
  const activeSessionId = RemoteInterviewPlayback.useActiveSessionId();

  const VariantEditorMap = useMemo((): Record<Graphql.RemoteQuestionVariant, React.ReactNode> => {
    return {
      ALGORITHM: null,
      DATABASE: null,
      CLASS: null,
      SYSTEM_DESIGN: <RemoteInterviewStaticFlowChartContainer key={`system-design-${activeSessionId}-${selectedEditorOption}`} />,
      UNKNOWN: null,
    };
  }, [activeSessionId, selectedEditorOption]);

  if (!normalizedActiveSession) {
    return {
      leftSidePanel: {
        questionAndAnswer: {
          uniqueKey: "",
          questionSection: {
            title: "",
            description: "",
            isArchived: false,
          },
          answerSections: [],
        },
      },
      Editor: null,
    };
  }

  return {
    leftSidePanel: {
      questionAndAnswer: {
        uniqueKey: normalizedActiveSession.liveCodingSessionId.toString(),
        questionSection: {
          title: resolveLanguage(normalizedActiveSession, lang, "title"),
          description: resolveLanguage(normalizedActiveSession, lang, "description"),
          isArchived: normalizedActiveSession.isArchived,
        },
        answerSections: normalizedActiveSession.answers.map(
          (answer): Widget.RemoteInterviewEntityPlaybackProps["content"]["leftSidePanel"]["questionAndAnswer"]["answerSections"][number] => {
            const answerTabNameMap: Record<RemoteInterviewPlayback.QuestionVariant, string> = {
              ALGORITHM: algorithmAnswerMap[answer.label],
              SYSTEM_DESIGN: `${t("模範解答")}${answer.label}`,
            };

            return {
              tabName: answerTabNameMap[answer.variant],
              title: resolveLanguage(normalizedActiveSession, lang, "title"),
              description: resolveLanguage(answer, lang, "description"),
            };
          },
        ),
      },
    },
    Editor: VariantEditorMap[normalizedActiveSession.variant],
  };
};
