import { createQuizEventRecordService } from "@hireroo/app-helper/quiz";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import React from "react";

import QuizCodingEditorContainer, { QuizCodingEditorContainerProps } from "./Container";

export type QuizCodingEditorFetchContainerProps = {
  entityId: number;
  interviewKind: "INTERVIEW" | "DEMO";
  uid: string;
  packageId: number;
  firstQuestionId: number;
  enableBrowserEventDetector: boolean;
};

const QuizCodingEditorFetchContainer: React.FC<QuizCodingEditorFetchContainerProps> = props => {
  const { t } = useTranslation();
  const [result, startQuiz] = Graphql.useStartQuizForQuizCodingEditorMutation();
  const { useEntity, useService } = QuizCodingEditor.useCreateQuizEntityHooks(props.entityId);
  const entity = useEntity();
  const service = useService();

  React.useEffect(() => {
    if (entity.quizStatus === "CREATED" && !result.error && !result.fetching) {
      startQuiz({
        quizId: entity.quizEntityId,
        candidateId: props.uid,
      })
        .then(res => {
          if (res.data?.startQuiz) {
            QuizCodingEditor.initialize(res.data.startQuiz);
          }
        })
        .catch(err => {
          Snackbar.notify({
            severity: "error",
            message: t("クイズ形式の試験開始に失敗しました。"),
          });
          Sentry.captureException(err);
        });
    }
  }, [entity.quizEntityId, entity.quizStatus, props.uid, result, startQuiz, t]);

  React.useEffect(() => {
    createQuizEventRecordService({
      quizId: props.entityId,
      quizPackageId: props.packageId,
      questionId: props.firstQuestionId,
    })
      .then(instance => {
        QuizCodingEditor.setService(props.entityId, instance);
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [props.entityId, props.packageId, props.firstQuestionId]);

  React.useEffect(() => {
    return () => {
      service?.comeOutQuizPackage();
      service?.dispose();
    };
  }, [service]);

  if (entity.quizStatus === "CREATED" || result.fetching || !service) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const containerProps: QuizCodingEditorContainerProps = {
    ...props,
    service,
  };

  return (
    <ErrorBoundary>
      <QuizCodingEditorContainer {...containerProps} />
    </ErrorBoundary>
  );
};

QuizCodingEditorFetchContainer.displayName = "QuizCodingEditorFetchContainer";

export default withErrorBoundary(QuizCodingEditorFetchContainer, {});
