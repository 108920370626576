import { App } from "@hireroo/app-store/essential/talent";
import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import CustomErrorMessagePanelContainer from "../../../../widget/v2/t/CustomErrorMessagePanel/Container";
import ErrorPanelContainer from "../../../../widget/v2/t/ErrorPanel/Container";
import ScreeningTestContainer, { ExamsIdContainerProps } from "./Container";

export type ExamsIdFetchContainerProps = {};

const ExamsIdFetchContainer: React.FC<ExamsIdFetchContainerProps> = () => {
  const { t } = useTranslation();
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [examId, setExamId] = React.useState<string>(id || "");

  const initialized = AssessmentsIdStore.useInitialized();
  const [result] = Graphql.useGetExamForAssessmentsIdQuery({
    variables: {
      examId: id?.toString() ?? "",
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || initialized,
  });

  React.useEffect(() => {
    if (result.data) {
      const { exam } = result.data;
      setExamId(exam.examId);
      AssessmentsIdStore.setExam(exam);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  if (result.data?.exam.answerAvailability === "NOT_AVAILABLE_FOR_INCLUDING_PROJECT") {
    return (
      <CustomErrorMessagePanelContainer
        title={t("このテストはご利用できない問題(実践形式)が出題されています。")}
        message={t("詳細はこのテストを出題している企業までお問い合わせください。")}
      />
    );
  }

  const containerProps: ExamsIdContainerProps = {
    examId: examId,
  };
  return <ScreeningTestContainer {...containerProps} />;
};

ExamsIdFetchContainer.displayName = "ExamsIdFetchContainer";

export default withErrorBoundary(ExamsIdFetchContainer, {});
