import * as Graphql from "@hireroo/graphql/client/urql";

type FeatureName =
  | "test.statics"
  | "test.rank"
  | "test.playback"
  | "test.csv-export"
  | "test.technical-comment"
  | "test.notifications.slack"
  | "test.copy-and-paste"
  | "test.project"
  | "test.systemDesign"
  | "test.suspicious-degree"
  | "interview"
  | "interview.notifications.slack"
  | "question"
  | "question.challenge"
  | "question.quiz"
  | "question.project"
  | "question.systemDesign"
  | "integrations.ats"
  | "signin.sso"
  | "assessment"
  | "exam.playback"
  | "exam.statics"
  | "exam.technical-comment"
  | "exam.notifications.slack"
  | "exam.copy-and-paste"
  | "exam.project"
  | "exam.systemDesign"
  | "behavioral-control";
type Method = "create" | "edit" | "read" | "delete";

type Key = `${FeatureName}.${Method}`;

export type PaymentItemType = Graphql.PaymentItemType;
const PaymentItemType = Graphql.PaymentItemType;

type FeaturePlanMap = {
  [key in Key]?: Graphql.PaymentItemType[];
};

const AllEnterprises: PaymentItemType[] = [
  PaymentItemType.Enterprise,
  PaymentItemType.EnterpriseV6,
  PaymentItemType.EnterpriseV7,
  PaymentItemType.EnterpriseV8,
  PaymentItemType.EnterpriseV9,
];

export const FeaturePlanMap = {
  // For test features
  "test.statics.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.rank.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.playback.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.csv-export.read": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.technical-comment.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.notifications.slack.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.notifications.slack.edit": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.copy-and-paste.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.project.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.project.edit": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.project.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.systemDesign.create": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.systemDesign.edit": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.systemDesign.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.suspicious-degree.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "test.suspicious-degree.edit": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  // For interview features
  "interview.read": [
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "interview.create": [
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "interview.edit": [
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "interview.notifications.slack.create": [
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "interview.notifications.slack.edit": [
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],

  // For question features
  "question.challenge.create": [
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.challenge.edit": [
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.quiz.create": [
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.quiz.edit": [
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.project.read": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.project.create": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "question.systemDesign.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  // For integrations features
  "integrations.ats.read": [
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "integrations.ats.create": [
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "integrations.ats.edit": [
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],

  // For signin features
  "signin.sso.read": [PaymentItemType.Professional, ...AllEnterprises],
  "signin.sso.create": [PaymentItemType.Professional, ...AllEnterprises],
  "signin.sso.edit": [PaymentItemType.Professional, ...AllEnterprises],

  //TODO: change when the plan is fixed
  // For assessment features
  "assessment.read": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "assessment.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "assessment.edit": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],

  "exam.statics.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.playback.read": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV5,
    PaymentItemType.BasicV6,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.technical-comment.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.BasicV7,
    PaymentItemType.BasicV8,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.notifications.slack.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.notifications.slack.edit": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.copy-and-paste.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.project.create": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.project.edit": [
    PaymentItemType.Basic,
    PaymentItemType.BasicV2,
    PaymentItemType.BasicV3,
    PaymentItemType.BasicV4,
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.systemDesign.create": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "exam.systemDesign.edit": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "behavioral-control.read": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
  "behavioral-control.create": [
    PaymentItemType.Standard,
    PaymentItemType.StandardV2,
    PaymentItemType.StandardV3,
    PaymentItemType.StandardV4,
    PaymentItemType.StandardV5,
    PaymentItemType.StandardV6,
    PaymentItemType.StandardV7,
    PaymentItemType.StandardV8,
    PaymentItemType.StandardV9,
    PaymentItemType.Professional,
    PaymentItemType.ProfessionalV2,
    PaymentItemType.ProfessionalV3,
    PaymentItemType.ProfessionalV4,
    PaymentItemType.ProfessionalV5,
    PaymentItemType.ProfessionalV6,
    ...AllEnterprises,
  ],
} satisfies FeaturePlanMap;

export type AvailableFeatureName = keyof typeof FeaturePlanMap;

export type PaymentV2ItemType = `${Graphql.PlanType}.${Graphql.PaymentV2Plan["generation"]}`;

export const paymentV2ItemTypeToPaymentItemType: Record<PaymentV2ItemType, Graphql.PaymentItemType> = {
  [`${Graphql.PlanType.Basic}.1`]: Graphql.PaymentItemType.Basic,
  [`${Graphql.PlanType.Basic}.2`]: Graphql.PaymentItemType.BasicV2,
  [`${Graphql.PlanType.Basic}.3`]: Graphql.PaymentItemType.BasicV3,
  [`${Graphql.PlanType.Basic}.4`]: Graphql.PaymentItemType.BasicV4,
  [`${Graphql.PlanType.Basic}.5`]: Graphql.PaymentItemType.BasicV5,
  [`${Graphql.PlanType.Basic}.6`]: Graphql.PaymentItemType.BasicV6,
  [`${Graphql.PlanType.Basic}.7`]: Graphql.PaymentItemType.BasicV7,
  [`${Graphql.PlanType.Basic}.8`]: Graphql.PaymentItemType.BasicV8,
  [`${Graphql.PlanType.Basic}.9`]: Graphql.PaymentItemType.BasicV9,
  [`${Graphql.PlanType.Standard}.1`]: Graphql.PaymentItemType.Standard,
  [`${Graphql.PlanType.Standard}.2`]: Graphql.PaymentItemType.StandardV2,
  [`${Graphql.PlanType.Standard}.3`]: Graphql.PaymentItemType.StandardV3,
  [`${Graphql.PlanType.Standard}.4`]: Graphql.PaymentItemType.StandardV4,
  [`${Graphql.PlanType.Standard}.5`]: Graphql.PaymentItemType.StandardV5,
  [`${Graphql.PlanType.Standard}.6`]: Graphql.PaymentItemType.StandardV6,
  [`${Graphql.PlanType.Standard}.7`]: Graphql.PaymentItemType.StandardV7,
  [`${Graphql.PlanType.Standard}.8`]: Graphql.PaymentItemType.StandardV8,
  [`${Graphql.PlanType.Standard}.9`]: Graphql.PaymentItemType.StandardV9,
  [`${Graphql.PlanType.Professional}.1`]: Graphql.PaymentItemType.Professional,
  [`${Graphql.PlanType.Professional}.2`]: Graphql.PaymentItemType.ProfessionalV2,
  [`${Graphql.PlanType.Professional}.3`]: Graphql.PaymentItemType.ProfessionalV3,
  [`${Graphql.PlanType.Professional}.4`]: Graphql.PaymentItemType.ProfessionalV4,
  [`${Graphql.PlanType.Professional}.5`]: Graphql.PaymentItemType.ProfessionalV5,
  [`${Graphql.PlanType.Professional}.6`]: Graphql.PaymentItemType.ProfessionalV6,
  [`${Graphql.PlanType.Enterprise}.1`]: Graphql.PaymentItemType.Enterprise,
  [`${Graphql.PlanType.Enterprise}.2`]: Graphql.PaymentItemType.EnterpriseV6,
  [`${Graphql.PlanType.Enterprise}.3`]: Graphql.PaymentItemType.EnterpriseV6,
  [`${Graphql.PlanType.Enterprise}.4`]: Graphql.PaymentItemType.EnterpriseV6,
  [`${Graphql.PlanType.Enterprise}.5`]: Graphql.PaymentItemType.EnterpriseV6,
  [`${Graphql.PlanType.Enterprise}.6`]: Graphql.PaymentItemType.EnterpriseV6,
  [`${Graphql.PlanType.Enterprise}.7`]: Graphql.PaymentItemType.EnterpriseV7,
  [`${Graphql.PlanType.Enterprise}.8`]: Graphql.PaymentItemType.EnterpriseV8,
  [`${Graphql.PlanType.Enterprise}.9`]: Graphql.PaymentItemType.EnterpriseV9,
};
