import { getRef } from "@hireroo/firebase";
import { PlaybackEvent } from "@hireroo/validator";
import { EventEmitter } from "events";
import type firebase from "firebase/compat/app";

import type * as Types from "./ClientType";

export type CreateReferenceArgs = {
  quizId: number;
};

export type InitializeConfig = {
  debug?: boolean;
};

export interface Listener {
  changePackageId: {
    payload: { packageId: number };
    callback: (payload: Listener["changePackageId"]["payload"]) => void;
  };
  changeQuizStateObject: {
    payload: { data: PlaybackEvent.QuizStateObject };
    callback: (payload: Listener["changeQuizStateObject"]["payload"]) => void;
  };
}

type EventName = keyof Listener;

/**
 * Firebase Realtime Database Path: quizzes/${quizId}/state
 */
export class QuizStateClient implements Types.BaseStateClient {
  #emitter = new EventEmitter();
  #disposeClientListener: () => void = () => undefined;
  #isDisposed = false;
  #isConnected = false;
  #debug = false;

  constructor(config: InitializeConfig) {
    this.#debug = config.debug ?? false;
  }

  private static createReference = (config: CreateReferenceArgs) => {
    return getRef("quiz", `quizzes/${config.quizId}/state`);
  };

  public reconnect = async (config: CreateReferenceArgs) => {
    this.#disposeClientListener();
    this.#isConnected = false;
    await this.connect(config);
  };

  public connect = async (config: CreateReferenceArgs): Promise<void> => {
    if (this.#isDisposed) {
      console.warn(`QuizPackageStateClient was disposed in connect sequence`);
      return;
    }
    const client = QuizStateClient.createReference(config);
    await client.once("value", this.handleOnlyOnceValue);
    client.on("child_added", this.handleChildAdded);

    this.#disposeClientListener = () => {
      client.off("value", this.handleOnlyOnceValue);
      client.off("child_added", this.handleChildAdded);
    };
  };

  private handleChildAdded = (snapshot: firebase.database.DataSnapshot) => {
    const data = snapshot.val();
    if (!data || snapshot.key === null) {
      return;
    }
    const result = PlaybackEvent.QuizState.safeParse(data);
    if (result.success) {
      switch (result.data.s) {
        case "selp": {
          this.emit("changePackageId", {
            packageId: result.data.v,
          });
          break;
        }
        default:
          break;
      }
    }
  };

  private handleOnlyOnceValue = (snapshot: firebase.database.DataSnapshot) => {
    const data = snapshot.val();
    if (!data) {
      return;
    }
    const result = PlaybackEvent.QuizStateObject.safeParse(data);
    if (result.success) {
      this.emit("changeQuizStateObject", {
        data: result.data,
      });
    } else {
      console.warn({
        raw: data,
        error: result.error,
      });
    }
  };

  public dispose = () => {
    if (this.#isDisposed) {
      return;
    }
    this.#isDisposed = true;
    this.#disposeClientListener();
  };

  public on = <T extends EventName>(eventName: T, callback: Listener[T]["callback"]) => {
    this.#emitter.on(eventName, callback);
    return () => {
      this.#emitter.off(eventName, callback);
    };
  };

  private emit = <T extends EventName>(eventName: T, payload: Listener[T]["payload"]) => {
    if (this.#isDisposed) {
      return;
    }
    this.#emitter.emit(eventName, payload);
  };
}
