import { Company } from "@hireroo/app-store/essential/talent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getIdToken } from "@hireroo/firebase";
import { createTemporallyGraphqlClient, getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";
import { redirect } from "@hireroo/router/api";

type AcceptInvitationArgs = {
  companyId: number;
  uid: string;
  invitationId: string;
};

export const acceptInvitation = async (args: AcceptInvitationArgs) => {
  const { t } = getTranslation();
  /**
   * Get a new client to update Credential information
   */
  const client = getGraphqlClient();
  const { acceptTalentInvitation } = await client.AcceptTalentInvitation({
    input: {
      talentId: args.uid,
      invitationId: args.invitationId,
    },
  });

  Snackbar.notify({
    severity: "success",
    message: t("招待を承諾しました。数秒後にリダイレクトされます。"),
  });

  await client.UpdateClaimsForTalent({
    input: {
      companyId: acceptTalentInvitation.companyId,
      uid: args.uid,
      userType: "TALENT",
    },
  });
  const authToken = await getIdToken(true);
  const newClient = createTemporallyGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    appVersion: APP_VERSION,
  });

  await newClient.SwitchActiveCompanyForTalentV2({
    input: {
      companyId: acceptTalentInvitation.companyId,
      talentId: args.uid,
    },
  });

  Company.updateActiveCompanyId(acceptTalentInvitation.companyId);
  return new Promise<void>(resolve => {
    setTimeout(() => {
      redirect("/t/assessments").then(() => {
        resolve();
      });
      /**
       * Provide a 1 second delay because transitions prior to the execution of Subscriber
       * due to a change in ActiveCompanyId may not work properly.
       */
    }, 1000);
  });
};
