import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomStepLabel, { CustomStepLabelProps } from "../CustomStepLabel/CustomStepLabel";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p4,
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  padding: "8px 16px",
}));

const StyledStack = styled(Stack)(() => ({
  padding: "16px",
}));

export type IpAddressDetectionReportProps = {
  firstAccessInfo: CustomStepLabelProps;
  extraAccessInfos: CustomStepLabelProps[];
};

const IpAddressDetectionReport: React.FC<IpAddressDetectionReportProps> = props => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Heading variant="subtitle2">{t("IPアドレス検知")}</Heading>
      <StyledStack direction="column" divider={<Divider />} spacing={1} width="100%">
        <CustomStepLabel {...props.firstAccessInfo} />
        {props.extraAccessInfos.map((extraAccessInfo, index) => {
          return <CustomStepLabel key={`label=${index}`} {...extraAccessInfo} />;
        })}
      </StyledStack>
    </Wrapper>
  );
};

IpAddressDetectionReport.displayName = "IpAddressDetectionReport";

export default IpAddressDetectionReport;
