import { RemoteInterviewForm } from "@hireroo/validator";
import { proxySet } from "valtio/utils";

import { createDefaultState } from "./definitions";
import { state } from "./State";
import type * as Types from "./types";

export const goTo = (step: number) => {
  state.activeStep = step;
};

export const clear = () => {
  const initialValue = createDefaultState();
  state.generalSettings = initialValue.generalSettings;
  state.employeeIds = initialValue.employeeIds;
  state.groupIds = initialValue.groupIds;
  state.selectedQuestionKeys.clear();
  state.unselectedQuestionKeys.clear();
  state.draftTags.clear();

  state.activeStep = initialValue.activeStep;

  state.submitValues = initialValue.submitValues;
};

export const setEmployeeIds = (ids: string[]) => {
  state.employeeIds = ids;
};

export const setGroupIds = (ids: string[]) => {
  state.groupIds = ids;
};

export const setTags = (tags: Types.RemoteTag[]) => {
  tags.forEach(tag => {
    state.tags.set(tag.name, tag);
  });
};

/**
 * An array is passed in the argument each time, so it is always updated.
 */
export const setDraftTags = (tags: string[]) => {
  const newTagSet = proxySet<string>();
  tags.forEach(tag => {
    newTagSet.add(tag);
  });
  state.draftTags = newTagSet;
};

/**
 TODO: We have to filter remote has no entities.
 because we can't fill from this remotes. but we have to change the logic using graphQL or changing server side.
 */
export const initializeRemoteItems = (remoteItems: Types.RemoteItem[]) => {
  remoteItems.forEach(remoteItem => {
    state.remoteItems.set(remoteItem.id, remoteItem);
  });
};

export const addFetchedRemote = (remote: Types.Remote) => {
  state.remotes.set(remote.id, remote);
};

type InitializeRemoteArgs = {
  generalSettings: Types.State["generalSettings"];
  employeeIds: string[];
  groupIds: string[];
  selectedQuestionKeys: string[];
  tags: string[];
};

export const initializeRemote = (args: InitializeRemoteArgs) => {
  state.generalSettings = args.generalSettings;
  state.employeeIds = args.employeeIds;
  state.groupIds = args.groupIds;

  // NOTE: This is important for watch state.
  state.selectedQuestionKeys = proxySet([...args.selectedQuestionKeys]);
  state.draftTags = proxySet([...args.tags]);
};

export const setGeneralSettings = (field: RemoteInterviewForm.GenerateSettingsFormSchema) => {
  state.generalSettings = field;
};

/**
 * initializeUnselectedQuestionByKeys update unselectedQuestionKeys from args.
 */
export const initializeUnselectedQuestionByKeys = (newKeys: string[]) => {
  state.unselectedQuestionKeys.clear();
  newKeys.forEach(key => {
    state.unselectedQuestionKeys.add(key);
  });
};

/**
 * initializeSelectedQuestionByKeys update selectedQuestionKeys from args.
 */
export const initializeSelectedQuestionByKeys = (newKeys: string[]) => {
  state.selectedQuestionKeys.clear();
  newKeys.forEach(key => {
    state.selectedQuestionKeys.add(key);
  });
};

export const setOriginalQuestions = (questions: Types.Question[]) => {
  questions
    .flatMap(question => (question ? [question] : []))
    .forEach(question => {
      state.originalQuestions.set(question.key, question);
    });
};

export const setOriginalQuestionsFromWrapper = (questionWrapper: Types.QuestionWrapper) => {
  questionWrapper.questionObjects
    .flatMap(questionItem => (questionItem.liveCodingQuestion ? [questionItem.liveCodingQuestion] : []))
    .forEach(liveCodingQuestion => {
      state.originalQuestions.set(liveCodingQuestion.key, liveCodingQuestion);
    });
};

/**
 * setSelectQuestionsFromHistory supplement question selection based on questions used in previous remote.
 * In doing so, store values in originalQuestions and selectedQuestion and remove values from unselectedQuestion.
 */
export const setSelectQuestionsFromHistory = (liveCoding: Types.LiveCoding) => {
  clearSelectQuestion();

  (liveCoding?.sessions || []).forEach(session => {
    if (session?.algorithmQuestion) {
      const key = session.algorithmQuestion.key;
      state.selectedQuestionKeys.add(key);
      state.unselectedQuestionKeys.delete(key);
      state.originalQuestions.set(key, session.algorithmQuestion);
    } else if (session?.systemDesignQuestion) {
      const key = session.systemDesignQuestion.key;
      state.selectedQuestionKeys.add(key);
      state.unselectedQuestionKeys.delete(key);
      state.originalQuestions.set(key, session.systemDesignQuestion);
    }
  });
};

/**
 * updateUnselectedQuestion update unselectedQuestionKeys based on the value received in the argument.
 * In doing so, already selected Questions are excluded.
 */
export const updateUnselectedQuestion = (newKeys: string[]) => {
  newKeys
    .filter(key => !state.selectedQuestionKeys.has(key))
    .forEach(key => {
      state.unselectedQuestionKeys.add(key);
    });
};

/**
 * selectQuestion update the selected and unselected key set, respectively.
 * The key selected question is marked as selected and remove from the unselected state.
 */
export const selectQuestion = (key: string) => {
  // Add to selected key set.
  state.selectedQuestionKeys.add(key);
  // Remove new key from unselected keys.
  state.unselectedQuestionKeys.delete(key);
};
/**
 * removeQuestion update the selected and unselected key set, respectively.
 * The key removed question is marked as unselected and remove from the selected state.
 * This func have to add it as the first element, so I have to re-create the set once.
 */
export const removeQuestion = (key: string) => {
  // Create new array for order.
  const unselected = Array.from(state.unselectedQuestionKeys.values());
  unselected.unshift(key);
  state.unselectedQuestionKeys.clear();
  // Add to unselected key set.
  unselected.forEach(key => {
    state.unselectedQuestionKeys.add(key);
  });

  // Remove new key from selected keys.
  state.selectedQuestionKeys.delete(key);
};

/**
 * clearSelectQuestion update as a initial value.
 * Before remove from selectedQuestionKeys, return the selected value as a unselected value.
 */
export const clearSelectQuestion = () => {
  const selected = Array.from(state.selectedQuestionKeys.values());
  selected.forEach(key => {
    state.unselectedQuestionKeys.add(key);
  });
  state.selectedQuestionKeys.clear();
};

/**
 * clearUnselectQuestion update as a initial value.
 */
export const clearUnselectQuestion = () => {
  state.unselectedQuestionKeys.clear();
};

export const fetchRemoteLoadingStatus = (isLoading: boolean) => {
  state.isFetchRemoteLoading = isLoading;
};

export const createRemoteLoadingStatus = (isLoading: boolean) => {
  state.isCreateRemoteLoading = isLoading;
};

export const updateRemoteLoadingStatus = (isLoading: boolean) => {
  state.isUpdateRemoteLoading = isLoading;
};

export const listQuestionsLoadingStatus = (status: Types.ListStatus) => {
  state.listQuestionStatus = status;
};

export const setSubmitValue = <T extends keyof Types.SubmitValues>(stepName: T, value: Types.SubmitValues[T]) => {
  state.submitValues[stepName] = value;
};
