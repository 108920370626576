import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Interview): void => {
  state.interview = newValue;
  const newEmployeeIds: string[] = [];
  const newEmployeeGroupIds: string[] = [];
  newValue.viewers.forEach(viewer => {
    if (viewer.__typename === "User") {
      newEmployeeIds.push(viewer.uid);
    } else if (viewer.__typename === "EmployeeGroup") {
      newEmployeeGroupIds.push(viewer.employeeGroupId);
    }
  });
  state.selectedEmployeeIds = newEmployeeIds;
  state.selectedGroupIds = newEmployeeGroupIds;
  state.defaultSelectedEmployeeIds = newEmployeeIds;
  state.defaultSelectedGroupIds = newEmployeeGroupIds;
};

export const setRefresh = (callback: Types.RefreshCallback) => {
  state.refresh = ref(callback);
};

export const updateInterview = (newValue: Types.Interview): void => {
  state.interview = newValue;
};

export const updateSelectedEmployeeIds = (newValue: string[]) => {
  state.selectedEmployeeIds = newValue;
};

export const updateSelectedGroupIds = (newValue: string[]) => {
  state.selectedGroupIds = newValue;
};

export const updateTags = (newValue: string[]) => {
  state.tags = newValue;
};
