import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import * as React from "react";

type Question = Widget.RemoteInterviewOverviewProps["questions"][0];

export type GenerateQuestionsPropsArgs = {};

export const useGenerateQuestionsProps = (_args: GenerateQuestionsPropsArgs): Widget.RemoteInterviewOverviewProps["questions"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const interview = RemoteInterviewOverview.useInterview();

  const questions = React.useMemo((): Question[] => {
    const items: Question[] = [];
    for (const session of interview.entity?.liveCoding?.sessions || []) {
      if (!session) {
        continue;
      }
      if (session.questionType === "ALGORITHM" && session.algorithmQuestion) {
        const question = session.algorithmQuestion;
        items.push({
          id: session.id,
          variantText: t("コーディング形式"),
          version: question.version ?? INITIAL_VERSION,
          title: resolveLanguage(question, lang, "title"),
          difficultyStars: {
            difficulty: question.difficulty,
          },
          detailButton: {
            href: generatePath("/e/questions/challenge/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            }),
          },
          isArchived: question.status === "ARCHIVED",
        });
      } else if (session.questionType === "SYSTEM_DESIGN" && session.systemDesignQuestion) {
        const question = session.systemDesignQuestion;
        items.push({
          id: session.id,
          variantText: t("システムデザイン形式"),
          version: INITIAL_VERSION,
          title: resolveLanguage(question, lang, "title"),
          difficultyStars: {
            difficulty: question.difficulty,
          },
          detailButton: {
            href: generatePath("/e/questions/systemdesign/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
            }),
          },
          isArchived: question.status === "ARCHIVED",
        });
      }
    }
    return items;
  }, [interview.entity?.liveCoding?.sessions, lang, t]);

  return questions;
};
