import type { Widget } from "@hireroo/presentation";

export type GenerateChallengeReadonlyCodeEditorPropsArgs = {
  runtime: string;
  code: string;
};

export const useGenerateProps = (args: GenerateChallengeReadonlyCodeEditorPropsArgs): Widget.ChallengeReadonlyCodeEditorProps => {
  return {
    language: args.runtime,
    value: args.code,
  };
};
