import { fetchLiveCodingRevisionHistories } from "@hireroo/app-helper/remote";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import * as Def from "@hireroo/challenge/definition";
import { ChallengeCodingEditorProvider, ChallengeCodingEditorProviderProps } from "@hireroo/challenge/store";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import RemoteInterviewChallengePlaybackContainer, { RemoteInterviewChallengePlaybackContainerProps } from "./Container";
import { useGenerateContentLeftSidePanelProps } from "./useGenerateContentLeftSidePanelProps";

const challengeAcceptableVariantMap: Record<Graphql.LiveCodingQuestionVariant, "DATABASE" | "ALGORITHM" | "CLASS"> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  SYSTEM_DESIGN: "ALGORITHM",
  UNKNOWN: "ALGORITHM",
};

const defaultLanguageListMap: Record<Graphql.LiveCodingQuestionVariant, string[]> = {
  ALGORITHM: Def.algorithmLanguageListMap.map(({ value }) => value).sort(),
  DATABASE: Def.sqlMapForDisplay.map(({ value }) => value).sort(),
  CLASS: Def.classLanguageForDisplay.map(({ value }) => value).sort(),
  SYSTEM_DESIGN: [],
  UNKNOWN: [],
};

const defaultLanguageMap: Record<Graphql.LiveCodingQuestionVariant, string> = {
  ALGORITHM: Def.defaultLanguageMap["ALGORITHM"],
  DATABASE: Def.defaultLanguageMap["DATABASE"],
  CLASS: Def.defaultLanguageMap["CLASS"],
  SYSTEM_DESIGN: "",
  UNKNOWN: "",
};

export type RemoteInterviewChallengePlaybackInitialContainerProps = {};

const RemoteInterviewChallengePlaybackInitialContainer: React.FC<RemoteInterviewChallengePlaybackInitialContainerProps> = () => {
  const activeSessionId = RemoteInterviewPlayback.useActiveSessionId();
  const normalizedActiveSession = RemoteInterviewPlayback.useNormalizedActiveSession();
  const runtimes = RemoteInterviewPlayback.useRuntimes();
  const selectedEditorOption = RemoteInterviewPlayback.useSelectedEditorOption();
  const containerProps: RemoteInterviewChallengePlaybackContainerProps = {};
  const [liveCodingId] = React.useState<number | undefined>(normalizedActiveSession?.liveCodingId);
  const hasPlaybackManager = RemoteInterviewPlayback.useHasPlaybackManager();
  const isInitialized = RemoteInterviewPlayback.useChallengeHistoriesInitialized();
  const leftSidePanel = useGenerateContentLeftSidePanelProps();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (liveCodingId) {
      fetchLiveCodingRevisionHistories({
        liveCodingId: liveCodingId,
        sessionId: activeSessionId,
      })
        .then(histories => {
          RemoteInterviewPlayback.initializeRevisionHistories(histories);
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    }
  }, [activeSessionId, liveCodingId]);

  React.useEffect(() => {
    return () => {
      RemoteInterviewPlayback.clearChallenge();
    };
  }, []);
  const enabledLanguages = React.useMemo(() => {
    if (runtimes.length > 0) {
      return runtimes;
    }
    return defaultLanguageListMap[normalizedActiveSession?.variant ?? "UNKNOWN"];
  }, [runtimes, normalizedActiveSession?.variant]);

  const defaultLanguage = React.useMemo(() => {
    if (runtimes.length > 0) {
      return selectedEditorOption;
    }
    return defaultLanguageMap[normalizedActiveSession?.variant ?? "UNKNOWN"];
  }, [runtimes.length, normalizedActiveSession?.variant, selectedEditorOption]);

  const challengeProvider: ChallengeCodingEditorProviderProps = {
    id: normalizedActiveSession?.liveCodingSessionId.toString(),
    variant: challengeAcceptableVariantMap[normalizedActiveSession?.variant ?? "ALGORITHM"],
    enableLanguages: enabledLanguages.slice(),
    defaultLanguage: defaultLanguage,
    options: {
      disableSetStateOnUnmount: true,
    },
  };
  const emptyContainerProps: Widget.RemoteInterviewEntityPlaybackProps = {
    content: {
      leftSidePanel: leftSidePanel,
      Editor: <Widget.RemoteInterviewChallengePlaybackEditor options={{ readOnly: true, minimap: { enabled: false } }} />,
    },
    playbackToolbar: {
      disabledAll: true,
      value: 0,
      slider: {
        max: 0,
        min: 0,
      },
      onChangePlaybackValue: () => {},
      enableAutoplay: true,
      defaultExpandStatus: "EXPAND_MORE",
      remainTime: "00:00",
      passedTime: "00:00",
      participantSliders: [],
    },
  };

  if (!normalizedActiveSession) {
    return t("廃止された機能が利用されているため、表示することができません。");
  }
  if (!isInitialized) return <Widget.Loading kind="CENTER_%" />;

  if (!hasPlaybackManager) return <Widget.RemoteInterviewEntityPlayback {...emptyContainerProps} />;

  return (
    <ErrorBoundary>
      <ChallengeCodingEditorProvider {...challengeProvider}>
        <RemoteInterviewChallengePlaybackContainer {...containerProps} />
      </ChallengeCodingEditorProvider>
    </ErrorBoundary>
  );
};
RemoteInterviewChallengePlaybackInitialContainer.displayName = "RemoteInterviewChallengePlaybackInitialContainer";

export default withErrorBoundary(RemoteInterviewChallengePlaybackInitialContainer, {});
