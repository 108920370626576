import { FetchIpAddressSchema } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import { backOff } from "exponential-backoff";
import * as React from "react";

export type GeoLocationData = {
  ip: string;
  city: string;
  country: string;
};

export type FetchIpAddressResponse = {
  ipAddress: string;
  geolocation: string;
};

export const useFetchIpAddress = (): FetchIpAddressResponse | undefined => {
  const [fetchIpAddressResponse, setFetchIpAddressResponse] = React.useState<FetchIpAddressResponse | null>(null);
  React.useEffect(() => {
    /**
     *  public API for fetching a user's IP address:
     *  https://ipinfo.io/
     *  https://ipinfo.io/developers/responses#free-plan
     */
    if (fetchIpAddressResponse === null) {
      backOff(
        () =>
          /**
           * ipinfoに渡すTokenはサインインしたユーザーに紐づくため、ipinfoのサービスを管理するユーザーのtokenを指定すること
           */
          fetch(`https://ipinfo.io/json?token=${import.meta.env.VITE_IP_INFO_TOKEN}`)
            .then(response => response.json())
            .then(jsonResponse => {
              const result = FetchIpAddressSchema.ipAddressSchema.safeParse(jsonResponse);
              if (result.success) {
                if (!result.data.ip || !result.data.city || !result.data.country) {
                  throw new Error(
                    `response includes empty fields in parsed result: ${JSON.stringify(result)}, json full response: ${JSON.stringify(
                      jsonResponse,
                    )}\n`,
                  );
                }
                let geolocation = "";
                /** check if city field exists, otherwise just return country */
                result.data.city ? (geolocation = result.data.city + ", " + result.data.country) : result.data.country;
                setFetchIpAddressResponse({
                  ipAddress: result.data.ip,
                  geolocation: geolocation,
                });
              } else {
                throw new Error(
                  `could not safely parse response: ${JSON.stringify(jsonResponse)}, zod error: ${JSON.stringify(result.error)}\n`,
                );
              }
            })
            .catch(error => {
              /** No need to output log here to retry */
              throw error;
            }),
        {
          timeMultiple: 1,
          /**
           * 5 x 1000ms = 5sec
           */
          numOfAttempts: 5,
          maxDelay: 1000,
          startingDelay: 3000,
        },
      ).catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [fetchIpAddressResponse]);

  if (fetchIpAddressResponse !== null) {
    return fetchIpAddressResponse;
  }
};
