import { TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { useEnabledPlaybackUpsell } from "@hireroo/app-helper/feature";
import { composeTextOperationV2, revisionToId } from "@hireroo/app-helper/firepad";
import { invertTextOperation } from "@hireroo/app-helper/hooks";
import { applyOperation, IStandaloneCodeEditor, ITextModel, useMonaco } from "@hireroo/app-helper/monaco";
import {
  CodeEditorInputEvent,
  createPasteEventMap,
  decoratePlaybackText,
  HistoryPasteMap,
  isBehavioralEvent,
  PasteEventMap,
  PasteRangeMap,
} from "@hireroo/app-helper/playback";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { useCursorWidgetManager } from "@hireroo/code-editor/extensions";
import { getCursorRange } from "@hireroo/code-editor/helpers/monaco";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import UpsellWithVideoContainer from "../../e/UpsellWithVideo/Container";
import ChallengePlaybackSettingsMenu, { ChallengePlaybackSettingsMenuContainerProps } from "./widgets/ChallengePlaybackSettingsMenu/Container";
import EventFrequencyTimelinePanelContainer from "./widgets/EventFrequencyTimelinePanel/Container";
import PageLeaveHistoryPanelContainer from "./widgets/PageLeaveHistoryPanel/Container";
import ReportChallengePlaybackRightSidePanelContainer from "./widgets/ReportChallengePlaybackRightSidePanel/Container";
import RunCodeEventFrequencyTimelinePanelContainer from "./widgets/RunCodeEventFrequencyTimelinePanel/Container";
import ScreeningTestActivityLogContainer from "./widgets/ScreeningTestActivityLog/Container";
import SearchHistoryPanelContainer from "./widgets/SearchHistoryPanel/Container";
import TimeReportPanelContainer from "./widgets/TimeReportPanel/Container";
import UseHintEventFrequencyTimelinePanelContainer from "./widgets/UseHintEventFrequencyTimelinePanel/Container";

type PlayBack = Exclude<Widget.ChallengePlaybackProps["playBack"], undefined>;

type Mark = Exclude<PlayBack["toolbar"]["slider"]["marks"], undefined | boolean>[0];
type Status = PlayBack["status"];
type PlaybackAccess = "AVAILABLE" | "LOCKED" | "NOT_SHOWN";

/**
 * TODO @Himenon Make the colors change according to the Code Editor theme
 */
const CURSOR_COLOR = "#838DFF";

const CODING_PLAYBACK_INTERVAL_MILLISECONDS = 100;
const BEHAVIORAL_EVENT_PLAYBACK_INTERVAL_MILLISECONDS = 500;

export type GenerateChallengePlaybackPropsArgs = {
  challengeId: number;
  question: ChallengePlayback.AlgorithmQuestion;
  submissionId: number;
  playbackAccess: PlaybackAccess;
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
  SuspiciousDegreeMessageForQuestion: React.ReactNode;
};

export const useGenerateProps = (args: GenerateChallengePlaybackPropsArgs): Widget.ChallengePlaybackProps => {
  const enabledPlaybackUpsell = useEnabledPlaybackUpsell();
  const { t } = useTranslation();
  const monacoEditorRef = React.useRef<IStandaloneCodeEditor[]>([]);
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const [passedTime, setPassedTime] = React.useState("");
  const submission = ChallengePlayback.useSubmission();
  const sliderValue = ChallengePlayback.useSliderValue();
  const editorMode = ChallengePlayback.useEditorMode();
  const isSessionIssued = ChallengePlayback.useIsSessionIssued();
  const appealMessage = ChallengePlayback.useAppealMessage();
  const hasBehavioralEvent = ChallengePlayback.useHasBehavioralEvent();
  const playbackSettings = ChallengePlayback.usePlaybackSettings();
  const enabledWebSearch = ChallengePlayback.useEnabledWebSearch();
  const codeEditorInputEvents = ChallengePlayback.useCodeEditorInputEvents();
  const lastCodeEditorInputEventsIndex = ChallengePlayback.useLastCodeEditorInputEventsIndex();
  const cursorWidgetManager = useCursorWidgetManager();
  const monaco = useMonaco();
  const createModel = React.useCallback(() => {
    const defaultValue = composeTextOperationV2(lastCodeEditorInputEventsIndex, codeEditorInputEvents);
    if (!monaco) {
      return null;
    }
    return monaco.editor.createModel(defaultValue, languageMapForHighlight[submission.runtime]);
  }, [monaco, lastCodeEditorInputEventsIndex, codeEditorInputEvents, submission.runtime]);

  const modelForDefaultScreen = React.useMemo(() => {
    return createModel();
  }, [createModel]);

  const modelForFullScreen = React.useMemo(() => {
    return createModel();
  }, [createModel]);

  const monacoEditorModels = React.useMemo((): ITextModel[] => {
    if (modelForDefaultScreen && modelForFullScreen) {
      return [modelForDefaultScreen, modelForFullScreen];
    }
    return [];
  }, [modelForDefaultScreen, modelForFullScreen]);

  const editorDecorations = React.useMemo((): Map<ITextModel, string[]> => {
    const decrationMap = new Map<ITextModel, string[]>();
    monacoEditorModels.forEach(model => {
      decrationMap.set(model, []);
    });
    return decrationMap;
  }, [monacoEditorModels]);

  const clipboardEvents = ChallengePlayback.useClipboardEvents();

  /**
   * Get the paste event map from submission and form it into a map with history revision mapped to paste events.
   */
  const pasteEventMap = React.useMemo((): PasteEventMap => {
    const targets = playbackManager.ticks.reduce<Record<string, CodeEditorInputEvent>>((all, tick, index) => {
      const codeEditorInputEvent = tick.events.find(event => event.kind === "CODE_EDITOR") as CodeEditorInputEvent | undefined;
      if (codeEditorInputEvent) {
        return { ...all, [revisionToId(index)]: codeEditorInputEvent };
      }
      return all;
    }, {});
    const { pasteEventMap } = createPasteEventMap(clipboardEvents, targets);
    return pasteEventMap;
  }, [playbackManager, clipboardEvents]);

  const historyPasteMap = React.useMemo((): HistoryPasteMap => {
    const result: HistoryPasteMap = {};
    let previousPasteSelection: PasteRangeMap = {};
    codeEditorInputEvents.forEach((codeEditorInputEvent, index) => {
      const pasteSelection: PasteRangeMap = { ...previousPasteSelection };
      for (const pasteEventKey in pasteEventMap) {
        const key = revisionToId(index);
        if (codeEditorInputEvent && pasteEventMap[pasteEventKey].histories[key]) {
          pasteSelection[pasteEventKey] = pasteEventMap[pasteEventKey].histories[key];
        }
      }
      result[index] = pasteSelection;
      previousPasteSelection = pasteSelection;
    });
    return result;
  }, [codeEditorInputEvents, pasteEventMap]);

  const status = React.useMemo((): Status => {
    if (codeEditorInputEvents.length === 0) {
      return "NO_DATA";
    }
    return "READY";
  }, [codeEditorInputEvents.length]);

  const remainTime = React.useMemo(() => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [playbackManager]);

  const updateDecoration = React.useCallback(
    (sliderIndex: number) => {
      if (!monaco) {
        return;
      }
      if (historyPasteMap && sliderIndex in historyPasteMap) {
        monacoEditorModels.forEach(model => {
          const decorations = editorDecorations.get(model) || [];
          const newDecorations = decoratePlaybackText(monaco, model, decorations, historyPasteMap[sliderIndex]);
          editorDecorations.set(model, newDecorations);
        });
      }
    },
    [monaco, monacoEditorModels, editorDecorations, historyPasteMap],
  );

  React.useEffect(() => {
    const cleanupReceiveTickEvent = playbackManager.onReceiveTickEvent(() => {
      const startTime = playbackManager.timeStamps.at(0) ?? 0;
      const currentTime = playbackManager.currentTimeStamp ?? 0;
      setPassedTime(Time.elapsedTimeFormat(currentTime - startTime));
    });
    playbackManager.refresh();
    if (!monaco) {
      return () => {
        cleanupReceiveTickEvent();
      };
    }
    const cleanupMoveForward = playbackManager.onMoveForward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < sliderIndex - tickIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex + step + 1);
        if (revision) {
          monacoEditorModels.forEach(model => {
            applyOperation(revision.textOperations, monaco, model);
            const range = getCursorRange(revision.textOperations, monaco, model);
            monacoEditorRef.current.forEach(editor => {
              editor.revealLineInCenter(range.startLineNumber);
            });
            cursorWidgetManager.updateCursor({ cursorId: revision.userId, range, cursorColor: CURSOR_COLOR });
          });
        }
      }
    });

    const cleanupMoveBackward = playbackManager.onMoveBackward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < tickIndex - sliderIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex - step);
        if (revision) {
          const content = composeTextOperationV2(tickIndex - step - 1, codeEditorInputEvents);
          const inverseOp = invertTextOperation(revision.textOperations, content);
          monacoEditorModels.forEach(model => {
            const range = getCursorRange(revision.textOperations, monaco, model);
            monacoEditorRef.current.forEach(editor => {
              editor.revealLineInCenter(range.startLineNumber);
            });
            cursorWidgetManager.updateCursor({
              cursorId: revision.userId,
              range,
              cursorColor: CURSOR_COLOR,
            });
            applyOperation(inverseOp, monaco, model);
          });
        }
      }
    });

    const cleanupMove = playbackManager.onMove(payload => {
      updateDecoration(payload.nextIndex);
    });

    return () => {
      cleanupReceiveTickEvent();
      cleanupMoveForward();
      cleanupMoveBackward();
      cleanupMove();
    };
  }, [t, cursorWidgetManager, updateDecoration, playbackManager, monaco, monacoEditorModels, codeEditorInputEvents, historyPasteMap]);

  React.useEffect(() => {
    updateDecoration(lastCodeEditorInputEventsIndex);
  }, [updateDecoration, lastCodeEditorInputEventsIndex]);

  const showRightSidePanel: boolean = isSessionIssued && hasBehavioralEvent && playbackSettings.enabledBehavioralControl;

  const handleChangeSliderValue = React.useCallback(
    (value: number, isTouchedPlay?: boolean) => {
      ChallengePlayback.updateSliderValue(value);
      if (!isTouchedPlay) {
        playbackManager.setTickIndex(value);
      }
    },
    [playbackManager],
  );

  const playbackIntervalMilliseconds = React.useMemo(() => {
    const tick = playbackManager.ticks.at(sliderValue);
    const hasBehavioralEvent = !!tick?.events.some(event => isBehavioralEvent(event));
    return hasBehavioralEvent ? BEHAVIORAL_EVENT_PLAYBACK_INTERVAL_MILLISECONDS : CODING_PLAYBACK_INTERVAL_MILLISECONDS;
  }, [playbackManager, sliderValue]);

  const playbackSwitcherProps = React.useMemo((): Widget.ChallengePlaybackProps["playbackSwitcher"] => {
    if (args.playbackAccess === "NOT_SHOWN") {
      return;
    }

    if (enabledPlaybackUpsell) {
      const canShowPlayback = args.playbackAccess === "AVAILABLE" || args.playbackAccess === "LOCKED";
      return {
        enableMode: (["SUBMIT_RESULT", canShowPlayback && "PLAYBACK"] as const).filter(enableMode => !!enableMode),
        onDialogClose: () => {},
        onChangeMode: () => {},
      };
    }
    const canShowPlayback = args.playbackAccess === "AVAILABLE";
    return {
      enableMode: (["SUBMIT_RESULT", canShowPlayback && "PLAYBACK"] as const).filter(enableMode => !!enableMode),
      onDialogClose: () => {},
      onChangeMode: () => {},
    };
  }, [args.playbackAccess, enabledPlaybackUpsell]);

  const challengePlaybackSettingsMenu: ChallengePlaybackSettingsMenuContainerProps = React.useMemo(
    () => ({
      canShowPasteAndTabStatistics: args.canShowPasteAndTabStatistics,
    }),
    [args.canShowPasteAndTabStatistics],
  );

  const canShowSettingsMenu = React.useMemo(() => {
    return args.canShowPasteAndTabStatistics || isSessionIssued;
  }, [args.canShowPasteAndTabStatistics, isSessionIssued]);

  const markMapByTickIndex = React.useMemo((): Map<number, { texts: string[]; show: boolean }> => {
    const markMap = new Map<number, { texts: string[]; show: boolean }>();
    const eventCountMap: Record<string, number | undefined> = {};
    playbackManager.ticks.forEach((tick, index) => {
      const target = markMap.get(index) || { texts: [], show: false };
      const addLabel = (text: string, show?: boolean) => {
        target.texts.push(text);
        target.show = !!show;
        markMap.set(index, target);
      };
      const uniqueEventSet = new Set<string>();
      tick.events.forEach(event => {
        if (uniqueEventSet.has(event.kind)) {
          return;
        }
        uniqueEventSet.add(event.kind);
        const previousCount = eventCountMap[event.kind] || 1;
        switch (event.kind) {
          case "ACCESS": {
            addLabel(`${t("IPアドレス検知")} ${previousCount}`);
            break;
          }
          case "USE_HINT": {
            addLabel(`${t("ヒント")} ${previousCount}`);
            break;
          }
          case "SUBMIT_QUESTION": {
            addLabel(`${t("提出")} ${previousCount}`, true);
            break;
          }
          case "RUN_CODE": {
            addLabel(`${t("コードの実行")} ${previousCount}`);
            break;
          }
          case "EDITOR_PASTE": {
            if (playbackSettings.enabledCopyAndPasteDetection) {
              addLabel(`${t("ペースト")} ${previousCount}`, playbackSettings.enabledCopyAndPasteDetection);
            }
            break;
          }
          case "WEB_SITE_SEARCH": {
            addLabel(`${t("Google検索")} ${previousCount}`);
            break;
          }
          case "CHATGPT_REQUEST": {
            addLabel(`ChatGPT ${previousCount}`);
            break;
          }
          case "EXTERNAL_WEB_SITE_ACCESS": {
            addLabel(`${"アクセス"} ${previousCount}`);
            break;
          }
          case "BROWSER_BLUR": {
            addLabel(`${t("ページ離脱")} ${previousCount}`);
            break;
          }
          case "BROWSER_HIDDEN": {
            addLabel(`${t("ページ離脱")} ${previousCount}`);
            break;
          }
          case "BROWSER_FOCUS": {
            addLabel(`${t("ページ再表示")} ${previousCount}`);
            break;
          }
        }
        eventCountMap[event.kind] = previousCount + 1;
      });
    });
    return markMap;
  }, [t, playbackManager, playbackSettings.enabledCopyAndPasteDetection]);

  const valueLabelFormat = React.useCallback(
    (value: number): React.ReactNode => {
      const target = markMapByTickIndex.get(value);
      const tick = playbackManager.ticks.at(value);
      const timeLabel = tick ? Time.unixTimeMilliSecondsToFormat(tick.ts, "yyyy/MM/dd HH:mm:ss") : "";
      if (!target) {
        return timeLabel;
      }
      return [timeLabel, ...target.texts].filter(Boolean).join(" ");
    },
    [markMapByTickIndex, playbackManager],
  );

  const playbackProps = React.useMemo((): Widget.ChallengePlaybackProps["playBack"] => {
    if (args.playbackAccess !== "AVAILABLE") {
      return;
    }
    const marks = Array.from(markMapByTickIndex.entries()).map(([tickIndex, target]): Mark => {
      return {
        value: tickIndex,
        label: target.show ? target.texts.join(", ") : undefined,
      };
    });
    return {
      monaco,
      modelForDefaultScreen,
      modelForFullScreen,
      showPasteRange: playbackSettings.enabledCopyAndPasteDetection,
      onChangeSliderValue: handleChangeSliderValue,
      onEditorMount: editor => {
        monacoEditorRef.current.push(editor);
        editor.onDidDispose(() => {
          monacoEditorRef.current = monacoEditorRef.current.filter(editorRef => editorRef === editor);
        });
        cursorWidgetManager.initCursorWidgetController(editor);
      },
      toolbar: {
        value: sliderValue,
        slider: {
          min: 0,
          max: playbackManager.lastTickIndex,
          marks: marks,
          valueLabelFormat: valueLabelFormat,
          valueLabelDisplay: "auto",
        },
        remainTime: remainTime,
        passedTime: passedTime,
        onChangePlayStatus: (status: "PLAY" | "PAUSE") => {
          ChallengePlayback.updatePlayStatus(status);
        },
        SettingsMenu: canShowSettingsMenu ? <ChallengePlaybackSettingsMenu {...challengePlaybackSettingsMenu} /> : null,
        autoPlayIntervalMilliseconds: playbackIntervalMilliseconds,
      },
      status: status,
      showRightSidePanel: showRightSidePanel,
      RightSidePanel: isSessionIssued ? <ReportChallengePlaybackRightSidePanelContainer /> : null,
      ActivityTimelineLog: <ScreeningTestActivityLogContainer />,
      StatisticsContents: [
        <TimeReportPanelContainer key="time-report" />,
        <EventFrequencyTimelinePanelContainer key="event-frequency" />,
        <RunCodeEventFrequencyTimelinePanelContainer key="run-code" />,
        enabledWebSearch && <SearchHistoryPanelContainer key="search-history" />,
        <PageLeaveHistoryPanelContainer key="leave" />,
        <UseHintEventFrequencyTimelinePanelContainer key="hint" />,
      ],
    };
  }, [
    isSessionIssued,
    args.playbackAccess,
    markMapByTickIndex,
    monaco,
    modelForDefaultScreen,
    modelForFullScreen,
    playbackSettings.enabledCopyAndPasteDetection,
    handleChangeSliderValue,
    sliderValue,
    playbackManager.lastTickIndex,
    valueLabelFormat,
    remainTime,
    passedTime,
    canShowSettingsMenu,
    challengePlaybackSettingsMenu,
    status,
    showRightSidePanel,
    enabledWebSearch,
    cursorWidgetManager,
    playbackIntervalMilliseconds,
  ]);

  const initialCodeMap = args.question.initialCode;

  const initialCode = React.useMemo((): string => {
    if (!initialCodeMap || !(submission.runtime in initialCodeMap)) {
      return "";
    }
    const runtime = submission.runtime;
    // FIXME The runtime needs to have a type definition equivalent to initialCode.
    return (initialCodeMap as Record<string, string>)[runtime] ?? "";
  }, [initialCodeMap, submission.runtime]);

  return {
    sliderValue: sliderValue,
    editorMode: editorMode,
    updateEditorMode: mode => {
      ChallengePlayback.updateEditorMode(mode);
    },
    targetElementId: TargetElementIdMap.PLAYBACK_SECTION,
    appealMessage: appealMessage || undefined,
    playbackSubmitCodeView: {
      runtime: submission.runtime,
      initialCode: initialCode,
      submitCode: submission.code,
    },
    playbackSwitcher: playbackSwitcherProps,
    playBack: playbackProps,
    PlaybackUpsellWithVideo:
      args.playbackAccess === "LOCKED" && enabledPlaybackUpsell ? <UpsellWithVideoContainer kind="CHALLENGE_PLAYBACK" /> : undefined,
    SuspiciousDegreeMessageForQuestion: args.SuspiciousDegreeMessageForQuestion,
  };
};
