import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import ScreeningTestTimelimitContainer from "../../shared/ScreeningTestTimelimit/Container";

type ShowingMode = "RUNNING" | "READONLY";

type HelpOption = Widget.ScreeningTestHeaderProps["menu"]["help"]["options"][0];

export type GenerateScreeningTestHeaderPropsArgs = {
  showingMode: ShowingMode;
  onEndInterview?: () => void;
  willBlockEndInterview: boolean;
  disabledFinishButton: boolean;
  messageForCandidate?: string;
};

export const useGenerateProps = (args: GenerateScreeningTestHeaderPropsArgs): Widget.ScreeningTestHeaderProps => {
  const entities = InterviewNavigation.useEntities();
  const entityMap = InterviewNavigation.useEntityMap();
  const currentEntityKey = InterviewNavigation.useCurrentSelectedEntityKey();
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const [disabledChangeQuestion, setDisabledChangeQuestion] = React.useState(false);
  const { t: t2 } = useTranslationWithVariable();

  const helpOptions: (HelpOption | false)[] = [
    args.showingMode === "RUNNING" && {
      text: t("チュートリアル再開"),
      value: "tutorial",
      icon: "LIVE_HELP",
      onClick: () => {
        ScreeningTestTutorial.updateRunTutorial(true);
      },
    },
    args.showingMode === "RUNNING" && {
      text: t("企業からのメッセージ"),
      value: "caution",
      icon: "MESSAGE",
      onClick: () => {
        setMessageOpen(true);
      },
      tooltip: !args.messageForCandidate ? t("企業からのメッセージがありません") : undefined,
      disabled: !args.messageForCandidate,
    },
    {
      text: t("ヘルプセンター"),
      value: "help-center",
      icon: "INFO",
      onClick: () => {
        helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
      },
    },
  ];

  return {
    endTestDialog: (args.showingMode === "RUNNING" || undefined) && {
      open,
      yesButton: {
        onClick: () => {
          args.onEndInterview?.();
          setOpen(false);
        },
      },
      noButton: {
        onClick: () => {
          setOpen(false);
        },
      },
    },
    questionSwitcher: (args.showingMode === "RUNNING" || undefined) && {
      value: currentEntityKey ?? "",
      disabled: false,
      items: entities.map((entity, index) => {
        return {
          value: entity.key,
          disabled: disabledChangeQuestion,
          status: entity.completed ? "COMPLETED" : "PENDING",
          tooltipText: resolveLanguage(entity, lang, "title"),
          displayText: t2("QuestionNumber", { num: index + 1 }),
        };
      }),
      onChange: value => {
        setDisabledChangeQuestion(true);
        // 問題の切り替えの連打防止の為、遅延を設ける
        window.setTimeout(() => {
          setDisabledChangeQuestion(false);
        }, 500);
        const entity = entityMap[value];
        if (!entity) {
          return;
        }
        InterviewNavigation.setSelectedEntityKey(value);
      },
    },
    Timelimit: args.showingMode === "RUNNING" && <ScreeningTestTimelimitContainer />,
    menu: {
      finishButton: (args.showingMode === "RUNNING" || undefined) && {
        onClick: () => {
          if (args.willBlockEndInterview) {
            setOpen(true);
          } else {
            args.onEndInterview?.();
          }
        },
        disabled: args.disabledFinishButton,
      },
      help: {
        options: helpOptions.flatMap(v => (v ? [v] : [])),
      },
      messageDialog: {
        open: messageOpen,
        onClose: () => {
          setMessageOpen(false);
        },
      },
      messageForCandidate: args.showingMode === "RUNNING" ? args.messageForCandidate : undefined,
      item: {
        type: "MANY",
        content: {},
      },
    },
  };
};
