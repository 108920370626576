import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import { useQuestionSearchAndSelectableAreaContext } from "./Context";
import QuestionSearchHeader, { QuestionSearchHeaderProps } from "./parts/QuestionSearchHeader/QuestionSearchHeader";
import SelectableQuestionList, { SelectableQuestionListProps } from "./parts/SelectableQuestionList/SelectableQuestionList";
import SortableQuestionList, { SortableQuestionListProps } from "./parts/SortableQuestionList/SortableQuestionList";
import SortableQuestionListHeader, { SortableQuestionListHeaderProps } from "./parts/SortableQuestionListHeader/SortableQuestionListHeader";

const WRAPPER_MAX_WIDTH = 1240;
const SIDEBAR_WIDTH = 280;
const COLUMN_SPACE_WIDTH = 4;

const StyledStack = styled(Stack)(() => ({
  height: "100%",
  width: "100%",
  maxWidth: WRAPPER_MAX_WIDTH,
}));

const Column = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  flexShrink: 0,
}));

const QuestionSelectColumnScrollableContent = styled(Box)(() => ({
  display: "flex",
  overflowY: "auto",
  width: "100%",
  flexShrink: 0,
}));

const ColumnScrollableContent = styled(Box)(() => ({
  display: "flex",
  overflowY: "auto",
  /** List Header Height is 40px, Table Pagination Height is 52px */
  height: `calc(100% - 92px);`,
  width: "100%",
  flexShrink: 0,
}));

const CONTENT_PAGINATION_HEIGHT = 52;

const StyledTablePagination = styled(TablePagination)(() => ({
  width: "100%",
  height: CONTENT_PAGINATION_HEIGHT,
  flexShrink: 0,
}));

const CenteredBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexShrink: 0,
  alignItems: "center",
}));

type ContentProps = {
  mode: "QUESTION_SEARCH";
  header: QuestionSearchHeaderProps;
  pagination: TablePaginationProps;
};

export {
  QuestionSearchAndSelectableAreaContext,
  QuestionSearchAndSelectableAreaProvider,
  type QuestionSearchAndSelectableAreaProviderProps,
  useQuestionSearchAndSelectableAreaContext,
} from "./Context";

export type QuestionSearchAndSelectableAreaProps = {
  loading: boolean;

  content: ContentProps;

  selectableQuestionList: Pick<SelectableQuestionListProps, "items">;

  sortableQuestionListHeader: SortableQuestionListHeaderProps;
  sortableQuestionList: Pick<SortableQuestionListProps, "entitySources">;
};

const QuestionSearchAndSelectableArea: React.FC<QuestionSearchAndSelectableAreaProps> = props => {
  const { selectedEntitySources: fieldValue, maxAddableQuestionCount } = useQuestionSearchAndSelectableAreaContext();
  const headerDetection = useResizeDetector({
    handleWidth: false,
  });

  const selectableQuestionListProps: SelectableQuestionListProps = {
    ...props.selectableQuestionList,
    disabledAll: maxAddableQuestionCount <= fieldValue.length,
  };

  const sortableQuestionListProps: SortableQuestionListProps = {
    ...props.sortableQuestionList,
  };

  const sortableQuestionListHeaderProps: SortableQuestionListHeaderProps = {
    ...props.sortableQuestionListHeader,
  };

  const usedHeight: number = (headerDetection.height ?? 0) + (props.content.mode === "QUESTION_SEARCH" ? CONTENT_PAGINATION_HEIGHT : 0);

  const columnWidth = `calc(50% - ${(2 * COLUMN_SPACE_WIDTH) / 2}px)`;
  const wrapperWidth = WRAPPER_MAX_WIDTH - SIDEBAR_WIDTH;

  return (
    <StyledStack direction="row" spacing={1} sx={{ width: wrapperWidth }}>
      <Column sx={{ width: columnWidth }}>
        <Box ref={headerDetection.ref}>{props.content.mode === "QUESTION_SEARCH" && <QuestionSearchHeader {...props.content.header} />}</Box>
        <QuestionSelectColumnScrollableContent sx={{ height: `calc(100% - ${usedHeight}px)` }}>
          {props.loading && (
            <CenteredBox>
              <CircularProgress color="secondary" size={30} />
            </CenteredBox>
          )}
          {!props.loading && <SelectableQuestionList {...selectableQuestionListProps} />}
        </QuestionSelectColumnScrollableContent>
        {props.content.mode === "QUESTION_SEARCH" && <StyledTablePagination {...props.content.pagination} />}
      </Column>
      <Column sx={{ width: columnWidth }}>
        <SortableQuestionListHeader {...sortableQuestionListHeaderProps} />
        <ColumnScrollableContent>
          <SortableQuestionList {...sortableQuestionListProps} />
        </ColumnScrollableContent>
      </Column>
    </StyledStack>
  );
};

QuestionSearchAndSelectableArea.displayName = "QuestionSearchAndSelectableArea";

export default QuestionSearchAndSelectableArea;
