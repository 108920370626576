import { FillRemoteTemplateList } from "@hireroo/app-store/widget/e/FillRemoteTemplateList";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as QuestionSelectFieldV2Subscriber from "../QuestionSelectFieldV2/Subscriber";
import { GenerateRemoteInterviewResourceEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewResourceEditorContainerProps = GenerateRemoteInterviewResourceEditorPropsArgs;

const RemoteInterviewResourceEditorContainer: React.FC<RemoteInterviewResourceEditorContainerProps> = props => {
  const remoteInterviewResourceEditorProps = useGenerateProps(props);
  React.useEffect(() => {
    const cleanup1 = QuestionSelectFieldV2Subscriber.startSubscribeSelectedQuestions({ target: "remote", enableProject: false });
    return () => {
      cleanup1();
      FillRemoteTemplateList.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewResourceEditor {...remoteInterviewResourceEditorProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewResourceEditorContainer.displayName = "RemoteInterviewResourceEditorContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorContainer, {});
