import { proxy } from "valtio";
import { proxyMap, proxySet } from "valtio/utils";

import * as Def from "./definitions";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  listQuestionsResponse: null,
  questionSearchQuery: {
    searchFilter: {
      questionVariants: [],
      statuses: [],
      difficulties: [],
      textFilter: "",
      skillTags: [],
      leakScoreLevels: [],
    },
    pager: Def.DefaultListQuestionsPager,
  },
  questionPackageSearchQuery: {
    searchFilter: {
      difficulties: [],
      textFilter: "",
    },
    pager: Def.DefaultListQuestionPackagesPager,
  },
  questionMap: proxyMap(),
  selectedUniqueKeysByEntityTracks: [],
  fetchQuestionsStatus: "READY",
  fetchQuestionPackagesStatus: "READY",

  listToDisplay: {
    kind: "ALL_QUESTIONS",
  },

  reachedIndexCache: null,
  questionSelectState: {
    openQuestionSelectDialog: false,
    mode: "ADD_FIXED",
    selectedEntityTrackFieldIndex: null,
  },

  skillTagState: {
    res: null,
    currentSkillTagIdsSet: proxySet(),
    skillTagSources: proxyMap(),
    nextOffset: 0,
    textFilter: "",
  },
});
