import { useTranslation } from "@hireroo/i18n";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntityFollowUpQuestionsSection, {
  EntityFollowUpQuestionsSectionProps,
} from "../../modules/EntityFollowUpQuestionsSection/EntityFollowUpQuestionsSection";
import EntityScoreBoard, { EntityScoreBoardProps } from "../../modules/EntityScoreBoard/EntityScoreBoard";
import ReportCheatDetectionSection, {
  ReportCheatDetectionSectionProps,
} from "../../modules/ReportCheatDetectionSection/ReportCheatDetectionSection";
import TestReportSectionHeader, { TestReportSectionHeaderProps } from "../../modules/TestReportSectionHeader/TestReportSectionHeader";
import { AnswerSectionProps } from "../../ms-components/Question";
import AnswerSection from "../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionDetailDrawer, { QuestionDetailDrawerProps } from "../../ms-components/Question/QuestionDetailDrawer/QuestionDetailDrawer";
import AnswerDetailSection, {
  ReportSystemDesignAnswerDetailSectionProps,
} from "../../ms-components/SystemDesign/ReportSystemDesignAnswerDetailSection/ReportSystemDesignAnswerDetailSection";
import EditableScoreSection, {
  ReportSystemDesignEditableScoreSectionProps,
} from "../../ms-components/SystemDesign/ReportSystemDesignEditableScoreSection/ReportSystemDesignEditableScoreSection";
import PlaybackSection, {
  ReportSystemDesignPlaybackSectionProps,
} from "../../ms-components/SystemDesign/ReportSystemDesignPlaybackSection/ReportSystemDesignPlaybackSection";

const EntityReportWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  padding: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

type Answer = Pick<AnswerSectionProps, "title" | "description"> & {
  name: string;
};

export type SystemDesignTestReportProps = {
  header: TestReportSectionHeaderProps;
  questionDetail: Omit<QuestionDetailDrawerProps, "open" | "onClose" | "variantText" | "answerContents"> & {
    answers: Answer[];
  };
  scoreBoard?: EntityScoreBoardProps;
  StatisticsContent?: React.ReactNode;
  playbackSection?: ReportSystemDesignPlaybackSectionProps;
  answerDetailSection?: ReportSystemDesignAnswerDetailSectionProps;
  editableScoreSection?: ReportSystemDesignEditableScoreSectionProps;
  cheatDetectionSection?: ReportCheatDetectionSectionProps;
  followUpQuestionsSection?: EntityFollowUpQuestionsSectionProps;
  announcement?: string;
};

const SystemDesignTestReport: React.FC<SystemDesignTestReportProps> = props => {
  const { t } = useTranslation();
  const [detailOpen, setDetailOpen] = React.useState(false);
  const testReportSectionHeaderProps: TestReportSectionHeaderProps = {
    ...props.header,
    detailButton: {
      onClick: () => {
        setDetailOpen(true);
      },
    },
  };

  const { answers, ...questionDetail } = props.questionDetail;

  const answerContents = answers.map((content): QuestionDetailDrawerProps["answerContents"][0] => ({
    id: `answer${content.name}`,
    Component: (
      <Box p={3}>
        <AnswerSection
          title={content.title}
          description={content.description}
          TopComponent={
            props.questionDetail.openButton && (
              <Box mb={2}>
                <Button
                  href={props.questionDetail.openButton.href}
                  rel="noreferrer"
                  target="_blank"
                  component="a"
                  color="secondary"
                  variant="outlined"
                  endIcon={<OpenInNewOutlined />}
                >
                  {t("詳細はこちら")}
                </Button>
              </Box>
            )
          }
        />
      </Box>
    ),
    label: {
      name: content.name,
    },
  }));

  const questionDrawer: QuestionDetailDrawerProps = {
    ...questionDetail,
    answerContents: answerContents,
    open: detailOpen,
    onClose: () => {
      setDetailOpen(false);
    },
    variantText: t("システムデザイン形式"),
  };

  return (
    <Box>
      <TestReportSectionHeader {...testReportSectionHeaderProps} />
      <EntityReportWrapper mt={2}>
        <Stack spacing={4}>
          {props.announcement && <Typography>{props.announcement}</Typography>}
          {props.scoreBoard && <EntityScoreBoard {...props.scoreBoard} />}
          {props.StatisticsContent}
          {props.answerDetailSection && <AnswerDetailSection {...props.answerDetailSection} />}
          {props.cheatDetectionSection && <ReportCheatDetectionSection {...props.cheatDetectionSection} />}
          {props.editableScoreSection && <EditableScoreSection {...props.editableScoreSection} />}
          {props.playbackSection && <PlaybackSection {...props.playbackSection} />}
          {props.followUpQuestionsSection && <EntityFollowUpQuestionsSection {...props.followUpQuestionsSection} />}
        </Stack>
      </EntityReportWrapper>
      <QuestionDetailDrawer {...questionDrawer} />
    </Box>
  );
};

SystemDesignTestReport.displayName = "SystemDesignTestReport";

export default SystemDesignTestReport;
