import * as z from "zod";

export const useFeedbackAnswer = () => {
  return z.enum(["YES", "NO", "NOT_SURE"]);
};

export type FeedbackAnswer = z.infer<ReturnType<typeof useFeedbackAnswer>>;

export const useScoringPrecisionFeedbackFormSchema = () => {
  const answer = useFeedbackAnswer();
  return z
    .object({
      answer: answer.optional(),
      comment: z.string().optional(),
    })
    .required({
      answer: true,
    });
};

export type ScoringPrecisionFeedbackFormSchema = z.infer<ReturnType<typeof useScoringPrecisionFeedbackFormSchema>>;
