import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateChallengeTestReportSuspiciousDegreeMessageForQuestionArgs = {
  challengeId: number;
  suspiciousInference: ChallengeTestReport.SuspiciousInference;
};

export const useGenerateProps = (
  args: GenerateChallengeTestReportSuspiciousDegreeMessageForQuestionArgs,
): Widget.ChallengeTestReportSuspiciousDegreeMessageForQuestionProps => {
  const { challengeId, suspiciousInference } = args;
  const setDialogStatus = React.useCallback(
    (status: ChallengeTestReport.FeedbackSuspiciousDegreeDialogStatus) => {
      ChallengeTestReport.setFeedbackSuspiciousDegreeDialogStatus(challengeId, status);
    },
    [challengeId],
  );
  const lang = useLanguageCode();

  return {
    suspiciousDegreeMessageForQuestion: {
      suspiciousDegree: suspiciousInference.suspiciousDegree,
      feedbackButton: {
        onClick: () => {
          setDialogStatus("OPEN");
        },
        disabled: suspiciousInference.feedback !== null,
      },
      reason: suspiciousInference ? resolveLanguage(suspiciousInference, lang, "reason", "") : undefined,
    },
  };
};
