import { INITIAL_VERSION, ListQuestion } from "@hireroo/app-definition/question";
import * as Graphql from "@hireroo/graphql/client/urql";

import { DeepReadonly } from "../types";

export const getQuestionStatus = (question: DeepReadonly<ListQuestion>): Graphql.QuestionStatus => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
      return question.status;
    case "ProjectQuestion":
    case "SystemDesignQuestion":
      return Graphql.QuestionStatus.Published;
    default:
      throw new Error(
        `Unknown type: ${
          (
            question as {
              type: "__invalid__";
            }
          ).type
        }`,
      );
  }
};

export const getVersion = (question: DeepReadonly<ListQuestion>): string => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
      return question.version;
    case "SystemDesignQuestion":
      return INITIAL_VERSION;
    default:
      throw new Error(`Unknown type: ${(question as { type: "__invalid__" }).type}`);
  }
};

export const getQuestionObjectId = (question: DeepReadonly<ListQuestion>): number => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "ProjectQuestion":
    case "SystemDesignQuestion":
      return question.questionId;
    case "MultiChoicePackage":
      return question.packageId;
    default:
      throw new Error(
        `Unknown type: ${
          (
            question as {
              type: "__invalid__";
            }
          ).type
        }`,
      );
  }
};

export const getNumUses = (question: DeepReadonly<ListQuestion>): number => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "SystemDesignQuestion":
      return question.numUses;
    default:
      throw new Error(
        `Unknown type: ${
          (
            question as {
              type: "__invalid__";
            }
          ).type
        }`,
      );
  }
};

export const getAccuracyRate = (question: DeepReadonly<ListQuestion>): number | undefined => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "SystemDesignQuestion":
      return question.accuracyRate;
    default:
      throw new Error(
        `Unknown type: ${
          (
            question as {
              type: "__invalid__";
            }
          ).type
        }`,
      );
  }
};

export const getAverageElapsedTime = (question: DeepReadonly<ListQuestion>): number => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "SystemDesignQuestion":
      return question.averageElapsedTimeSeconds;
    default:
      throw new Error(
        `Unknown type: ${
          (
            question as {
              type: "__invalid__";
            }
          ).type
        }`,
      );
  }
};

type BinForHistogram = {
  name: string;
  value: number;
};

export const generateScoreDistributionBinsForHistogram = (bins: number[]): BinForHistogram[] => {
  if (bins.length === 0) {
    return [];
  }
  const binWidth = 100 / bins.length;

  return bins.map((value, i) => {
    const start = Math.floor(binWidth * i * 100) / 100;
    const end = i === bins.length - 1 ? Math.floor(binWidth * (i + 1) * 100) / 100 : Math.floor((binWidth * (i + 1) - 1) * 100) / 100;
    return {
      name: `${start}~${end}`,
      value: value,
    };
  });
};
