import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormControlLabel = styled(FormControlLabel)({
  "& > span:nth-of-type(2)": {
    width: "100%",
  },
  marginTop: "10px",
});

export type SingleChoiceItem = {
  value: string;
  title: string;
  description?: string;
};

export type QuizQuestionSingleChoiceProps = {
  selectedValue: string;
  description: string;
  items: SingleChoiceItem[];
  disabledAll: boolean;
  onChange: (optionId: string) => void;
};

const QuizQuestionSingleChoice: React.FC<QuizQuestionSingleChoiceProps> = props => {
  const { t } = useTranslation();
  return (
    <React.Suspense
      fallback={
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      }
    >
      <Box mb={2}>
        <Markdown size="large" children={props.description} />
      </Box>
      <RadioGroup value={props.selectedValue} onChange={e => props.onChange(e.target.value)}>
        {props.items.map((option, index) => (
          <Box key={`option-${index}-${option.value}`}>
            <StyledFormControlLabel
              // Radio form passed "string" so value is "string"
              key={option.value}
              value={option.value}
              disabled={props.disabledAll}
              control={<Radio color="primary" />}
              label={<Markdown size="middle" children={option.title} />}
            />
          </Box>
        ))}

        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label={t("この問題をスキップ")}
          disabled={props.disabledAll}
          sx={{ mt: "10px" }}
        />
      </RadioGroup>
    </React.Suspense>
  );
};

QuizQuestionSingleChoice.displayName = "QuizQuestionSingleChoice";

export default QuizQuestionSingleChoice;
