import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { RemoteInterviewParticipants } from "@hireroo/app-store/widget/shared/RemoteInterviewParticipants";
import { RemoteInterviewTutorial } from "@hireroo/app-store/widget/shared/RemoteInterviewTutorial";
import { useChallengeCodingEditorContext } from "@hireroo/challenge/store";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import * as Sentry from "@sentry/react";
import * as React from "react";

type ConnectRealtimeHooksToRemotesIdStoreArgs = {
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useConnectRealtimeHooksToRemotesIdStore = (args: ConnectRealtimeHooksToRemotesIdStoreArgs) => {
  const { collaborativeState } = args;
  const challengeCodingEditor = useChallengeCodingEditorContext();
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const systemDesign = useSystemDesignContext();

  React.useEffect(() => {
    RemotesId.updateActiveSessionId(collaborativeState.selectedSession);
  }, [collaborativeState.selectedSession]);

  React.useEffect(() => {
    RemotesId.updateQuestionVariant(collaborativeState.selectedQuestionVariant);
  }, [collaborativeState.selectedQuestionVariant]);

  React.useEffect(() => {
    if (activeSessionVariant === "ALGORITHM" || activeSessionVariant === "DATABASE" || activeSessionVariant === "CLASS") {
      challengeCodingEditor.action.updateLanguage(collaborativeState.selectedLanguage, "PASSIVE");
    }
  }, [activeSessionVariant, challengeCodingEditor, collaborativeState.selectedLanguage]);

  React.useEffect(() => {
    systemDesign.action.updateComponentType(collaborativeState.selectedComponentType);
  }, [collaborativeState.selectedComponentType, systemDesign.action]);
};

type ConnectRealtimeHooksToRemoteInterviewParticipantsArgs = {
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useConnectRealtimeHooksToRemoteInterviewParticipantsStore = (args: ConnectRealtimeHooksToRemoteInterviewParticipantsArgs) => {
  const { collaborativeState } = args;
  React.useEffect(() => {
    const participants = Object.values(collaborativeState.users).map((user): RemoteInterviewParticipants.Participant => {
      return {
        id: user.uid,
        displayName: user.name,
        src: null,
      };
    });
    RemoteInterviewParticipants.updateParticipants(participants);
  }, [collaborativeState.users]);
};

/**
 * This hooks is a function that takes measures to prevent GetRemotes from being repeatedly
 * executed when caught updating the realtime database.
 */
export const useFetchRemote = (props: { remoteId: string }) => {
  const client = getGraphqlClient();
  const [fetchingStatus, setFetchingStatus] = React.useState<"START" | "PENDING" | "STOP">("STOP");
  const fetchRemote = React.useCallback(() => {
    setFetchingStatus("PENDING");
    client
      .GetRemotes({
        remoteId: props.remoteId,
      })
      .then(res => {
        RemotesId.updateRemote(res.remote);
      })
      .catch(error => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setFetchingStatus("STOP");
      });
  }, [client, props.remoteId]);

  React.useEffect(() => {
    if (fetchingStatus === "START") {
      fetchRemote();
    }
  }, [fetchRemote, fetchingStatus]);

  return {
    start: () => {
      setFetchingStatus("START");
    },
  };
};

export const useJoinLiveCoding = () => {
  const client = getGraphqlClient();
  const user = Auth.useCurrentUser();
  const isJoined = RemotesId.useLiveCodingCheckJoinStatus(user.uid);
  const liveCodingId = RemotesId.useLiveCodingId();
  const interviewStatus = RemotesId.useInterviewStatus();
  const liveCodingStatus = RemotesId.useLiveCodingStatus();
  const [joinStatus, setJoinStatus] = React.useState<"NOT_JOINED" | "JOINED">(
    interviewStatus === "STARTED" && isJoined ? "JOINED" : "NOT_JOINED",
  );

  const startLiveCoding = React.useCallback(() => {
    client
      .StartLiveCoding({
        input: {
          liveCodingId: liveCodingId,
          employeeId: user.uid,
          userName: user.displayName,
        },
      })
      .then(res => {
        RemotesId.updateLiveCoding(res.startLiveCoding);
        setJoinStatus("JOINED");
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [client, liveCodingId, user.displayName, user.uid]);

  const joinLiveCoding = React.useCallback(() => {
    client
      .JoinLiveCodingForEmployee({
        input: {
          liveCodingId: liveCodingId,
          userName: user.displayName,
        },
      })
      .then(res => {
        RemotesId.updateLiveCoding(res.joinLiveCoding);
        setJoinStatus("JOINED");
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [client, liveCodingId, user.displayName]);

  React.useEffect(() => {
    // Employee can start the live coding when its statuses are only CREATED or AWAITING
    if (interviewStatus !== "STARTED") {
      return;
    }
    if (isJoined) {
      return;
    }
    if (liveCodingStatus !== "CREATED" && liveCodingStatus !== "AWAITING") {
      return;
    }

    startLiveCoding();
  }, [isJoined, startLiveCoding, liveCodingStatus, interviewStatus]);

  React.useEffect(() => {
    // Employee can not join the live coding when its statuses are not started or users already join
    if (interviewStatus !== "STARTED") {
      return;
    }
    if (isJoined) {
      return;
    }
    if (liveCodingStatus !== "STARTED") {
      return;
    }

    joinLiveCoding();
  }, [interviewStatus, isJoined, joinLiveCoding, liveCodingStatus]);

  return joinStatus;
};

type SubscribeFinishEventArgs = {
  remoteId: string;
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useSubscribeFinishEvent = (args: SubscribeFinishEventArgs) => {
  const navigate = useTransitionNavigate();
  const { collaborativeState, remoteId } = args;
  React.useEffect(() => {
    if (collaborativeState.isLiveCodingFinished) {
      navigate("/e/remotes/:id/evaluate", {
        pathParams: {
          id: remoteId,
        },
      });
    }
  }, [collaborativeState.isLiveCodingFinished, navigate, remoteId]);
};

export type RecordingStatus = "START" | "PENDING" | "STOP";
export const useToggleVideoRecording = (props: { roomId: string; isRecording: boolean }) => {
  const [fetchStatus, setFetchStatus] = React.useState<RecordingStatus>("STOP");
  const client = getGraphqlClient();

  const start = React.useCallback(() => {
    setFetchStatus("PENDING");
    client
      .StartRecordingVideoChatForEmployee({
        input: {
          roomId: props.roomId,
        },
      })
      .catch(err => {
        Sentry.captureException(err);
      })
      .finally(() => setFetchStatus("STOP"));
  }, [client, props.roomId]);

  const stop = React.useCallback(() => {
    setFetchStatus("PENDING");
    client
      .StopRecordingVideoChatForEmployee({
        input: {
          roomId: props.roomId,
        },
      })
      .catch(err => {
        Sentry.captureException(err);
      })
      .finally(() => setFetchStatus("STOP"));
  }, [client, props.roomId]);

  React.useEffect(() => {
    if (!props.roomId) {
      return;
    }
    if (fetchStatus !== "START") {
      return;
    }
    if (props.isRecording) {
      stop();
    } else {
      start();
    }
  }, [start, stop, fetchStatus, props.isRecording, props.roomId]);

  return { fetchStatus, toggleVideoRecording: () => setFetchStatus("START") };
};

const TutorialMap: Record<RemotesId.LiveCodingQuestionVariant, RemoteInterviewTutorial.Kind> = {
  ALGORITHM: "EMPLOYEE_CHALLENGE",
  DATABASE: "EMPLOYEE_CHALLENGE",
  CLASS: "EMPLOYEE_CHALLENGE",
  SYSTEM_DESIGN: "EMPLOYEE_SYSTEM_DESIGN",
  UNKNOWN: "EMPLOYEE_DEFAULT",
};

export const useAutoStartTutorial = (activeSessionVariant: RemotesId.LiveCodingQuestionVariant) => {
  React.useEffect(() => {
    RemoteInterviewTutorial.autoStartTutorial(TutorialMap[activeSessionVariant]);
    return () => {
      RemoteInterviewTutorial.clear();
    };
  }, [activeSessionVariant]);
};
