import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import PairEditor, { PairEditorProps } from "./parts/PairEditor/PairEditor";

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  fontSize: "14px",
  padding: "8px 16px",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p4,
}));

export type SimilarCodesProps =
  | {
      kind: "SHOW_CONTENT";
      pairEditor: PairEditorProps;
    }
  | { kind: "NOTHING_TO_SHOW" };

const SimilarCodes: React.FC<SimilarCodesProps> = props => {
  const { t } = useTranslation();

  if (props.kind === "NOTHING_TO_SHOW") {
    return (
      <Wrapper>
        <Heading>{t("類似度")}</Heading>
        <Typography padding={5} textAlign={"center"} color="text.secondary" fontSize="14px">
          {t("類似コードは検出されませんでした。")}
        </Typography>
      </Wrapper>
    );
  }

  const { pairEditor } = props;

  return (
    <Wrapper>
      <Heading>{t("類似度")}</Heading>
      <PairEditor {...pairEditor} />
    </Wrapper>
  );
};

SimilarCodes.displayName = "SimilarCodes";

export default SimilarCodes;
