import { useInterview } from "@hireroo/app-helper/hooks";
import { generateNormalizedEntity } from "@hireroo/app-helper/normalizer";
import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import { ExamNavigation } from "@hireroo/app-store/widget/t/ExamNavigation";
import { Pages, Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import TestAnnouncementContainer from "../../../../widget/v2/shared/TestAnnouncement/Container";
import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";
import EntryAssessmentFetchContainer from "./widget/EntryAssessment/FetchContainer";

export type ExamsIdContainerProps = {
  examId: string;
};

const ExamsIdContainer: React.FC<ExamsIdContainerProps> = props => {
  const examStatus = AssessmentsIdStore.useExamStatus();
  const entities = AssessmentsIdStore.useEntities();
  const firstEntity = generateNormalizedEntity(entities[0]);
  const [interviewState, interviewAction] = useInterview({
    interviewId: props.examId,
    interviewType: "exam",
    entityId: firstEntity.entityId,
    entityType: firstEntity.entityType,
    questionId: firstEntity.questionId,
    version: firstEntity.questionVersion,
  });
  /**
   * TODO @himenon Workaround INITIALIZED support.
   * Essentially, the useInterview association should be made side-effect free, and this component should be initialized at the time when the initialization is completely finished.
   */
  const [initialized, setInitialized] = React.useState(false);

  const screeningTestProps = useGenerateProps({
    examId: props.examId,
    canConnect: initialized && interviewState.interviewReady && interviewState.isInterviewing,
  });
  const initialEntityKey = `${interviewState.entityType}-${interviewState.entityId}-${interviewState.questionMap.id}-${interviewState.questionMap.version}`;
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    const stopStartSubscribeShowingEntity = Subscriber.startSubscribeOnChangeShowingEntity({
      onChangeShowingEntity: entity => {
        interviewAction.setChangeQuestion(entity.entityId, entity.entityType, entity.questionId, entity.questionVersion);
      },
    });
    const stopSubscribeQuizCodingEditor = Subscriber.startSubscribeQuizCodingEditor();
    const stopSubscribeSystemDesignCodingEditor = Subscriber.startSubscribeSystemDesignCodingEditor();
    const stopSubscribeProjectCodingEditor = Subscriber.startSubscribeProjectCodingEditorV3();
    const stopSubscribeChallengeCodingEditor = Subscriber.startSubscribeChallengeCodingEditor();
    const stopSubscribeChallengeCodingEditorLoading = Subscriber.startSubscribeChallengeCodingEditorLoading();
    const stopSubscribeQuizCodingEditorLoading = Subscriber.startSubscribeQuizCodingEditorLoading();

    return () => {
      stopSubscribeQuizCodingEditor();
      stopStartSubscribeShowingEntity();
      stopSubscribeProjectCodingEditor();
      stopSubscribeSystemDesignCodingEditor();
      stopSubscribeChallengeCodingEditor();
      stopSubscribeChallengeCodingEditorLoading();
      stopSubscribeQuizCodingEditorLoading();
    };
  });
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    ExamNavigation.setSelectedEntityKey(initialEntityKey);
    setInitialized(true);
  }, [initialEntityKey, interviewState.interviewReady]);

  if (examStatus === "CREATED") {
    //TODO: fix to assessment
    return <EntryAssessmentFetchContainer examId={props.examId} />;
  }

  if (!interviewState.interviewReady) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  if (!interviewState.isInterviewing) {
    return (
      <ErrorBoundary>
        <Pages.Assessment
          layout={{
            //TODO: fix to talent header
            Header: null,
            // <ScreeningTestHeaderContainer
            //   {...{
            //     showingMode: "READONLY",
            //     onEndInterview: undefined,
            //     willBlockEndInterview: true,
            //     disabledFinishButton: true,
            //     messageForCandidate: undefined,
            //   }}
            // />
            //TODO: fix to talent footer
            Footer: null,
            Snackbar: <SnackbarContainer />,
            Tutorial: null,
          }}
          Content={<TestAnnouncementContainer kind="ALL_QUESTION_SUBMITTED" />}
        />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Pages.Assessment {...screeningTestProps} />
    </ErrorBoundary>
  );
};

ExamsIdContainer.displayName = "ExamsIdContainer";

export default withErrorBoundary(ExamsIdContainer, {});
