import { DefaultAvailableQuestionVariants, GraphQLQuestionVariantMap } from "@hireroo/app-definition/question";
import { App, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import { QuestionsTable } from "@hireroo/app-store/widget/e/QuestionsTable";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import QuestionTableContainer, { QuestionTableContainerProps } from "./Container";

export type QuestionTableFetchContainerProps = {};

const QuestionTableFetchContainer: React.FC<QuestionTableFetchContainerProps> = () => {
  const appStatus = App.useStatus();

  const currentSearchFilter = QuestionsStore.useCurrentSearchFilter();
  const initialized = QuestionsStore.useInitialized();
  const pager = QuestionsStore.usePager();
  const { isDescending, sortMethod } = QuestionsStore.useSortParams();
  const lang = useLanguageCode();

  const isAvailableFeature = Payment.useIsAvailableFeature();
  const availableQuestionVariants = DefaultAvailableQuestionVariants.filter(defaultAvailableQuestionVariant => {
    switch (defaultAvailableQuestionVariant) {
      case "PROJECT":
      case "PROJECT_BACKEND":
      case "PROJECT_DATA_SCIENCE":
      case "PROJECT_FRONTEND":
      case "PROJECT_OTHER":
        return isAvailableFeature("test.project.create");
      case "SYSTEM_DESIGN":
        return isAvailableFeature("test.systemDesign.create");
      case "CHALLENGE":
      case "CHALLENGE_ALGORITHM":
      case "CHALLENGE_CLASS":
      case "CHALLENGE_DATABASE":
      case "QUIZ":
        return true;
      default:
        throw new Error(`Not implemented : ${defaultAvailableQuestionVariant satisfies never}`);
    }
  });

  const acceptQuestionVariant = new Set<Graphql.QuestionVariant>(
    availableQuestionVariants.map(availableQuestionVariants => GraphQLQuestionVariantMap[availableQuestionVariants]),
  );

  const questionsInput: Graphql.QuestionsInput = {
    isDescending,
    offset: pager.offset,
    size: pager.size,
    sortMethod,
    withCount: true,
    filters: {
      difficulties: currentSearchFilter.difficulties.slice(),
      statuses: currentSearchFilter.statuses.slice(),
      titleEn: lang === "en" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
      titleJa: lang === "ja" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
      questionVariants:
        currentSearchFilter.questionVariants.length === 0
          ? DefaultAvailableQuestionVariants.filter(variant => acceptQuestionVariant.has(variant))
          : [...currentSearchFilter.questionVariants].filter(variant => acceptQuestionVariant.has(variant)),
      skillTagIds: currentSearchFilter.skillTags.map(skillTag => skillTag.value),
      leakScoreLevels: currentSearchFilter.leakScoreLevels.slice(),
    },
  };

  const [result, refresh] = Graphql.useListQuestionsForQuestionsPageQuery({
    variables: {
      questionsInput: questionsInput,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    QuestionsStore.updateLoadingStatus(result.fetching ? "LOADING" : "NONE");
    if (result.data) {
      QuestionsStore.setResponse(result.data.questions);
    }

    return () => {
      QuestionsTable.clear();
    };
  }, [result.data, result.fetching]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: QuestionTableContainerProps = {};

  return <QuestionTableContainer {...containerProps} />;
};

QuestionTableFetchContainer.displayName = "QuestionTableFetchContainer";

export default withErrorBoundary(QuestionTableFetchContainer, {});
