import type { Widget } from "@hireroo/presentation";

export type GenerateTestAnnouncementPropsArgs = {
  kind: Widget.TestAnnouncementProps["kind"];
  formLink?: Widget.TestAnnouncementProps["formLink"];
};

export const useGenerateProps = (args: GenerateTestAnnouncementPropsArgs): Widget.TestAnnouncementProps => {
  return {
    kind: args.kind,
    formLink: args.formLink,
  };
};
