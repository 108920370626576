import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import { useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "8px",
}));

const StyledAccordion = styled(Accordion)(() => ({
  backgroundImage: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "static",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: "20px",
  padding: 0,
  "&.Mui-expanded": {
    minHeight: "20px",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));

const MarkdownWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "8px",
  border: "1px solid",
  backgroundColor: "inherit",
  borderColor: theme.palette["Other"].Divider,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "4px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette["Gray/Shades"].p8,
  height: "20px",
  width: "20px",
}));

export type FollowUpQuestionProps = {
  question: string;
  answer: string;
  purpose?: string;
  className?: string;
};

const FollowUpQuestion: React.FC<FollowUpQuestionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Wrapper spacing={2} className={props.className}>
      {props.purpose && (
        <Stack direction="row" spacing={1.5}>
          <IconWrapper>
            <LightbulbRoundedIcon fontSize="small" color="info" />
          </IconWrapper>
          <Typography color={theme.palette.info.main} fontSize={14}>
            {props.purpose}
          </Typography>
        </Stack>
      )}
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" spacing={1.5}>
            <IconWrapper>
              <Typography fontSize={14} fontWeight={900}>
                Q
              </Typography>
            </IconWrapper>
            <React.Suspense>
              <Markdown size="small">{props.question}</Markdown>
            </React.Suspense>
          </Stack>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Stack direction="row" spacing={1.5} pt={2}>
            <IconWrapper>
              <Tooltip title={t("模範解答")}>
                <Typography fontSize={14} fontWeight={900}>
                  A
                </Typography>
              </Tooltip>
            </IconWrapper>
            <React.Suspense>
              <MarkdownWrapper display="flex" flexDirection="column" width="100%" border="1px solid">
                <Markdown size="small">{props.answer}</Markdown>
              </MarkdownWrapper>
            </React.Suspense>
          </Stack>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Wrapper>
  );
};

FollowUpQuestion.displayName = "FollowUpQuestion";

export default FollowUpQuestion;
